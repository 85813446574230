import { IonLoading } from "@ionic/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { mainStore } from "../stores/MainStore";
import { MarkerConfig } from "../stores/MapStore";

const BaseMap: React.FC= () => {
  const [mapConfig, setMapConfig] = useState({zoom: mainStore.mapStore.zoom, center: mainStore.mapStore.center});

  const [isLoadingMap, setLoadingMap] = useState(true);

  const handleOnLoadSuccess = () => {
    setLoadingMap(false)
  }

  useEffect(() => {
    setMapConfig({zoom: mainStore.mapStore.zoom, center: mainStore.mapStore.center});
  }, []);

  return (
    <>
    <div className="map__wrapper">
      <YMaps>
        <IonLoading cssClass="ion-loading" isOpen={isLoadingMap} spinner="crescent"/>
        <Map defaultState={mapConfig} onLoad={ handleOnLoadSuccess } className="map">
          {mainStore.mapStore.markerPoints.map((e, idx) => 
            <Marker latitude={e.latitude} longitude={e.longitude} uid={e.uid} key={`marker_${idx}`}/>
          )}
        </Map>
      </YMaps>
    </div>
    </>
  )
}

const Marker: React.FC<MarkerConfig> = ({longitude, latitude, uid}) => {
  const handleChoosePoint = () => {
    mainStore.mapStore.activePoint = uid;
  }

  return (
    <>
      <Placemark onClick={ handleChoosePoint } geometry={[latitude, longitude]}/>
    </>
  )
}

export default observer(BaseMap);