import PerfectScrollbar from 'perfect-scrollbar';

export const UI = {
  deviceViewport: {
    tablet: 1199,
    phone: 767
  },
  isInit: false,

  init() {
    if (this.isInit) return;
    this.elHtml = document.querySelector('html');
    this.elBody = document.querySelector('body');
    this.elColumnMain = document.querySelector('.column-main');
    this.header.init();
    this.menu.init();
    this.filter.init();
    this.scroll.init();
    this.accordion.init();
    this.dropdown.init();
    this.table.init();
    this.stateToggle.init();
    this.debug.init();
    this.isInit = true;
  },

  header: {
    init() {
      this.elHeader = document.querySelector('.header');
      this.elHeaderTop = document.querySelector('.header__top');
      this.scrollY = UI.elColumnMain.scrollTop;
      this.isHeaderTopReset = true;
      this.isHeaderTopFixed = false;
      this.isHeaderTopActive = false;
      this.checkResize();
      this.addEvents();
    },
    addEvents() {
      window.addEventListener('resize', () => this.checkResize());
    },
    checkScroll() {
      if (!UI.elColumnMain.scrollTop) this.resetHeaderTop();
      if (UI.elColumnMain.scrollTop > 120) {
        let scrollDelta = UI.elColumnMain.scrollTop - this.scrollY;
        this.setHeaderTopFixed();
        if (scrollDelta >= 10) {
          this.scrollY = UI.elColumnMain.scrollTop;
          this.unsetHeaderTopActive();
        }
        if (scrollDelta <= -50) {
          this.scrollY = UI.elColumnMain.scrollTop;
          this.setHeaderTopActive();
        }
      } else {
        if (!this.isHeaderTopActive) this.resetHeaderTop();
      }
    },
    checkResize() {
      if (window.innerWidth > UI.deviceViewport.phone) {
        if (this.eventListener) {
          UI.elColumnMain.removeEventListener('scroll', this.eventListener);
          this.eventListener = null;
          this.resetHeaderTop();
        }
      } else {
        if (!this.eventListener) {
          this.eventListener = () => this.checkScroll();
          UI.elColumnMain.addEventListener('scroll', this.eventListener);
        }
      }
    },
    resetHeaderTop() {
      if (this.isHeaderTopReset) return;
      this.elHeader.classList.remove('_fixed');
      this.elHeaderTop.classList.remove('_active', '_fixed');
      this.isHeaderTopReset = true;
      this.isHeaderTopFixed = false;
      this.isHeaderTopActive = false;
    },
    setHeaderTopFixed() {
      if (this.isHeaderTopFixed) return;
      this.elHeaderTop.classList.add('_fixed');
      this.isHeaderTopReset = false;
      this.isHeaderTopFixed = true;
    },
    setHeaderTopActive() {
      if (this.isHeaderTopActive) return;
      this.elHeader.classList.add('_fixed');
      this.elHeaderTop.classList.add('_active');
      this.isHeaderTopReset = false;
      this.isHeaderTopActive = true;
    },
    unsetHeaderTopActive() {
      if (!this.isHeaderTopActive) return;
      this.elHeader.classList.remove('_fixed');
      this.elHeaderTop.classList.remove('_active');
      this.isHeaderTopReset = false;
      this.isHeaderTopActive = false;
    }
  },

  menu: {
    init() {
      this.addEvents();
    },
    addEvents() {
      UI.elBody.addEventListener('click', e => {
        let el = e.target.closest('.js-open-menu');
        if (el) {
          e.preventDefault();
          this.open();
          return;
        }
        if (e.target.closest('.js-close-menu')) {
          e.preventDefault();
          this.close();
        }
      });
    },
    open() {
      UI.elHtml.classList.add('_show-menu');
    },
    close() {
      UI.elHtml.classList.remove('_show-menu');
    }
  },

  filter: {
    init() {
      this.addEvents();
    },
    addEvents() {
      UI.elBody.addEventListener('click', e => {
        let el = e.target.closest('.js-open-filter');
        if (el) {
          e.preventDefault();
          this.open();
          return;
        }
        if (e.target.closest('.js-close-filter')) {
          e.preventDefault();
          this.close();
        }
      });
    },
    open() {
      UI.elHtml.classList.add('_show-filter');
    },
    close() {
      UI.elHtml.classList.remove('_show-filter');
    }
  },

  scroll: {
    init() {
      this.scrollOption = {
        minScrollbarLength: 20
      };
      this.toggleCustomScroll();
      this.addEvents();
    },
    addEvents() {
      window.addEventListener('resize', () => this.toggleCustomScroll());
    },
    toggleCustomScroll() {
      if (window.innerWidth > UI.deviceViewport.tablet) {
        document.querySelectorAll('.js-custom-scroll').forEach(el => {
          if (el.Scroll) {
            el.Scroll.update();
            return;
          }
          el.Scroll = new PerfectScrollbar(el, this.scrollOption);
          if (el.classList.contains('table-responsive')) {
            el.addEventListener('ps-x-reach-start', this.psXReachStart);
            el.addEventListener('ps-x-reach-end', this.psXReachEnd);
            el.addEventListener('ps-scroll-x', this.psScrollX);
          }
        });
      } else {
        document.querySelectorAll('.js-custom-scroll').forEach(el => {
          if (!el.Scroll) return;
          el.Scroll.destroy();
          el.Scroll = null;
          if (el.classList.contains('table-responsive')) {
            el.removeEventListener('ps-x-reach-start', this.psXReachStart);
            el.removeEventListener('ps-x-reach-end', this.psXReachEnd);
            el.removeEventListener('ps-scroll-x', this.psScrollX);
            el.parentNode.classList.remove('_left', '_right');
          }
        });
      }
      document.querySelectorAll('.table-responsive.js-custom-scroll').forEach(el => {
        if (el.ScrollEvent) return;
        el.ScrollEvent = true;
        el.addEventListener('scroll', () => {
          UI.dropdown.checkScroll();
          UI.table.checkHeaderPosition();
        });
      });
    },
    psXReachStart(e) {
      e.target.parentNode.classList.remove('_left');
      if (e.target.classList.contains('ps--active-x')) e.target.parentNode.classList.add('_right');
    },
    psXReachEnd(e) {
      e.target.parentNode.classList.remove('_right');
      if (!e.target.classList.contains('ps--active-x')) e.target.parentNode.classList.remove('_left');
    },
    psScrollX(e) {
      if (e.target.Scroll.reach.x === null) e.target.parentNode.classList.add('_left', '_right');
    },
    toCatalogOffer(id) {
      if (!id) return;
      let elOffer = document.getElementById('offer_' + id);
      if (!elOffer) return;
      let tableHeadHeight = 56;
      this.to(UI.elColumnMain.scrollTop + elOffer.getBoundingClientRect().top - tableHeadHeight);
    },
    to(positionY) {
      if (!UI.elColumnMain) return;
      UI.elColumnMain.scrollTop = positionY ?? 0;
      setTimeout(() => {
        if (UI.elColumnMain.Scroll) UI.elColumnMain.Scroll.update();
      }, 100);
    }
  },

  accordion: {
    init() {
      this.addEvents();
    },
    addEvents() {
      UI.elBody.addEventListener('click', e => {
        if (e.target.closest('.js-toggle-accordion')) {
          e.preventDefault();
          this.toggle(e.target.closest('.accordion'));
        }
      });
    },
    toggle(el) {
      if (!el) return;
      el.classList.contains('_active') === false
        ? this.open(el) : this.close(el);
    },
    open(el) {
      el.classList.add('_active');
      const elBody = el.querySelector('.accordion__body');
      elBody.removeAttribute('style');
      const height = elBody.clientHeight;
      elBody.style.height = '0';
      UI.animate((progress) => {
        elBody.style.height = height * progress + 'px';
        if (progress === 1) {
          elBody.removeAttribute('style');
          UI.scroll.toggleCustomScroll();
        }
      }, 200);
    },
    close(el) {
      el.classList.remove('_active');
      const elBody = el.querySelector('.accordion__body');
      elBody.removeAttribute('style');
      elBody.style.display = 'block';
      const height = elBody.clientHeight;
      UI.animate((progress) => {
        elBody.style.height = height - height * progress + 'px';
        if (progress === 1) {
          elBody.removeAttribute('style');
          UI.scroll.toggleCustomScroll();
        }
      }, 200);
    },
    reset(parentElSelector) {
      document.querySelectorAll(`${parentElSelector} .accordion`).forEach(el => el.classList.remove('_active'));
    }
  },

  dropdown: {
    init() {
      this.elActiveDropdown = null;
      this.addEvents();
    },
    addEvents() {
      UI.elBody.addEventListener('click', e => {
        let el = e.target.closest('.dropdown__body');
        if (el) {
          e.preventDefault();
          this.toggle(e.target.closest('.dropdown'));
          return;
        }
        if (e.target.closest('.dropdown__interactive')) return;
        if (e.target.closest('.picker-md')) return;
        this.close();
      });
    },
    addEventScroll() {
      this.eventListener = () => this.checkScroll();
      UI.elColumnMain.addEventListener('scroll', this.eventListener);
      window.addEventListener('resize', this.eventListener);
      this.checkScroll();
    },
    removeEventScroll() {
      if (this.eventListener) {
        UI.elColumnMain.removeEventListener('scroll', this.eventListener);
        window.removeEventListener('resize', this.eventListener);
        this.eventListener = null;
        this.elActiveDropdown = null;
      }
    },
    checkScroll() {
      if (!this.elActiveDropdown) return;
      const elFilter = this.elActiveDropdown.querySelector('.dropdown__filter');
      elFilter.style.top = this.elActiveDropdown.getBoundingClientRect().bottom + 'px';
      elFilter.style.left = this.elActiveDropdown.getBoundingClientRect().left + 'px';
      
      if (this.elActiveDropdown.getBoundingClientRect().left + elFilter.clientWidth >= document.documentElement.clientWidth) {
        elFilter.style.left = this.elActiveDropdown.getBoundingClientRect().left - elFilter.clientWidth + this.elActiveDropdown.clientWidth + 'px';
      }
    },
    toggle(el) {
      el.classList.contains('_active') === false
        ? this.open(el) : this.close();
    },
    open(el) {
      this.close();
      if (el.classList.contains('_fixed')) {
        this.elActiveDropdown = el;
        this.addEventScroll();
      }
      el.classList.add('_active');
    },
    close() {
      if (this.eventListener) this.removeEventScroll();
      document.querySelectorAll('.dropdown').forEach(el => el.classList.remove('_active'));
    }
  },

  table: {
    init() {
      this.addEvents();
    },
    addEvents() {
      UI.elColumnMain.addEventListener('scroll', (e) => {
        //this.checkScrollTop(e);
        this.checkHeaderPosition();
      });
      window.addEventListener('resize', () => this.checkHeaderPosition());
    },
    checkHeaderPosition() {
      const elTableResponsive = document.querySelector('.table-responsive.js-custom-scroll');
      if (!elTableResponsive) return;
      const elHeaderPlace = elTableResponsive.querySelector('.table-block__header-place');
      if (!elHeaderPlace) return;
      const elHeaderWrap = elTableResponsive.querySelector('.table-block__header-wrap');
      if (!elHeaderWrap) return;
      const elHeader = elTableResponsive.querySelector('.table-block__header');
      if (!elHeader) return;
      if (elHeaderPlace.getBoundingClientRect().top <= 0 && elTableResponsive.clientHeight + elHeaderPlace.getBoundingClientRect().top >= 150) {
        elHeaderWrap.classList.add('_fixed');
        elHeaderWrap.style.width = elTableResponsive.clientWidth + 'px';
        elHeader.style.width = elHeaderPlace.clientWidth + 'px';
        elHeader.style.marginLeft = elTableResponsive.scrollLeft * -1 + 'px';
      } else elHeaderWrap.classList.remove('_fixed');
      UI.dropdown.checkScroll();
    },
    /*checkScrollTop(e) {
        if (e.target.scrollTop > 0) {
            if (mainStore.isScrollTop) mainStore.isScrollTop = false;
        } else {
            if (!mainStore.isScrollTop) mainStore.isScrollTop = true;
        }
    }*/
  },

  stateToggle: {
    init() {
      this.addEvents();
    },
    addEvents() {
      UI.elBody.addEventListener('click', e => {
        let el = e.target.closest('.js-state-trigger');
        if (el) this.set(el);
        el = e.target.closest('.form-search');
        if (!el) this.reset('.form-search.js-state');
      });
    },
    set(el) {
      el.closest('.js-state').setAttribute('data-state', el.getAttribute('data-set'));
    },
    reset(elSelector) {
      document.querySelectorAll(elSelector).forEach(el => el.removeAttribute('data-state'));
    }
  },

  debug: {
    init() {
      this.elDebugScreenWidth = document.querySelector('.debug-screen-width');
      if (!this.elDebugScreenWidth) return;
      this.elDebugScreenWidth.innerHTML = `w${window.innerWidth} h${window.innerHeight}`;
      this.addEvents();
    },
    addEvents() {
      window.addEventListener('resize', () => this.elDebugScreenWidth.innerHTML = `w: ${window.innerWidth} h: ${window.innerHeight}`);
    }
  },

  animate: function (draw, duration) {
    const start = performance.now();
    return requestAnimationFrame(function animate(time) {
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;
      if (timeFraction > 0) draw(timeFraction);
      if (timeFraction < 1) requestAnimationFrame(animate);
    });
  }
};
