import { observer } from 'mobx-react';
import React from 'react';
import { mainStore } from '../stores/MainStore';
import Popup from './Popup';
import { DeliverySchedule } from '../api/Checkout';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export default observer(() => {
  // const [deliverySchedule, setDeliverySchedule] = useState<DeliverySchedule | null>(null);
  const handlePopupDismiss = () => {
    mainStore.isShowChooseDeliverySchedulePopup = false;
  };
  const handleChooseDate = (schedule: DeliverySchedule) => {
    mainStore.checkoutStore.deliveryDate = schedule;
    handlePopupDismiss();
  };

  return (
    <>
      <Popup isShow={mainStore.isShowChooseDeliverySchedulePopup} onBackdropDismiss={handlePopupDismiss}>
        <div className="board__title h6">Выберите дату доставки</div>
        <div className="position-relative overflow-auto">
          {(mainStore.checkoutStore.deliveryScheduleList || []).filter((item) => item.availiable).map((item, i) => (
            <div
              className="br-8 p-3 border-mgray c-black cur-p mt-2 transition-color c-hover-bg-lgray"
              onClick={() => handleChooseDate(item)}
              key={i}
            >
              { item.date ? format(new Date(item.date), 'eeee, d MMMM', { locale: ru }) : '' }
            </div>
          ))}
        </div>
      </Popup>
    </>
  );
});
