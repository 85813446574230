import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { mainStore } from '../stores/MainStore';
import Popup from './Popup';

const PopupMap: React.FC = () => {
  const [debtCost, setDebtCost] = useState(0);
  const [havePDZ, setPDZ] = useState(false);
  const history = useHistory();

  const handlePopupDismiss = () => {
    mainStore.isShowDebtPopup = false;
  };

  const routeToOrders = () => {
    handlePopupDismiss();
    history.replace('/orders');
    mainStore.catalogStore.setClientId(null);
    mainStore.promotionsStore.setClientId(null);
  }

  useEffect(() => {
    if (!mainStore.catalogStore.clientId) return;

    const client = mainStore.cabinetStore.getClientById(parseInt(mainStore.catalogStore.clientId, 10));
    if (!client) return;

    if (client.customer.pdz && client.customer.pdz > 0) {
      setPDZ(true);
      return;
    }

    setPDZ(false);

    const limit = client.credit_limit || 0;
    const limitBalance = Math.abs(client.credit_limit_balance || 0);
    const totalCost = Number(mainStore.promotionsStore.getTotals.totalSumDiscount + mainStore.checkoutStore.deliveryCost);

    setDebtCost(Math.ceil((limitBalance + totalCost) - limit))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStore.catalogStore.clientId, mainStore.promotionsStore.getTotals.totalSumDiscount, mainStore.checkoutStore.deliveryCost])

  return(
    <>
      <Popup isShow={mainStore.isShowDebtPopup} onBackdropDismiss={ handlePopupDismiss }>
        <div className="board__title h6"> Оплата </div>
        <div>
          {!havePDZ ? (
            <span>Ваш заказ оформлен, чтобы он был обработан оператором, оплатите предыдущие заказы на сумму минимум {mainStore.prettyPrice(debtCost)} руб.</span>
          )
          :
          (
            <span>Для того, чтобы заказ был взят работу, вам необходимо погасить просроченную задолженность</span>
          )}
        </div>
        <div>
          <button
            className="btn btn-primary w-min-200 mt-3"
            onClick={routeToOrders}>
            Мои заказы
          </button>
        </div>
      </Popup>
    </>
  )
}

export default observer(PopupMap);