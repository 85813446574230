import { Address } from './Cabinet';
import { CheckoutRequest, PaymentType } from './Checkout';
import { ApiResponse, requests } from './Requests';
import { mainStore } from '../stores/MainStore';

export interface Order {
  button: string,
  cancellation: boolean | null,
  credit_control: boolean,
  customer: {
    name: string,
    id: number,
    pdz?: number,
  }
  date_payment_format: string,
  date_timestamp: number,
  debt: string,
  delay_debt: number,
  id: string,
  number: string,
  overdue_amount: string,
  payment_amount: number,
  is_paid: boolean,
  payment_date_timestamp: number,
  price_total: string,
  prices_checked: boolean,
  status: 'created' | 'processing' | 'delivery' | 'delivered' | 'canceled',
  shipment_status: 
  'Не обработан' | 'Обработан оператором' | 'Готов к маршрутизации' | 'Готов к экспедиции' | 
  'Экспедиция' | 'Доставленно' | 'Доставленно с актом' | 'Отменён',
  order: {
    ref?: string,
    payment_available: boolean,
    checkout_req_data?: CheckoutRequest,
    postpay: boolean,
  }
}

export interface OrderDetail {
  payment_available: boolean,
  address_id: number,
  address: Address | null,
  doc_type: string,
  goods: OrderGood[],
  number: string,
  payment_amount: string,
  payment_type: PaymentType,
}

export interface OrderGood {
  amount: number,
  code: string,
  name: string,
  qty: number,
  qty_request: number,
  ref: string,
  good: {
    price: number,
    preview_url: string,
    id: number,
    vendor_code: string,
  }
}

export enum TranslatedOrderStatusClient {
  'canceled' = 'Отменен',
  'created' = 'Создан',
  'processing' = 'Обработка',
  'delivery' = 'Доставка',
  'delivered' = 'Доставленно',
}

interface OrdersResponse extends ApiResponse {
  data: Order[];
}

interface OrderByIdResponse extends ApiResponse {
  data: {
    order: {
      ref: string,
      payment_available: boolean,
      checkout_req_data: CheckoutRequest,
    }[]
  };
}

interface GoodsResponse extends ApiResponse {
  data: OrderDetail;
}

interface OrdersRequests {
  getOrders(data?: {
    offset?: number,
    limit?: number,
  }): Promise<OrdersResponse>;

  getGoods(orderId: string): Promise<GoodsResponse>;

  getOrderById(orderId: string): Promise<OrderByIdResponse>;
}

export const OrdersRequests: OrdersRequests = {
  getOrders: (data) =>
    requests.get('client/orders/get' + mainStore.convertObjToGet(data || {})),

  getGoods: (orderId) =>
    requests.get(`client/order/goods/get/?order_id=${orderId}`),

  getOrderById: (orderId) =>
    requests.get('client/orders/' + orderId),
};
