import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {MainStore} from '../stores/MainStore';
import {useHistory} from 'react-router';

const Logout: React.FC<{ mainStore: MainStore }> = ({mainStore}) => {
    const history = useHistory();

    useEffect(() => {
        if (!mainStore.userStore.isAuth) history.replace('/auth');
        else mainStore.userStore.logout();
    }, [mainStore.userStore, mainStore.userStore.isAuth, history]);

    return <></>;
};

export default inject('mainStore')(observer(Logout));
