import { ApiResponse, requests } from './Requests';
import { mainStore } from '../stores/MainStore';

export interface CheckoutGood {
  good_id: number,
  ref: string,
  count: number,
  price: number,
  status: 'confirmed' | 'not_confirmed',
}

interface CheckoutGoodExtend extends CheckoutGood {
  valid_max_count: number,
  valid_price: number,
}

interface CheckoutPromotion {
  id: number,
  ref: string,
  type_present: 'gift' | 'discount',
  multiplicity: number,
  gift_packs: CheckoutPromotionPack[],
  promotion_packs: CheckoutPromotionPack[],
}

interface CheckoutPromotionExtend extends CheckoutPromotion {
  gift_packs: CheckoutPromotionPackExtend[],
  promotion_packs: CheckoutPromotionPackExtend[],
}

export interface CheckoutPromotionPack {
  id: number,
  ref: string,
  products: CheckoutGood[],
}

export interface CheckoutMeta {
  customer_ref: string,
  delivery_address_ref: string,
  order_ref: string,
  status: 'reserved',
  warehouse_ref: string,
}

interface CheckoutPromotionPackExtend extends CheckoutPromotionPack {
  products: CheckoutGoodExtend[],
}

export type DeliveryType = 'self_pickup' | 'courier_delivery' | 'cdek' | 'cdek_to_door'

export type PaymentType = 'alfabank' | 'deferred_payment' | 'qr' | 'card'

export type ShippingDocumentsType = 'UPD' | 'Check' | 'bsf'

export interface DeliveryInfoMsg {
  cost: number,
  delivery_schedule: DeliverySchedule[],
  pickup_points?: PickupPoint[],
  eta?: {
    days_min: number,
    days_max: number,
  }
}

export interface PickupPoint {
  code: string,
  location: {
    address: string,
    longitude: number,
    latitude: number,
    fias_guid: string,
  }
}

export enum CdekSubtype {
  toStore = 136,
  toDoor = 137,
}

export interface DeliverySchedule {
  availiable: boolean,
  date: string,
}

export interface ConfirmOrderResponse extends ApiResponse {
  basket: CheckoutGoodExtend[],
  promotions: CheckoutPromotionExtend[],
  customer_id: number,
  meta: CheckoutMeta,
  manager_id?: number,
}

interface DeliveryInfoResponse extends ApiResponse {
  data: DeliveryInfoMsg;
}

interface CheckoutResponse extends ApiResponse {
  data: {
    action: 'process_payment' | 'accept_order',
    data: {
      integration_result: {
        total: number
      },
      process_payment_url: string,
    }
  };
}

interface CheckStatusResponse extends ApiResponse {
  data: {
    status: 'success' | 'failed',
  };
}

export interface ConfirmOrderRequest {
  basket: CheckoutGood[],
  promotions: CheckoutPromotion[],
  customer_id: number,
  manager_id?: number,
  doc_type?: 'bsf' | ''
}

export interface CheckoutRequest {
  order_ref: string,
  address_ref: string,
  def_payment: boolean,
  delivery: {
    type: DeliveryType,
    date: string,
    delivery_point?:string,
    subtype: CdekSubtype,
    interval?: {
      min: string,
      max: string,
    }
  }
  to: string,
  payment: {
    type: PaymentType,
  },
  doc_type: ShippingDocumentsType
}

export interface OrderPaymentRequest {
  order_ref: string,
  payment: {
    type: PaymentType,
  },
}

interface CheckoutRequests {
  confirmOrder(data: ConfirmOrderRequest): Promise<ConfirmOrderResponse>;

  getDeliveryInfo(data: {
    address_ref: string,
    order_ref: string,
    delivery_type: DeliveryType,
    delivery_subtype?: CdekSubtype,
    doc_type?: 'bsf'
  }): Promise<DeliveryInfoResponse>;

  checkout(data: CheckoutRequest): Promise<CheckoutResponse>;

  checkStatus(paymentId: string): Promise<CheckStatusResponse>;

  payment(data: OrderPaymentRequest): Promise<CheckoutResponse>;
}

export const CheckoutRequests: CheckoutRequests = {
  confirmOrder: (data) =>
    requests.post('store/order/form', data),

  getDeliveryInfo: (data) =>
    requests.get('client/orders/checkout/delivery_info' + mainStore.convertObjToGet(data)),

  checkout: (data) =>
    requests.post('client/orders/checkout', data),

  checkStatus: (paymentId) =>
    requests.get(`client/payments/alfabank/${paymentId}/status?token=10`),

  payment: (data) =>
    requests.post('client/payments', data)
};
