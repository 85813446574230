import { action, observable, runInAction, toJS, computed } from 'mobx';
import { AlertButton, AlertType, mainStore, SortingOrder } from './MainStore';
import { Order, OrderDetail, OrdersRequests, TranslatedOrderStatusClient } from '../api/Orders';
import { badRequestError, ErrorResponse, responseLockedError, responseOverlappingError } from '../api/Requests';
import { persist } from 'mobx-persist';
import { CACHE_EXPIRED_GOODS, CACHE_EXPIRED_ORDERS, ORDERS_INITIAL_LIMIT, ORDERS_PER_PAGE } from '../config';
import { formatISO } from 'date-fns';
import { CabinetRequests } from '../api/Cabinet';

export enum OrdersSortingName {
  None,
  Date,
  DatePayment,
  Client,
  ManagerStatus,
  ClientStatus
}

interface GoodItem extends OrderDetail {
  cacheDate: number,
}

export type ClientInFilter = [number, string, number?];

export default class OrdersStore {
  @persist('list') @observable orders: Order[] = [];
  @persist('list') @observable sortingOrder: SortingOrder[] = [0, 0, 0, 0, 0];
  @persist('list') @observable filterDateRange: [number, number] = [0, 0];
  @persist('list') @observable filterDatePaymentRange: [number, number] = [0, 0];
  @persist('object') @observable goods: { [key: string]: GoodItem } = {};
  @persist('object') @observable excludedClientId: { [key: string]: boolean } = {};
  @persist('object') @observable excludedPriceCheck: { [key: string]: boolean } = {};
  @persist('object') @observable excludedCreditCheck: { [key: string]: boolean } = {};
  @persist('object') @observable excludedCancelCheck: { [key: string]: boolean } = {};
  @persist('object') @observable excludedStatusCheck: { [key: string]: boolean } = {};
  @persist('object') @observable excludedStatusClientCheck: { [key: string]: boolean } = {};
  @persist @observable cacheDate: number | null = null;
  @persist @observable sortingName: OrdersSortingName = OrdersSortingName.None;
  @persist @observable filterOrdersDebt = false;
  @persist @observable clientsFilterPhrase = '';
  @persist @observable isAllOrdersLoaded = false;
  @observable statusList: string[] = [
    'Не обработан', 'Обработан оператором', 'Готов к маршрутизации', 'Готов к экспедиции', 'Экспедиция',
    'Доставленно',
    'Доставленно с актом',
  ];
  @observable statusClientList: string[] = Object.entries(TranslatedOrderStatusClient).map(([, val]) => (val));
  @observable clientId: number | null = null;
  @observable orderId: string | null = null;
  @observable clientsList: ClientInFilter[] = [];
  @observable curPage = 1;
  filterDateMin = '';
  filterDateMax = '';
  filterDatePaymentMin = '';
  filterDatePaymentMax = '';
  isDataParsed = false;

  @computed get filteredOrders(): Order[] {
    let orders = toJS(this.orders);
    if (!orders.length) return orders;
    if (Object.keys(this.excludedClientId).length === this.clientsList.length || Object.keys(
      this.excludedPriceCheck).length === 2 || Object.keys(this.excludedCreditCheck).length === 2) return [];
    orders = orders.filter((order) => {
      if (this.filterOrdersDebt) {
        if (mainStore.isZero(order.debt)) return false;
      }
      if (Object.keys(this.excludedClientId).length) {
        if (this.excludedClientId[order.customer.id.toString()]) return false;
      }
      if (Object.keys(this.excludedPriceCheck).length) {
        if (this.excludedPriceCheck[order.prices_checked ? 'true' : 'false']) return false;
      }
      if (Object.keys(this.excludedCreditCheck).length) {
        if (this.excludedCreditCheck[order.credit_control ? 'true' : 'false']) return false;
      }
      if (Object.keys(this.excludedCancelCheck).length) {
        if (this.excludedCancelCheck[order.cancellation ? 'true' : 'false']) return false;
      }
      if (Object.keys(this.excludedStatusCheck).length) {
        if (this.excludedStatusCheck[order.shipment_status]) return false;
      }
      if (Object.keys(this.excludedStatusClientCheck).length) {
        if (this.excludedStatusClientCheck[TranslatedOrderStatusClient[order.status]]) return false;
      }
      if (this.filterDateRange[0]) {
        if (order.date_timestamp * 1000 < this.filterDateRange[0]) return false;
      }
      if (this.filterDateRange[1]) {
        if (order.date_timestamp * 1000 > this.filterDateRange[1]) return false;
      }
      if (this.filterDatePaymentRange[0]) {
        if (order.payment_date_timestamp * 1000 < this.filterDatePaymentRange[0]) return false;
      }
      if (this.filterDatePaymentRange[1]) {
        if (order.payment_date_timestamp * 1000 > this.filterDatePaymentRange[1]) return false;
      }
      return true;
    });
    return orders;
  }

  @computed get sortedOrders(): Order[] {
    let orders = this.filteredOrders;
    if (!orders.length) return orders;
    if (!this.sortingName || !this.sortingOrder[this.sortingName]) return orders;
    switch (this.sortingName) {
      case OrdersSortingName.Date:
        orders = orders.slice().sort(({ date_timestamp: a }, { date_timestamp: b }) => {
          if (this.sortingOrder[this.sortingName] === SortingOrder.Asc) return a - b;
          return b - a;
        });
        break;
      case OrdersSortingName.DatePayment:
        orders = orders.slice().sort(({ payment_date_timestamp: a }, { payment_date_timestamp: b }) => {
          if (this.sortingOrder[this.sortingName] === SortingOrder.Asc) return a - b;
          return b - a;
        });
        break;
      case OrdersSortingName.Client:
        orders = orders.slice().sort(({ customer: { name: a } }, { customer: { name: b } }) => {
          if (!a || !b) return 0;
          return a.localeCompare(b) * (this.sortingOrder[this.sortingName] === SortingOrder.Asc ? 1 : -1);
        });
        break;
      case OrdersSortingName.ManagerStatus:
        orders = orders.slice().sort(({ shipment_status: a }, { shipment_status: b }) => {
          if (!a || !b) return 0;
          return a.localeCompare(b) * (this.sortingOrder[this.sortingName] === SortingOrder.Asc ? 1 : -1);
        });
        break;
      case OrdersSortingName.ClientStatus:
        orders = orders.slice().sort(({ status: a }, { status: b }) => {
          if (!a || !b) return 0;
          return a.localeCompare(b) * (this.sortingOrder[this.sortingName] === SortingOrder.Asc ? 1 : -1);
        });
        break;
    }
    return orders;
  }

  @computed get paginatedOrders(): Order[] {
    return this.sortedOrders.slice(0, this.curPage * ORDERS_PER_PAGE);
  }

  @computed get filteredOrdersDebt(): { debt: string, debtOverdue: string } {
    const debts = {
      debt: '0',
      debtOverdue: '0',
    };
    this.filteredOrders.forEach(({ debt, overdue_amount }) => {
      debts.debt = mainStore.additionFloat(debts.debt, debt);
      debts.debtOverdue = mainStore.additionFloat(debts.debtOverdue, overdue_amount);
    });
    return debts;
  }

  @computed get filteredClients(): ClientInFilter[] {
    let clients = toJS(this.clientsList);
    if (!clients.length || !this.clientsFilterPhrase) return clients;
    return clients.filter(([, name]) => name.toLowerCase().indexOf(this.clientsFilterPhrase.toLowerCase()) !== -1);
  }

  @computed get isLastPage(): boolean {
    return this.sortedOrders.length === this.paginatedOrders.length;
  }

  @action setInit() {
    if (!this.isDataParsed) this.parseOrdersData();
  };

  @action clearCache() {
    this.cacheDate = null;
    this.orders = [];
    this.goods = {};
    this.filterOrdersDebt = false;
    this.sortingOrder = [0, 0, 0, 0, 0];
    this.filterDateRange = [0, 0];
    this.filterDatePaymentRange = [0, 0];
    this.excludedClientId = {};
    this.excludedPriceCheck = {};
    this.excludedCreditCheck = {};
    this.excludedCancelCheck = {};
    this.excludedStatusCheck = {};
    this.excludedStatusClientCheck = {};
    this.sortingName = OrdersSortingName.None;
    this.clientsFilterPhrase = '';
    this.clientId = null;
    this.filterDateMin = '';
    this.filterDateMax = '';
    this.filterDatePaymentMin = '';
    this.filterDatePaymentMax = '';
    this.clientsList = [];
    this.isDataParsed = false;
    this.orderId = null;
    this.isAllOrdersLoaded = false;
    this.curPage = 1;
  }

  @action parseOrdersData() {
    this.isDataParsed = true;
    if (!this.orders.length) {
      this.clientsList = [];
      this.filterDateMin = '';
      this.filterDateMax = '';
      this.filterDatePaymentMin = '';
      this.filterDatePaymentMax = '';
      return;
    }
    let dateMin = 0;
    let dateMax = 0;
    let datePaymentMin = 0;
    let datePaymentMax = 0;
    let clients: { [key: number]: ClientInFilter } = {};
    for (let i = 0; i < this.orders.length; i++) {
      dateMax = !i ? this.orders[i].date_timestamp : Math.max(dateMax, this.orders[i].date_timestamp);
      datePaymentMax = !i ? this.orders[i].payment_date_timestamp : Math.max(
        datePaymentMax, this.orders[i].payment_date_timestamp);
      if (this.orders[i].date_timestamp) {
        dateMin = !dateMin ? this.orders[i].date_timestamp : Math.min(dateMin, this.orders[i].date_timestamp);
      }
      if (this.orders[i].payment_date_timestamp) {
        datePaymentMin = !datePaymentMin ? this.orders[i].payment_date_timestamp : Math.min(
          datePaymentMin, this.orders[i].payment_date_timestamp);
      }
      if (!clients[this.orders[i].customer.id]) {
        clients[this.orders[i].customer.id] = [
          this.orders[i].customer.id,
          this.orders[i].customer.name,
          this.orders[i].customer.pdz,
        ];
      }
    }
    if (!dateMin || !dateMax) dateMin = dateMax = dateMin || dateMax;
    if (!datePaymentMin || !datePaymentMax) datePaymentMin = datePaymentMax = datePaymentMin || datePaymentMax;
    this.filterDateMin = dateMin ? formatISO(dateMin * 1000, { representation: 'date' }) : '';
    this.filterDateMax = dateMax ? formatISO(dateMax * 1000, { representation: 'date' }) : '';
    this.filterDatePaymentMin = datePaymentMin ? formatISO(datePaymentMin * 1000, { representation: 'date' }) : '';
    this.filterDatePaymentMax = datePaymentMax ? formatISO(datePaymentMax * 1000, { representation: 'date' }) : '';
    this.clientsList = Object.values(clients).sort(([, a], [, b]) => a.localeCompare(b));
  };

  @action getOrders(force = false, isLoadAll = false): Promise<any> {
    if (mainStore.activeRequestsSet.has('orders_getOrders')) return Promise.reject(responseOverlappingError);
    if (!force && this.cacheDate && Date.now() - this.cacheDate < CACHE_EXPIRED_ORDERS) return Promise.reject(
      responseLockedError);
    mainStore.activeRequestsSet.add('orders_getOrders');

    return OrdersRequests.getOrders({
      offset: 0,
      limit: isLoadAll ? 1000 : ORDERS_INITIAL_LIMIT,
    }).then(e => {
      runInAction(() => {
        this.cacheDate = Date.now();
        this.orders = (e.data || []).map(({
          button,
          cancellation,
          credit_control,
          customer = {},
          date_payment_format,
          date_timestamp,
          debt,
          delay_debt,
          id,
          number,
          overdue_amount,
          payment_amount,
          is_paid,
          payment_date_timestamp,
          price_total,
          prices_checked,
          status,
          shipment_status,
          order = {},
        }) => ({
          button,
          cancellation,
          credit_control,
          customer: {
            name: customer.name || '',
            id: customer.id || 0,
            pdz: customer.pdz,
          },
          date_payment_format,
          date_timestamp,
          debt,
          delay_debt,
          id,
          number,
          overdue_amount,
          payment_amount,
          is_paid,
          payment_date_timestamp,
          price_total,
          prices_checked,
          status,
          shipment_status,
          order: {
            ref: order.ref,
            payment_available: order.payment_available || false,
            checkout_req_data: order.checkout_req_data,
            postpay: order.postpay || false,
          },
        }));
        this.isAllOrdersLoaded = isLoadAll;
        this.parseOrdersData();
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => {
      runInAction(() => {
        console.log(error)
        this.isAllOrdersLoaded = false;
      });
      return this.errorHandler(error, 'getOrders');
    }).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('orders_getOrders');
      });
    });
  };

  @action
  async getOrderById(id: string) {
    const reqName = 'orders_getOrderById';

    if (mainStore.activeRequestsSet.has(reqName)) return Promise.reject(responseOverlappingError);

    mainStore.activeRequestsSet.add(reqName);
    try {
      return await OrdersRequests.getOrderById(id);
    } finally {
      runInAction(() => {
        mainStore.activeRequestsSet.delete(reqName);
      });
    }
  }

  @action getGoods(force: boolean = false): Promise<any> {
    if (!this.orderId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('orders_getGoods')) return Promise.reject(responseOverlappingError);
    if (this.goods[this.orderId]) {
      if (!force && this.goods[this.orderId].cacheDate && Date.now() - this.goods[this.orderId].cacheDate < CACHE_EXPIRED_GOODS) return Promise.reject(
        responseLockedError);
    }
    mainStore.activeRequestsSet.add('orders_getGoods');
    const orderId = this.orderId;
    return OrdersRequests.getGoods(this.orderId).then(e => {
      runInAction(() => {
        this.goods[orderId] = { ...e.data, cacheDate: Date.now() };
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getGoods')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('orders_getGoods');
      });
    });
  };

  @action getAddress() {
    if (!this.orderId) return Promise.reject(badRequestError);
    mainStore.activeRequestsSet.add('orders_getAddress');
    const addressId = this.goods[this.orderId].address_id;
    const orderId = this.orderId;

    return CabinetRequests.getAddressById({ addressId })
      .then((response) => {
        runInAction(() => {
          this.goods[orderId].address = response.data.address;
        });
      })
      .catch((error: ErrorResponse) => this.errorHandler(error, 'getAddress'))
      .finally(() => mainStore.activeRequestsSet.delete('orders_getAddress'));
  }

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage: string = '';
    let errorButton: AlertButton | undefined = undefined;
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
