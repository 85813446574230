import { IonRouterLink } from '@ionic/react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Category, FilterItem, FilterLineItem, Property, PropertyItem } from '../api/Catalog';
import { GetParams, mainStore } from '../stores/MainStore';

const PromotionCategoriesFilters: React.FC = () => {
  const history = useHistory()
  const handleResetFilter = () => {
    history.push(`/promotion/${mainStore.promotionsStore.clientId}/${mainStore.promotionsStore.promotionId}${mainStore.convertObjToGet({
      pt: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[0] : '',
      pid: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[1] : '',
      s: mainStore.promotionsStore.sortingOrder === 2 ? '2' : ''
    })}`);
  }
  const handleExpand = (part: string) => {
    mainStore.promotionsStore.filterPartExpaned[part] = !mainStore.promotionsStore.filterPartExpaned[part];
  }

  return (
    <div className="filter__wrap">
      <div className="filter__bg js-close-filter"/>
      <section className="filter">
        <div className="filter__close btn-close icon icon-close-round js-close-filter"/>
        <div className="filter__body js-custom-scroll">
          {mainStore.promotionsStore.filters.length > 0 && mainStore.promotionsStore.filters[0].data.length > 0 && (
            <div className={`filter__part _brand accordion${mainStore.promotionsStore.filterPartExpaned.brand ? ' _active' : ''}`}>
              <div className="accordion__title js-toggle-accordion"
                   onClick={() => handleExpand('brand')}>
                <div className="accordion__title-toggle"/>
                <span className="text-muted">БРЕНД</span>
              </div>
              <div className="accordion__body">
                <div className="accordion__body-wrap">
                  {mainStore.promotionsStore.filters[0].data.map((item) => (
                    <FilterRow item={item} key={item.id}/>
                  ))}
                </div>
              </div>
            </div>
          )}
          {mainStore.promotionsStore.categories.length > 0 && (
            <div className={`filter__part _category accordion${mainStore.promotionsStore.filterPartExpaned.category ? ' _active' : ''}`}>
              <div className="accordion__title js-toggle-accordion"
                   onClick={() => handleExpand('category')}>
                <div className="accordion__title-toggle"/>
                <span className="text-muted">КАТЕГОРИИ</span>
              </div>
              <div className="accordion__body">
                <div className="accordion__body-wrap">
                  <Categories items={mainStore.promotionsStore.categories}/>
                </div>
              </div>
            </div>
          )}
          {mainStore.promotionsStore.properties.length > 0 && (
            <div className={`filter__part _properties accordion${mainStore.promotionsStore.filterPartExpaned.properties ? ' _active' : ''}`}>
              <div className="accordion__title js-toggle-accordion"
                   onClick={() => handleExpand('properties')}>
                <div className="accordion__title-toggle"/>
                <span className="text-muted">ФИЛЬТРЫ</span>
              </div>
              <div className="accordion__body">
                <div className="accordion__body-wrap">
                  {mainStore.promotionsStore.properties.map((item) => (
                    <PropertyGroup property={item} key={item.property_id}/>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {mainStore.promotionsStore.activeFilters.count > 0 && (
          <div className="filter__footer">
            <button className="btn btn-secondary btn-block" onClick={handleResetFilter}>
              Сбросить
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

const Categories: React.FC<{ items: Category[] }> = observer(({items}) => {
  const param: GetParams = {
    pt: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[0] : '',
    pid: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[1] : '',
    b: mainStore.promotionsStore.activeFilters.brandIds.join(','),
    bl: mainStore.promotionsStore.activeFilters.brandLineIds.join(','),
    c: '',
    s: mainStore.promotionsStore.sortingOrder === 2 ? '2' : ''
  };

  return (
    <>
      {items && items.map((item) => (
        <React.Fragment key={item.id}>
          {(item.children && item.children.length > 0) ? (
            <div className={`accordion${item.is_expanded || (mainStore.promotionsStore.breadcrumbs.map(item => item.id).indexOf(item.id) !== -1) ? ' _active' : ''}`}>
              <div className="accordion__title js-toggle-accordion"
                   onClick={() => item.is_expanded = !item.is_expanded}>
                <div>
                  {mainStore.firstLetterUp(item.name)}
                  &nbsp;<span className="fz-12 c-t66">({item.goods_count})</span>
                </div>
                {!item.is_expanded && (
                  <IonRouterLink
                    className="link-abs"
                    routerLink={`/promotion/${mainStore.promotionsStore.clientId}/${mainStore.promotionsStore.promotionId}${mainStore.convertObjToGet({
                      ...param,
                      c: item.id
                    })}`}/>
                )}
                <div className="accordion__title-toggle"/>
              </div>
              <div className="accordion__body">
                <Categories items={item.children}/>
              </div>
            </div>
          ) : (
            <CategoryRow cat={item}/>
          )}
        </React.Fragment>
      ))}
    </>
  );
});

const CategoryRow: React.FC<{ cat: Category }> = observer(({cat}) => {
  const param: string = mainStore.convertObjToGet({
    pt: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[0] : '',
    pid: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[1] : '',
    b: mainStore.promotionsStore.activeFilters.brandIds.join(','),
    bl: mainStore.promotionsStore.activeFilters.brandLineIds.join(','),
    c: cat.id,
    s: mainStore.promotionsStore.sortingOrder === 2 ? '2' : ''
  });

  return (
    <div className={`filter-category__item${mainStore.promotionsStore.breadcrumbs.map(item => item.id).indexOf(cat.id) !== -1 ? ' _active' : ''}`}>
      <div>
        {mainStore.firstLetterUp(cat.name)}
        &nbsp;<span className="fz-12 c-t66">({cat.goods_count})</span>
      </div>
      <Link className="link-abs" to={`/promotion/${mainStore.promotionsStore.clientId}/${mainStore.promotionsStore.promotionId}${param}`}/>
    </div>
  );
});

const FilterRow: React.FC<{ item: FilterItem }> = observer(({item}) => {
  const history = useHistory();

  let checkedBrandLines: number = 0;
  if (item.lines.length && !item.checked) {
    checkedBrandLines = mainStore.promotionsStore.getCheckedBrandLines(item.lines);
    if (checkedBrandLines === item.lines.length) item.checked = true;
  }
  const handleExpand = () => {
    item.is_expanded = !item.is_expanded;
  }
  const handleChange = () => {
    const param: GetParams = {
      pt: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[0] : '',
      pid: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[1] : '',
      b: '',
      bl: mainStore.promotionsStore.activeFilters.brandLineIds.join(','),
      s: mainStore.promotionsStore.sortingOrder === 2 ? '2' : ''
    };
    if (item.checked) {
      param.b = mainStore.promotionsStore.activeFilters.brandIds.filter(val => val !== item.id).join(',');
    } else {
      const lineIds: number[] = item.lines.map(item => item.id);
      param.b = mainStore.promotionsStore.activeFilters.brandIds.concat(item.id).join(',');
      param.bl = mainStore.promotionsStore.activeFilters.brandLineIds.filter(val => lineIds.indexOf(val) === -1).join(',');
    }
    history.push(`/promotion/${mainStore.promotionsStore.clientId}/${mainStore.promotionsStore.promotionId}${mainStore.convertObjToGet(param)}`);
  }

  return (
    <>
      {item.lines.length > 0 ? (
        <div className={`accordion${item.is_expanded ? ' _active' : ''}`}>
          <div className="accordion__title">
            <div className="custom-control custom-checkbox w-100">
              <input className={`custom-control-input${checkedBrandLines ? ' _indeterminate' : ''}`}
                     type="checkbox"
                     id={`filter_${item.id}`}
                     checked={item.checked || false}
                     onChange={handleChange}/>
              <label className="custom-control-label"
                     htmlFor={`filter_${item.id}`}>
                {item.name || 'Без названия'}
              </label>
            </div>
            <div className="accordion__title-toggle js-toggle-accordion" onClick={handleExpand}/>
          </div>
          <div className="accordion__body">
            {item.lines.map((itemLine) => (
              <FilterLineRow item={itemLine} parent={item} key={itemLine.id}/>
            ))}
          </div>
        </div>
      ) : (
        <div className="custom-control custom-checkbox">
          <input className="custom-control-input"
                 type="checkbox"
                 id={`filter_${item.id}`}
                 checked={item.checked || false}
                 onChange={handleChange}/>
          <label className="custom-control-label"
                 htmlFor={`filter_${item.id}`}>
            {item.name || 'Без названия'}
          </label>
        </div>
      )}
    </>
  );
});

const FilterLineRow: React.FC<{ item: FilterLineItem, parent: FilterItem }> = observer(({item, parent}) => {
  const history = useHistory();
  const handleChange = () => {
    const param: GetParams = {
      pt: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[0] : '',
      pid: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[1] : '',
      b: mainStore.promotionsStore.activeFilters.brandIds.join(','),
      bl: '',
      s: mainStore.promotionsStore.sortingOrder === 2 ? '2' : ''
    };
    if (item.checked) {
      if (parent.checked) {
        const lineIds: number[] = parent.lines.map(item => item.id).filter(val => val !== item.id);
        param.b = mainStore.promotionsStore.activeFilters.brandIds.filter((val) => val !== item.brand_id).join(',');
        param.bl = mainStore.promotionsStore.activeFilters.brandLineIds.concat(lineIds).join(',');
      } else {
        param.bl = mainStore.promotionsStore.activeFilters.brandLineIds.filter((val) => val !== item.id).join(',');
      }
    } else {
      if (parent.lines.filter(item => !item.checked).length === 1) {
        const lineIds: number[] = parent.lines.map(item => item.id);
        param.b = mainStore.promotionsStore.activeFilters.brandIds.concat(item.brand_id).join(',');
        param.bl = mainStore.promotionsStore.activeFilters.brandLineIds.filter(val => lineIds.indexOf(val) === -1).join(',');
      } else {
        param.bl = mainStore.promotionsStore.activeFilters.brandLineIds.concat(item.id).join(',');
      }
    }
    history.push(`/promotion/${mainStore.promotionsStore.clientId}/${mainStore.promotionsStore.promotionId}${mainStore.convertObjToGet(param)}`);
  }

  return (
    <div className="custom-control custom-checkbox">
      <input className="custom-control-input"
             type="checkbox"
             id={`line_${item.brand_id}_${item.id}`}
             checked={item.checked || false}
             onChange={handleChange}/>
      <label className="custom-control-label"
             htmlFor={`line_${item.brand_id}_${item.id}`}>
        {item.name || 'Без названия'}
      </label>
    </div>
  );
});

const PropertyGroup: React.FC<{ property: Property }> = observer(({property}) => {
  const handleExpand = () => {
    property.is_expanded = !property.is_expanded;
  }

  return (
    <div className={`accordion${property.is_expanded ? ' _active' : ''}`}>
      <div className="accordion__title js-toggle-accordion" onClick={handleExpand}>
        <div className="accordion__title-toggle"/>
        {property.name || 'Без названия'}&nbsp;
        <span className="fz-12 c-t66 text-nowrap">({property.goods_count})</span>
      </div>
      <div className="accordion__body">
        {property.items.map((item) => (
          <PropertyRow item={item} key={item.item_id}/>
        ))}
      </div>
    </div>
  );
});

const PropertyRow: React.FC<{ item: PropertyItem }> = observer(({item}) => {
  const history = useHistory();
  const handleChange = () => {
    const param: GetParams = {
      pt: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[0] : '',
      pid: mainStore.promotionsStore.expandedPackId ? mainStore.promotionsStore.expandedPackId[1] : '',
      b: mainStore.promotionsStore.activeFilters.brandIds.join(','),
      bl: mainStore.promotionsStore.activeFilters.brandLineIds.join(','),
      c: mainStore.promotionsStore.breadcrumbs.length ? mainStore.promotionsStore.breadcrumbs.slice(-1)[0].id : '',
      f: '',
      s: mainStore.promotionsStore.sortingOrder === 2 ? '2' : ''
    };
    if (item.checked) {
      param.f = mainStore.promotionsStore.activeFilters.propIds.filter(val => val !== item.item_id).join(',');
    } else {
      param.f = mainStore.promotionsStore.activeFilters.propIds.concat(item.item_id).join(',');
    }
    history.push(`/promotion/${mainStore.promotionsStore.clientId}/${mainStore.promotionsStore.promotionId}${mainStore.convertObjToGet(param)}`);
  }

  return (
    <div className="custom-control custom-checkbox">
      <input className="custom-control-input"
             type="checkbox"
             id={`property_${item.item_id}`}
             checked={item.checked || false}
             onChange={handleChange}/>
      <label className="custom-control-label"
             htmlFor={`property_${item.item_id}`}>
        {item.name || 'Без названия'}&nbsp;<span className="fz-12 c-t66 text-nowrap">({item.goods_count})</span>
      </label>
    </div>
  );
});

export default inject('mainStore')(observer(PromotionCategoriesFilters));
