import { observer } from 'mobx-react';
import React from 'react';
import { mainStore } from '../stores/MainStore';
import BaseMap from './BaseMap';
import Popup from './Popup';

const PopupMap: React.FC = () => {
  const handlePopupDismiss = () => {
    mainStore.isShowMapPopup = false;
  };

  return(
    <>
      <Popup className="w-max-752" isShow={mainStore.isShowMapPopup} onBackdropDismiss={ handlePopupDismiss }>
        <BaseMap/>
      </Popup>
    </>
  )
}

export default observer(PopupMap);