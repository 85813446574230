import { mainStore, GetParams } from '../stores/MainStore';
import {
  CatalogTreeRequest,
  CatalogTreeResponse,
  FiltersResponse,
  GoodsRequest,
  GoodsResponse,
  PropertiesRequest,
  PropertiesResponse,
} from './Catalog';
import { ApiResponse, requests } from './Requests';

export interface PromotionShort {
  created_at: string,
  date_end: string,
  date_start: string,
  description: string,
  id: number,
  invalid: boolean,
  name: string,
  post: {
    content: string,
    content_html: string,
    created_at: string,
    excerpt: null,
    featured_images: {
      content_type: string,
      created_at: string,
      description: null,
      disk_name: string,
      extension: string,
      field: string,
      file_name: string,
      file_size: number,
      id: number,
      path: string,
      sort_order: number,
      title: null,
      updated_at: string,
    }[],
    has_summary: boolean,
    id: number,
    metadata: null,
    published: boolean,
    published_at: null,
    slug: string,
    summary: string,
    title: string,
    updated_at: string,
    user_id: null,
  },
  post_id: number,
  ref: string,
  settings: {
    multiplicity: number,
    n?: number,
  } | null,
  tab_view: string,
  type: 'base' | 'n_gifts' | 'free',
  updated_at: string,
}

export interface PromotionPack {
  comment: string | null,
  created_at: string,
  id: number,
  name: string,
  products: Record<string, PromotionPackProduct>,
  ref: string
  settings: {
    quantity_to_buy?: number,
    quantity_to_gift?: number,
    amount_to_buy?: number,
    discount?: number,
  } | null,
  type: 'base' | 'amount_to_buy' | 'quantity_to_buy' | 'discount' | 'good' | 'good_qty',
  updated_at: string,

  totalQty: number,
  isFixedCount: boolean,
}

export interface PromotionPackProduct {
  good_id: number,
  promotion_pack_id: number
  settings: {
    quantity_to_buy?: number,
    quantity_to_gift?: number,
    limit_to_gift?: number,
  } | null,
  type: 'base' | 'quantity_to_buy' | 'quantity_to_gift',
}

export interface Promotion extends PromotionShort {
  promotion_packs: Record<string, PromotionPack>,
  gift_packs: Record<string, PromotionPack>,

  promotionPackIds: string[],
  giftPackIds: string[],
  multiplicity: number,
  packFixedCountIds: string[],
  packNoFixedCountIds: string[],
  discount?: number,
}

interface GetPromotionsResponse extends ApiResponse {
  status: 'complete',
  data: {
    promotions: {
      data: PromotionShort[],
      current_page: number,
      first_page_url: string | null,
      from: number,
      next_page_url: string | null,
      path: string,
      per_page: string,
      prev_page_url: string | null,
      to: number,
      total: number,
      pages?: number,
      page?: string,
    }
  }
}

interface GetPromotionResponse extends ApiResponse {
  data: {
    promotion: Promotion,
  }
}

export interface PromotionsRequest {
  customer_id?: string,
  manager_id?: number,
  page: number,
  per_page: number,
}

export interface PromotionRequest {
  region_id: string,
  customer_id?: string,
  manager_id?: number,
}

export interface PromotionGoodsRequest extends GoodsRequest {
  promotion_id: number,
  region_id: string,
  promotion_pack_id?: number,
  promotion_gift_pack_id?: number,
}

export interface PromotionTreeRequest extends CatalogTreeRequest {
  promotion_id: number,
  region_id: string,
  promotion_pack_id?: number,
  promotion_gift_pack_id?: number,
}

export interface PromotionPropertiesRequest extends PropertiesRequest {
  promotion_id: number,
  region_id: string,
  promotion_pack_id?: number,
  promotion_gift_pack_id?: number,
}

export interface PromotionBrandsRequest {
  customer_id: number | string,
  promotion_id: number,
  region_id: string,
  manager_id?: number,
  promotion_pack_id?: number,
  promotion_gift_pack_id?: number,
}

interface PromotionsRequests {
  getPromotions(data?: PromotionsRequest): Promise<GetPromotionsResponse>;

  getPromotion(promotionID: string, data?: PromotionRequest): Promise<GetPromotionResponse>;

  getPromotionGoods(packType: 'goods' | 'gifts', data: PromotionGoodsRequest): Promise<GoodsResponse>;

  getPromotionBrands(packType: 'goods' | 'gifts', data: PromotionBrandsRequest): Promise<FiltersResponse>;

  getPromotionTree(packType: 'goods' | 'gifts', data: PromotionTreeRequest): Promise<CatalogTreeResponse>;

  getPromotionProperties(packType: 'goods' | 'gifts', data: PromotionPropertiesRequest): Promise<PropertiesResponse>;
}

export const PromotionsRequests: PromotionsRequests = {
  getPromotions: (data) =>
    requests.get('client/catalog/promotion' + mainStore.convertObjToGet(data as any || {})),

  getPromotion: (promotionID, data) =>
    requests.get('client/catalog/promotion/' + promotionID + mainStore.convertObjToGet(data as unknown as GetParams || {})),

  getPromotionGoods: (packType, data) =>
    requests.post(`client/catalog/promotion${packType === 'gifts' ? '_gift' : ''}/goods`, data),

  getPromotionBrands: (packType, data) =>
    requests.post(`client/catalog/promotion${packType === 'gifts' ? '_gift' : ''}/brands`, data),

  getPromotionTree: (packType, data) =>
    requests.post(`client/catalog/promotion${packType === 'gifts' ? '_gift' : ''}/tree`, data),

  getPromotionProperties: (packType, data) =>
    requests.post(`client/catalog/promotion${packType === 'gifts' ? '_gift' : ''}/properties`, data),
};
