import React from 'react';
import {inject, observer} from 'mobx-react';
import {IonToast} from '@ionic/react';
import {Alert, AlertButton, mainStore} from '../stores/MainStore';
import {ToastButton} from '@ionic/react/dist/types/components/IonToast';

const Alerts: React.FC = () => {
    const handleToastDismiss = () => {
        mainStore.alerts.pop();
    };

    const getButtons = (button: AlertButton | undefined): ToastButton[] => {
        const buttons: ToastButton[] = [
            {
                text: 'ОК',
                role: 'cancel',
                handler: handleToastDismiss
            }
        ];
        if (button) {
            buttons.unshift({
                text: button.text,
                cssClass: 'toast-button-cancel',
                handler: () => {
                    button.action();
                    handleToastDismiss();
                }
            });
        }
        return buttons;
    };

    if (!mainStore.alerts.length) return <></>;

    return (
        <>
            {mainStore.alerts.slice(-1).map((item: Alert) => (
                <IonToast
                    key={Math.random()}
                    isOpen={true}
                    message={item.message}
                    cssClass={item.cssClass}
                    buttons={getButtons(item.button)}
                />
            ))}
        </>
    );
};

export default inject('mainStore')(observer(Alerts));
