import React from 'react';
import { useLocation, useHistory } from 'react-router';
import { IonRouterLink } from '@ionic/react';
import { observer } from 'mobx-react';
import { mainStore } from '../stores/MainStore';
import { MenuPart } from '../api/Menu';

type MenuItemProps = {
  part: MenuPart,
  pathname: string
}

type MenuFooterItemProps = {
  part: MenuPart,
  pathname: string
}


export default observer(() => {
  const history = useHistory();
  const { pathname } = useLocation();
  const handleRemoveActiveClient = () => {
    mainStore.catalogStore.setClientId(null);
    mainStore.promotionsStore.setClientId(null);
    if (pathname.indexOf('catalog') !== -1) history.replace('/');
    if (pathname.indexOf('actions/') !== -1) history.replace('/actions');
  };
  const handleOpenChooseLocationPopup = () => mainStore.isShowChooseRegionPopup = true;

  return (
    <div className="menu__wrap">
      <div className="menu__bg js-close-menu" />
      <section className="menu">
        <div className="menu__logo js-close-menu">
          <img src="/assets/img/logo_beegudi_white.svg" alt="" />
          <IonRouterLink className="link-abs" routerLink="/" />
          <div className="menu__close btn-close icon icon-close-round js-close-menu" />
        </div>
        <div className="menu__content js-custom-scroll">
          <div className="menu__body">
            <nav className="menu-list">
              {mainStore.userStore.isManager && mainStore.catalogStore.clientId !== null && (
                <>
                  <div className="icon-text">
                    <div className="icon-text__text w-100 overflow-hidden">
                      <div>Активный клиент:</div>
                      <div className="fz-12">
                        {mainStore.cabinetStore.getClientById(
                          parseInt(mainStore.catalogStore.clientId, 10))?.customer.name || ''}
                      </div>
                    </div>
                    <span className="icon-text__icon icon icon-remove fz-20 m-0 ml-6 menu-list__item w-auto cur-p js-close-menu"
                          title="Сбросить"
                          onClick={handleRemoveActiveClient}
                    />
                  </div>
                  {mainStore.checkoutStore.deliveryAddress !== null && (
                    <>
                      {(mainStore.checkoutStore.deliveryAddress.region?.name || '').length > 0 && (
                        <div className="icon-text__text w-100 overflow-hidden c-white">
                          <div className="fz-12">
                            Регион:{' '}
                            <span title={mainStore.firstLetterUp(mainStore.checkoutStore.deliveryAddress.region.name)}>
                              {mainStore.firstLetterUp(mainStore.checkoutStore.deliveryAddress.region.name)}
                            </span>
                          </div>
                        </div>
                      )}
                      {mainStore.checkoutStore.deliveryAddress.address?.length > 0 && (
                        <div className="icon-text__text w-100 overflow-hidden c-white">
                          <div className="fz-12">
                            <span title={mainStore.checkoutStore.deliveryAddress.address}>
                              {mainStore.checkoutStore.formatedAddress}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="menu-list__space" />
                </>
              )}
              {!mainStore.userStore.isAuth && mainStore.locationRegionList.length > 0 && (
                <>
                  <div className="menu-list__item cur-p js-close-menu" onClick={handleOpenChooseLocationPopup}>
                    <div className="icon-text overflow-hidden">
                      <span className="icon-text__icon icon icon-map-point" />
                      <span className="icon-text__text text-truncate">
                        {mainStore.currentRegion !== null ? mainStore.currentRegion.name : 'Выберите регион'}
                      </span>
                    </div>
                  </div>
                  <div className="menu-list__space" />
                </>
              )}
              {mainStore.menu.menu.parts.map((part, i) => (
                <MenuItem part={part} pathname={pathname} key={i} />
              ))}
              <div className="menu-list__space" />
              <div className="menu-list__item js-close-menu">
                <div className="icon-text">
                  <span className="icon-text__icon icon icon-remove" />
                  <span className="icon-text__text">Выход из аккаунта</span>
                </div>
                <IonRouterLink className="link-abs" routerLink="/logout" />
              </div>
            </nav>
          </div>
          {mainStore.menu.footer.parts.length > 0 && (
            <div className="menu__footer">
              <nav className="menu-list">
                {mainStore.menu.footer.parts.map((part, i) => (
                  <MenuFooterItem part={part} pathname={pathname} key={i} />
                ))}
              </nav>
            </div>
          )}
        </div>
      </section>
    </div>
  );
});

const MenuItem = observer(({ part, pathname }: MenuItemProps) => {
  if (part.type === 'separator') return <div className="menu-list__space" />;
  if (!part.link) return <></>;
  const link: string = '/' + (part.type === 'dynamic' ? 'page/' : '') + part.link;
  pathname = pathname.replace('promotion', 'actions');

  return (
    <div className={`menu-list__item js-close-menu${pathname.indexOf(link) !== -1 ? ' _active' : ''}`}>
      <div className="icon-text">
        {part.icon && <img className="icon-text__icon" src={part.icon} alt="" />}
        <span className="icon-text__text">{part.name || 'Без названия'}</span>
      </div>
      <IonRouterLink className="link-abs" routerLink={link} />
    </div>
  );
});

const MenuFooterItem = observer(({ part, pathname }: MenuFooterItemProps) => {
  if (!part.link) return <></>;
  const link: string = '/' + (part.type === 'dynamic' ? 'page/' : '') + part.link;

  return (
    <div className={`menu-list__item js-close-menu${pathname.indexOf(link) !== -1 ? ' _active' : ''}`}>
      {part.name || 'Без названия'}
      <IonRouterLink className="link-abs" routerLink={link} />
    </div>
  );
});
