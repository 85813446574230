import { mainStore } from '../stores/MainStore';
import { ApiResponse, requests } from './Requests';

export interface Category {
  children: Category[],
  goods_count: number,
  id: number,
  name: string,

  is_expanded: boolean,
}

export interface FilterItem {
  id: number,
  lines: FilterLineItem[],
  name: string,

  checked: boolean,
  is_expanded: boolean,
}

export interface FilterLineItem {
  brand_id: number,
  id: number,
  name: string,

  checked: boolean,
}

export interface PropertyItem {
  goods_count: number,
  item_id: number,
  name: string,
  title: string,

  checked: boolean,
}

export interface Filter {
  data: FilterItem[],
  name: string,
  title: string,
}

export interface Property {
  goods_count: number,
  items: PropertyItem[],
  name: string,
  property_id: number,
  title: string,

  is_expanded: boolean,
}

export interface Good {
  article: string,
  brand_description: string,
  brand_line_name: string | null,
  brand_name: string | null,
  catalogs: GoodCatalog[],
  code: string,
  description: string,
  discount: GoodDiscount | null,
  good_id: number,
  image: string,
  name: string,
  notification_count: number | null,
  preview: string,
  price: string | null,
  price_with_discount: string | null,
  producing_country: string | null,
  promo: GoodPromo[],
  qty: number | null,
  technology_description: string,
  volume: string | null,
  volume_measure: string | null,
  weight: string | null,
  weight_measure: string | null,
  ref: string,

  offer_id?: number | null,
  offer_name?: string,
}

interface GoodDiscount {
  catalog_visibility: boolean,
  discount: number,
  name: string,
}

interface GoodPromo {
  id: number,
  name: string,
  post?: {
    slug: string,
  },
}

export interface GoodCatalog {
  id: number,
  name: string,
}

export interface GoodOffer {
  brand_description: string,
  brand_line_name: string | null,
  brand_name: string | null,
  description: string,
  goods: Good[],
  image: string,
  is_offer: boolean,
  name: string,
  offer_id: number | null,
  preview: string,
  producing_country: string | null,
  technology_description: string,

  sorting?: number,
}

export interface SearchAutocompleteItem {
  code: string,
  id: number,
  name: string,
}

export interface Region {
  id: string,
  iso_code: string,
  name: string,
  parent: null,
}

export interface FiltersResponse extends ApiResponse {
  data: {
    filter: Filter[] | null,
  };
}

export interface PropertiesResponse extends ApiResponse {
  data: {
    property: Property[] | null,
  };
}

export interface GoodsResponse extends ApiResponse {
  data: {
    goods: GoodOffer[] | null,
    page: number,
    pages: number,
    perPage: number,
    total: number,
  };
}

export interface GoodResponse extends ApiResponse {
  data: {
    catalogs: GoodCatalog[],
    good: GoodOffer,
  };
}

export interface CatalogTreeResponse extends ApiResponse {
  data: {
    catalogs: Category[] | null,
  };
}

export interface SearchAutocompleteResponse extends ApiResponse {
  data: {
    data: SearchAutocompleteItem[],
  };
}

interface RegionsResponse extends ApiResponse {
  data: {
    regions: Region[]
  };
}

export interface CategoriesRequest {
  customer_id: string,
  manager_id?: number,
}

export interface GoodsRequest {
  doc_type: string;
  customer_id: string | number,
  catalog_id?: number,
  manager_id?: number,
  filters: {
    brand_id?: number[],
    brand_line_id?: number[],
  },
  properties: number[],
  sort?: {
    name: string,
    type: 'asc' | 'desc',
  },
  q: string,
  page: number,
  perPage: number,
  region_id: string,
}

export interface CatalogTreeRequest {
  customer_id: number | string,
  manager_id?: number,
  filters: {
    brand_id?: number[],
    brand_line_id?: number[],
  },
}

export interface PropertiesRequest {
  customer_id: number | string,
  manager_id?: number,
  filters: {
    brand_id?: number[],
    brand_line_id?: number[],
  },
  catalog_id?: number,
}

export interface SearchAutocompleteRequest {
  q: string,
  perPage: number,
  page: number,
  customer_id: number | string,
  manager_id?: number,
}

interface CatalogRequests {
  getFilters(data: {
    customer_id: number | string,
    manager_id?: number,
  }): Promise<FiltersResponse>;

  getCatalogTree(data: CatalogTreeRequest): Promise<CatalogTreeResponse>;

  getProperties(data: PropertiesRequest): Promise<PropertiesResponse>;

  getGoods(data: GoodsRequest): Promise<GoodsResponse>;

  getGood(goodId: number, data: {
    doc_type?: string,
    customer_id: number | string,
    manager_id?: number,
  }): Promise<GoodResponse>;

  getSearchAutocomplete(data: SearchAutocompleteRequest): Promise<SearchAutocompleteResponse>;

  notifyArrival(goodId: number, data: {
    customer_id: number | string,
    manager_id?: number,
  }): Promise<ApiResponse>;

  getRegions(): Promise<RegionsResponse>;
}

export const CatalogRequests: CatalogRequests = {
  getFilters: (data) =>
    requests.get('client/catalog/filters' + mainStore.convertObjToGet(data)),

  getCatalogTree: (data) =>
    requests.post('client/catalog/catalog/tree', data),

  getProperties: (data) =>
    requests.post('client/catalog/properties', data),

  getGoods: (data) =>
    requests.post('client/catalog/goods', data),

  getGood: (goodId, data) =>
    requests.get(`client/catalog/goods/${goodId}` + mainStore.convertObjToGet(data)),

  getSearchAutocomplete: (data) =>
    requests.get('client/catalog/goods/autocomplete' + mainStore.convertObjToGet(data as {})),

  notifyArrival: (goodId, data) =>
    requests.post(`client/catalog/goods/${goodId}/notify_arrival`, data),

  getRegions: () =>
    requests.get('client/catalog/goods/regions'),
};
