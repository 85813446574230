import { ApiResponse, requests } from './Requests';

export interface RoutePlan {
  brand_id: number,
  exclusive_plan: number | string,
  sale_plan: number | string,
}

export interface RouteTask {
  editable: boolean,
  finished: boolean,
  id: number,
  text: string,
}

export interface Route {
  address_id: number,
  closed: boolean,
  customer_id: number,
  date: number,
  id: number,
  expanded: boolean,
  plans: RoutePlan[],
  tasks: RouteTask[],
}

export interface ServerTime {
  serverTime: string,
  serverTimezoneName: string,
  serverTimezoneOffset: number,
}

export interface Client {
  address: string,
  address_id: number,
  brand_plan: BrandPlan[],
  original_brand_plan: BrandPlan[],
  customer_id: number,
  customer_name: string,
  expanded: boolean,
  debt: {
    visit: number,
    shipment: number,
    pdz: number,
    pdz_day: number,
    pdz_percent: number,
  },
}

export interface PotentialClient {
  address: string | null,
  email: string | null,
  id: number,
  name: string | null,
  phone: string | null,
}

export interface BrandPlan {
  address_id: number,
  brand_id: number,
  brand_name: string,
  commom_total_exclusive_plan: string | number,
  common_total_sale_plan: string | number,
  total_exclusive_done: string | number,
  total_exclusive_plan: string | number,
  total_sale_done: string | number,
  total_sale_plan: string | number,
}

export interface BrandPlanTotal {
  total_exclusive_done: number,
  total_exclusive_plan: number,
  total_sale_done: number,
  total_sale_plan: number,
}

interface MoveClientsResponseItem {
  data: any,
  errors: [
    {
      type: string,
      id: number
    }
  ],
  success: boolean
}

interface RoutesResponse extends ApiResponse {
  data: Route[][],
}

interface ServerTimeResponse extends ApiResponse {
  data: ServerTime,
}

interface ClientsResponse extends ApiResponse {
  data: {
    clients: Client[],
    potential_clients: PotentialClient[],
  };
}

interface AddClientsResponse extends ApiResponse {
  data: Route[],
}

interface MoveClientsResponse extends ApiResponse {
  data: MoveClientsResponseItem[];
}

interface AddTaskResponse extends ApiResponse {
  data: {
    id: number;
  };
}

interface RoutesRequests {
  getServerTime(timezone: string): Promise<ServerTimeResponse>;

  getRoutes(): Promise<RoutesResponse>;

  getClients(): Promise<ClientsResponse>;

  addClients(data: {
    customer_id: number,
    address_id: number,
    date: string,
  }): Promise<AddClientsResponse>;

  updateRoute(data: {
    route_id: number,
    brand_id: number,
    sale_plan: number,
    exclusive_plan: number,
  }): Promise<ApiResponse>;

  closeRoute(route_id: number, data: {
    lat: string,
    long: string,
  }): Promise<ApiResponse>;

  moveRoute(data: {
    routes: {
      route_id: number,
      date: string,
    }[]
  }): Promise<MoveClientsResponse>;

  copyRoute(data: {
    routes: {
      route_id: number,
      date: string,
    }[]
  }): Promise<AddClientsResponse>;

  addTask(data: {
    route_id: number;
    text: string;
  }): Promise<AddTaskResponse>;

  editTask(data: {
    task_id: number;
    text: string;
  }): Promise<ApiResponse>;

  toggleTask(data: {
    task_id: number;
  }): Promise<ApiResponse>;

  removeTask(data: {
    task_id: number;
  }): Promise<ApiResponse>;
}

export const RoutesRequests: RoutesRequests = {
  getServerTime: (timezone) =>
    requests.get(`server_time?timezone=${timezone}`),

  getRoutes: () =>
    requests.get('client/route'),

  getClients: () =>
    requests.get('manager/clients'),

  addClients: (data) =>
    requests.post('client/route/create', data),

  updateRoute: (data) =>
    requests.post('client/route/update', data),

  closeRoute: (routeId, data) =>
    requests.post(`client/route/${routeId}/close`, data),

  moveRoute: (data) =>
    requests.post('client/route/transfer', data),

  copyRoute: (data) =>
    requests.post('client/route/copy', data),

  addTask: (data) =>
    requests.post('client/task/create', data),

  editTask: (data) =>
    requests.post('client/task/update', data),

  toggleTask: (data) =>
    requests.post('client/task/toggle', data),

  removeTask: (data) =>
    requests.post('client/task/delete', data),
};
