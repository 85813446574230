import { ApiResponse, requests } from './Requests';

interface DynamicPageResponse extends ApiResponse {
  data: {
    content: string,
  };
}

interface DynamicPageRequests {
  getPage(part: string): Promise<DynamicPageResponse>;
}

export const DynamicPageRequests: DynamicPageRequests = {
  getPage: (part) =>
    requests.get(`client/get_dynamic_page/${part}`),
};
