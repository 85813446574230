// export const API_ROOT: string = 'https://vks.parfum-altai.ru/api/v1/';
export const API_ROOT: string = 'https://parfum.hpdev.ru/api/v1/';

export const CACHE_EXPIRED_MENU: number = 12 * 60 * 60 * 1000; //12 hours
export const CACHE_EXPIRED_CABINET: number = 12 * 60 * 60 * 1000; //12 hours
export const CACHE_EXPIRED_ROUTES: number = 12 * 60 * 60 * 1000; //12 hours
export const CACHE_EXPIRED_ORDERS: number = 12 * 60 * 60 * 1000; //12 hours
export const CACHE_EXPIRED_GOODS: number = 12 * 60 * 60 * 1000; //12 hours
export const CACHE_EXPIRED_DYNAMIC: number = 12 * 60 * 60 * 1000; //12 hours

export const REQUEST_TIMEOUT: number = 60000; //1 min

export const CATALOG_ITEMS_PER_PAGE: number = 25;
export const CATALOG_SEARCH_AUTOCOMPLETE_ITEMS_PER_PAGE: number = 10;
export const CATALOG_RECENT_ITEMS_COUNT: number = 20;

export const PROMOTION_ITEMS_PER_PAGE: number = 10;
export const PROMOTION_SEARCH_AUTOCOMPLETE_ITEMS_PER_PAGE: number = 10;

export const CLIENTS_PER_PAGE = 15;
export const ORDERS_PER_PAGE = 15;
export const ORDERS_INITIAL_LIMIT = 50;

//export const PATH_UPDATE_XML: string = 'https://app.parfum.hpdev.ru/update_client.xml';
//export const PATH_UPDATE_XML_DEV: string = 'https://app.parfum.hpdev.ru/update_client_dev.xml';

export const VERSION: string = '1.0.0'; //release.features.bugfixes
