import { ApiResponse, requests } from './Requests';
import { mainStore } from '../stores/MainStore';

export interface Manager {
  email: string | null,
  id: number,
  name: string | null,
  phone: string | null,
  photo: string | null,
}

export interface Cabinet {
  addresses: Address[],
  amount_debt: number,
  contact_name: string,
  contact_phone: string,
  contract_url: string,
  credit_limit: string | null,
  credit_limit_balance: string | null,
  deferment_of_payment: number,
  id: number,
  inn: string | null,
  kpp: string | null,
  legal_address: string,
  name: string,
  overdue_amount: number,
  status: string,
}

export interface Address {
  address: string,
  delivery: Delivery[],
  desc?: string,
  id?: number,
}

export interface Delivery {
  day?: string,
  end_time: string,
  number: number,
  start_time: string,
}

export interface Client {
  addresses: Address[],
  amount_debt: number,
  contact_name: string,
  contact_phone: string,
  credit_limit: number | null,
  credit_limit_balance: number | null,
  deferment_of_payment: number,
  inn: string | null,
  kpp: string | null,
  overdue_amount: number,
  status: string,
  customer: {
    id: number,
    name: string,
    pdz?: number,
  }
}

export interface DashboardItem {
  days: {
    from: number,
    to: number,
  };
  dn: {
    active: number,
    notactive: number,
    percent: number,
  };
  sales: {
    done: string,
    left: number,
    percent: number,
  };
}

export interface Dashboard {
  arrears_url: string,
  clients: DashboardItem,
  new_client: DashboardItem,
  receivables: {
    done: number,
    left: number,
    percent: number,
  }
  report_url: string,
}

export interface NewClient {
  name: string | null,
  address: string | null,
  phone: string | null,
  email: string | null,
  id: number,
}

export interface ClientAddress {
  active: boolean,
  address: string,
  cdek_city_id: number,
  created_at: string,
  customer_id: number,
  delivery_days: unknown[],
  id: number,
  last_update: boolean,
  lat: null,
  lon: null,
  phone: string,
  postal_code: string,
  ref: string,
  region: {
    id: string,
    iso_code: string,
    name: string,
    parent: null,
  },
  region_id: string,
  send_sms: boolean,
  short_address: string,
  updated_at: string,
}

interface PartsDataResponse extends ApiResponse {
  data: {
    manager: Manager,
    cabinet?: Cabinet,
    clients?: Client[],
    dashboard?: Dashboard,
    new_clients?: NewClient[],
    reconciliation_act_url?: string,
  };
}

interface AddNewClientResponse extends ApiResponse {
  data: {
    id: number,
  };
}

interface AddressListResponse extends ApiResponse {
  data: {
    addresses: {
      data: ClientAddress[],
      page: number,
      pages: number,
      perPage: number,
      total: number,
    },
  },
}

interface AddressResponse extends ApiResponse {
  data: {
    address: Address,
  }
}

interface CabinetRequests {
  getPartsData(): Promise<PartsDataResponse>;

  sendFeedback(data: {
    subject: string,
    message: string,
  }): Promise<ApiResponse>;

  addNewClient(data: {
    name: string,
    address: string,
    phone: string,
    email: string,
  }): Promise<AddNewClientResponse>;

  sendManagerContacts(data: {
    name: string,
    phone: string,
  }): Promise<ApiResponse>;

  sendClientContacts(data: {
    name: string,
    kpp: string,
    inn: string,
    legal_address: string,
    contact_name: string,
    contact_phone: string,
    addresses: Address[],
  }): Promise<ApiResponse>;

  getAddressListByClientId(data: {
    customer_id: number,
    per_page?: number,
    page?: number,
  }): Promise<AddressListResponse>;

  getAddressById(data: { addressId: number }) : Promise<AddressResponse>;

}

export const CabinetRequests: CabinetRequests = {
  getPartsData: () =>
    requests.post('client/work_shift/start'),

  sendFeedback: (data) =>
    requests.post('client/feedback', data),

  addNewClient: (data) =>
    requests.post('manager/potential_clients/add', data),

  sendManagerContacts: (data) =>
    requests.post('client/manager_contacts', data),

  sendClientContacts: (data) =>
    requests.post('client/client_delivery', data),

  getAddressListByClientId: (data) =>
    requests.get('client/addresses' + mainStore.convertObjToGet(data)),

  getAddressById: (data) =>
    requests.get('client/address/' + data.addressId),
};
