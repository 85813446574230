import { ApiResponse, requests } from './Requests';

export interface LoginResponse extends ApiResponse {
  data?: {
    user_name: string;
    user_type: string;
    token: string;
    refresh_token: string;
  };
}

interface RefreshLoginResponse extends ApiResponse {
  data: {
    token: string;
  };
}

interface AuthRequests {
  sendLogin(data: {
    email?: string;
    phone?: string;
    password: string;
  }): Promise<LoginResponse>;

  sendCode(data: {
    phone: string;
    code: string;
  }): Promise<LoginResponse>;

  refreshToken(data: {
    refresh_token: string;
  }): Promise<RefreshLoginResponse>;
}

export const AuthRequests: AuthRequests = {
  sendLogin: (data) =>
    requests.post('client/auth/auth', data),

  sendCode: (data) =>
    requests.post('client/auth/code', data),

  refreshToken: (data) =>
    requests.post('client/token/refresh', data),
};
