import { ApiResponse, requests } from './Requests';

export interface MenuPart {
  name: string;
  icon: string;
  type: 'static' | 'dynamic' | 'separator';
  link: string;
}

export interface Menu {
  header: {
    parts: MenuPart[];
  };
  menu: {
    parts: MenuPart[];
  };
  footer: {
    parts: MenuPart[];
  };
  content: string;
  min_position_price: number;
}

interface MenuResponse extends ApiResponse {
  data: Menu;
}

interface MenuRequests {
  getMenu(): Promise<MenuResponse>;
}

export const MenuRequests: MenuRequests = {
  getMenu: () =>
    requests.get('client/get_parts'),
};
