import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { mainStore } from '../stores/MainStore';
import Popup from './Popup';
import { ClientAddress } from '../api/Cabinet';
import { useLocation } from 'react-router';

export default observer(() => {
  const { pathname } = useLocation();
  const [isAlert, setIsAlert] = useState(false);
  const [clientAddress, setClientAddress] = useState<ClientAddress | null>(null);
  const handlePopupAddressDismiss = () => {
    if (pathname.indexOf('catalog') !== -1) return;
    mainStore.isShowChooseAddressPopup = false;
  };
  const handlePopupAlertDismiss = () => setIsAlert(false);
  const handleChooseAddress = (address: ClientAddress) => {
    mainStore.isShowChooseAddressPopup = false;
    if (mainStore.catalogStore.regionId && mainStore.catalogStore.regionId !== address.region_id) {
      setClientAddress(address);
      setIsAlert(true);
    } else {
      mainStore.checkoutStore.deliveryAddress = address;
      mainStore.catalogStore.regionId = address.region_id;
    }
  };
  const handleForceChooseAddress = () => {
    setIsAlert(false);
    if (!clientAddress) return;
    mainStore.catalogStore.basket = [];
    mainStore.promotionsStore.basket = {};
    mainStore.checkoutStore.deliveryAddress = clientAddress;
    mainStore.catalogStore.regionId = clientAddress.region_id;
  };
  const handleToggleBSF = () => {
    mainStore.catalogStore.docType = !mainStore.catalogStore.docType
  }

  return (
    <>
      <Popup isShow={mainStore.isShowChooseAddressPopup} onBackdropDismiss={handlePopupAddressDismiss}>
        <div className="board__title h6">Выберите адрес</div>
        <div className="mb-2 font-weight-bold">
          {pathname.indexOf(
            'catalog') !== -1 ? 'Для просмотра актуального каталога выберите ваш адрес:' : 'На какой адрес доставить заказ?'}
        </div>
        <div className="position-relative overflow-auto mb-12">
          {(mainStore.catalogStore.clientAddressList || []).map((item, i) => (
            <div
              className="br-8 p-3 border-mgray c-black cur-p mt-2 transition-color c-hover-bg-lgray"
              onClick={() => handleChooseAddress(item)}
              key={i}
            >
              {item.address || ''}
            </div>
          ))}
        </div>
        {!mainStore.checkoutStore.deliveryAddress && (
          <div className="custom-control custom-switch mb-3 mb-md-0 flex-shrink-0 mr-3">
          <input className="custom-control-input"
                id="bsf_switch"
                type="checkbox"
                checked={mainStore.catalogStore.docType}
                onChange={handleToggleBSF}
          />
          <label className="custom-control-label cur-p" htmlFor="bsf_switch">оформить заказ БСФ</label>  
        </div>
        )}
      </Popup>
      <Popup isShow={isAlert} onBackdropDismiss={handlePopupAlertDismiss}>
        <div className="board__title h6">Внимание</div>
        <div className="mb-4 font-weight-bold fz-20 lh-24">
          Выбранный адрес доставки относится к иному региональному складу. При подтверждении указанного адреса корзина будет очищена
        </div>
        <div className="row">
          <div className="col col-6">
            <button
              className="btn btn-secondary btn-block"
              onClick={handlePopupAlertDismiss}
            >
              Оставить текущий адрес
            </button>
          </div>
          <div className="col col-6">
            <button
              className="btn btn-primary btn-block"
              onClick={handleForceChooseAddress}
            >
              Продолжить
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
});
