import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

type PopupProps = {
  isShow: boolean,
  children: ReactNode,
  isBackdrop?: boolean,
  onBackdropDismiss?: () => void,
  className?: string,
}

export default observer(({
  isShow,
  children,
  isBackdrop = true,
  onBackdropDismiss,
  className,
}: PopupProps) => {
  const refPopup = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition in={isShow}
                   timeout={{ enter: 350, exit: 250 }}
                   mountOnEnter
                   unmountOnExit
                   classNames="popup" nodeRef={refPopup}
    >
      <div className="popup" ref={refPopup}>
        <div className={htmlClasses('popup__bg', { '_transparent': !isBackdrop })}
             onClick={onBackdropDismiss}
        />
        <div className={htmlClasses('popup__body', className)}>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
});
