import React from 'react';
import {IonRouterLink} from '@ionic/react';
import {useLocation} from 'react-router';
import {inject, observer} from 'mobx-react';
import {mainStore} from '../stores/MainStore';
import {MenuPart} from '../api/Menu';

const Footer: React.FC = () => {
    const {pathname} = useLocation();

    return (
        <footer className="footer">
            <div className="footer__menu">
                {mainStore.menu.footer.parts.map((part, i) => (
                    <FooterItem part={part} pathname={pathname} key={i}/>
                ))}
            </div>
            <div className="footer__body" dangerouslySetInnerHTML={{__html: mainStore.menu.content}}/>
        </footer>
    );
};

const FooterItem: React.FC<{ part: MenuPart, pathname: string }> = observer(({part, pathname}) => {
    if (!part.link) return <></>;
    const link: string = '/' + (part.type === 'dynamic' ? 'page/' : '') + part.link;

    return (
        <div className="footer__item">
            <IonRouterLink className={`footer__link${pathname.indexOf(link) !== -1 ? ' _active' : ''}`}
                           routerLink={link}>
                {part.name || 'Без названия'}
            </IonRouterLink>
        </div>
    );
});

export default inject('mainStore')(observer(Footer));
