import { Plugins } from '@capacitor/core';
import { RefresherEventDetail } from '@ionic/core';
import * as Sentry from '@sentry/browser';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_ROOT, REQUEST_TIMEOUT } from '../config';
import { mainStore } from '../stores/MainStore';

const { Network } = Plugins;

type HeadersToken = {
  headers: {
    token?: string
  }
}

export interface ApiResponse {
  success: boolean;
  status: string;
  message?: string | null;
  data?: any;
}

export interface ErrorResponse extends AxiosResponse {
  data: {
    success: boolean;
    message?: string;
    [key: string]: any;
  };
}

interface Requests {
  get(url: string): Promise<any>;

  post(url: string, body?: object): Promise<any>;
}

axios.defaults.baseURL = API_ROOT;
axios.defaults.timeout = REQUEST_TIMEOUT;

const responseBody = (response: AxiosResponse): Promise<ApiResponse> => response.data;
const responseError = (response: AxiosError): Promise<ErrorResponse> => Promise.reject(response.response);
export const responseNetworkError: AxiosResponse = {
  status: 12029,
  statusText: 'Cannot Connect to Internet',
  config: {},
  headers: null,
  data: null,
};
const responseTimeoutError: AxiosResponse = {
  status: 12002,
  statusText: 'Timeout Request',
  config: {},
  headers: null,
  data: null,
};
export const badRequestError: AxiosResponse = {
  status: 400,
  statusText: 'Bad Request',
  config: {},
  headers: null,
  data: null,
};
export const responseOverlappingError: AxiosResponse = {
  status: 409,
  statusText: 'Overlapping Request',
  config: {},
  headers: null,
  data: null,
};
export const responseLockedError: AxiosResponse = {
  status: 423,
  statusText: 'Not Expired',
  config: {},
  headers: null,
  data: null,
};

const setToken = (): HeadersToken | {} => {
  if (!mainStore.userStore.isAuth) return {};
  return { headers: { token: mainStore.userStore.tokenAccess } };
};

export const sequentialRequests = async (
  requests: (() => Promise<any>)[], refresherEvent?: CustomEvent<RefresherEventDetail>): Promise<any> => {
  for (let i = 0; i < requests.length; i++) {
    try {
      await requests[i]();
    } catch (error) {
      if (error.status === 403 || error.status === 12029) break;
    }
  }
  if (refresherEvent) refresherEvent.detail.complete();
};

const requestWrapper = async (request: () => Promise<any>): Promise<any> => {
  const { connected } = await Network.getStatus();
  if (!connected) return Promise.reject(responseNetworkError);
  try {
    return await request();
  } catch (error) {
    if (error.status === 403 && error.data.message === 'Token expired') {
      try {
        await mainStore.userStore.refreshToken();
        return request();
      } catch (e) {}
    }
    return Promise.reject(error);
  }
};

export const requests: Requests = {
  get: (url) => requestWrapper(() => axios
    .get(url, setToken())
    .then(responseBody)
    .catch(responseError)),

  post: (url, body) => requestWrapper(() => axios
    .post(url, body, setToken())
    .then(responseBody)
    .catch(responseError)),
};

const parseRequest = (request?: any): string => {
  if (request instanceof FormData) {
    let tempData: { [key: string]: any } = {};
    request.forEach((value, key) => {
      if (value instanceof File) value = 'binary';
      tempData[key] = value;
    });
    return JSON.stringify(tempData);
  }
  return JSON.stringify(request);
};

axios.interceptors.response.use(function (response) {
  Sentry.withScope(function (scope) {
    scope.setExtras({
      request: {
        url: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
        data: parseRequest(response.config.data),
      },
      response: JSON.stringify(response.data),
      status: response.status,
    });
    Sentry.captureMessage(
      `[${response.status}] ${response.config.baseURL}${response.config.url}`,
      Sentry.Severity.Info,
    );
  });
  return response;
}, function (error) {
  if (!error.response) {
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      error.response = responseTimeoutError;
      error.response.config = error.config;
    } else {
      error.response = {
        status: error.code,
        statusText: '',
        config: error.config,
        headers: null,
        data: {
          success: false,
          message: error.message || '',
        },
      };
    }
  }
  Sentry.withScope(function (scope) {
    scope.setExtras({
      request: {
        url: error.response.config.baseURL + error.response.config.url,
        method: error.response.config.method,
        data: parseRequest(error.response.config.data),
      },
      response: JSON.stringify(error.response.data),
      status: error.response.status,
    });
    Sentry.captureMessage(
      `[${error.response.status}] ${error.response.config.baseURL}${error.response.config.url}`,
      Sentry.Severity.Error,
    );
  });
  return Promise.reject(error);
});
