import { RefresherEventDetail } from '@ionic/core';
import { IonRefresher, IonRefresherContent } from '@ionic/react';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { sequentialRequests } from '../api/Requests';
import { mainStore } from '../stores/MainStore';

const Home: React.FC = () => {
  useEffect(() => {
    const force = mainStore.forceStartupRequests;
    sequentialRequests([
      () => mainStore.getMenu(force)
    ]).catch(() => void 0);
    mainStore.forceStartupRequests = false;
  }, []);

  const handleRefresher = (e: CustomEvent<RefresherEventDetail>) => {
    sequentialRequests([
      () => mainStore.getMenu(true)
    ], e).catch(() => void 0);
  }

  return (
    <>
      <IonRefresher className="ion-refresher"
                    slot="fixed"
                    onIonRefresh={handleRefresher}
                    disabled={!mainStore.isCapacitor}>
        <IonRefresherContent/>
      </IonRefresher>

      <div className="board board_p-sm">
        <div className="board__title h6">Акции</div>
        <div className="img-tile">
          <div className="img-tile__col">
            <div className="img-tile-item" style={{backgroundImage: "url(/assets/img/tile_1.jpg)"}}>
              <div className="img-tile-item__space"/>
              {/*<a className="link-abs" href="#"/>*/}
            </div>
          </div>
          <div className="img-tile__col">
            <div className="img-tile-item" style={{backgroundImage: "url(/assets/img/tile_2.jpg)"}}>
              <div className="img-tile-item__space"/>
              {/*<a className="link-abs" href="#"/>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="board board_p-sm">
        <div className="board__title h6">Новости</div>
        <div className="img-tile">
          <div className="img-tile__col">
            <div className="img-tile-item" style={{backgroundImage: "url(/assets/img/tile_3.jpg)"}}>
              <div className="img-tile-item__space"/>
              {/*<a className="link-abs" href="#"/>*/}
            </div>
          </div>
          <div className="img-tile__col">
            <div className="img-tile-item" style={{backgroundImage: "url(/assets/img/tile_4.jpg)"}}>
              <div className="img-tile-item__space"/>
              {/*<a className="link-abs" href="#"/>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject('mainStore')(observer(Home));
