import { isPlatform } from '@ionic/react';
import localForage from 'localforage';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import { create, persist } from 'mobx-persist';
import { DynamicPageRequests } from '../api/DynamicPage';
import { Menu, MenuRequests } from '../api/Menu';
import { badRequestError, ErrorResponse, responseLockedError, responseOverlappingError } from '../api/Requests';
import { CACHE_EXPIRED_DYNAMIC, CACHE_EXPIRED_MENU } from '../config';
import CabinetStore from './CabinetStore';
import CatalogStore from './CatalogStore';
import OrdersStore from './OrdersStore';
import PromotionsStore from './PromotionsStore';
import RoutesStore from './RoutesStore';
import UserStore from './UserStore';
import { Region, CatalogRequests } from '../api/Catalog';
import CheckoutStore from './CheckoutStore';
import MapStore from './MapStore';

export enum AlertType {
  Error = 'ion-alert-error',
  Warning = 'ion-alert-warning',
  Info = 'ion-alert-info',
  Success = 'ion-alert-success',
}

export type AlertButton = {
  text: string;
  action: () => any;
}

export type Alert = {
  message: string;
  cssClass: AlertType;
  button?: AlertButton;
}

export enum SortingOrder {
  None,
  Asc,
  Desc,
}

type DynamicPart = {
  cacheDate: number,
  content: string,
}

export type GetParams = Record<string, string | number | boolean | undefined>


export class MainStore {
  @persist('object') @observable menu: Omit<Menu, 'min_position_price'> = {
    header: {
      parts: [],
    },
    menu: {
      parts: [],
    },
    footer: {
      parts: [],
    },
    content: '',
  };
  @persist('object') @observable dynamicParts: Record<string, DynamicPart> = {};
  @persist('object') @observable currentRegion: Region | null = null;
  @persist @observable cacheMenuDate: number | null = null;
  @persist @observable isShowConfirmLocation = false;
  @observable storesInitComplete = false;
  @observable storeMainInit = false;
  @observable storeUserInit = false;
  @observable storeCabinetInit = false;
  @observable storeRoutesInit = false;
  @observable storeOrdersInit = false;
  @observable storeCatalogInit = false;
  @observable storePromotionsInit = false;
  @observable storeCheckoutInit = false;
  @observable storeMapInit = false;
  @observable alerts: Alert[] = [];
  @observable attentionMessage = '';
  @observable isCapacitor = isPlatform('capacitor');
  @observable userStore: UserStore = new UserStore();
  @observable cabinetStore: CabinetStore = new CabinetStore();
  @observable routesStore: RoutesStore = new RoutesStore();
  @observable ordersStore: OrdersStore = new OrdersStore();
  @observable catalogStore: CatalogStore = new CatalogStore();
  @observable promotionsStore: PromotionsStore = new PromotionsStore();
  @observable checkoutStore: CheckoutStore = new CheckoutStore();
  @observable mapStore: MapStore = new MapStore();
  @observable forceStartupRequests = !isPlatform('capacitor');
  @observable dynamicPart: string | null = null;
  @observable activeRequestsSet: Set<string> = new Set();
  @observable minBasketRowPrice = 0;
  @observable locationRegionList: Region[] = [];
  @observable isShowChooseRegionPopup = false;
  @observable isShowChooseAddressPopup = false;
  @observable isShowChooseDeliverySchedulePopup = false;
  @observable isShowMapPopup = false;
  @observable isShowDebtPopup = false;

  //@observable isScrollTop = true;

  @computed get isShowLoader(): boolean {
    return !!this.activeRequestsSet.size;
  };

  cleanPhoneNumber(phone: string): string {
    if (!phone) return '';
    phone = phone.replace(/\D/g, '');
    if (phone.length === 11) return '+7' + phone.slice(1);
    return phone;
  }

  prettyPhoneNumber(phone: string): string {
    if (!phone.length) return '';
    return phone.replace(/\D/g, '').slice(-10).replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
  };

  prettyPrice(price: number | string, delimiter = '.'): string {
    price = price.toString().replace(/\s/g, '').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
    if (delimiter === '.') return this.removeZeroFraction(this.addZeroFraction(price));
    return this.addZeroFraction(price).replace('.', ',');
  };

  prettyDate(input: number | string, locales: string, options: Record<string, string>): string {
    if (!input) return '';
    if (typeof input === 'number' && input < 9999999999) input *= 1000;
    return new Date(input).toLocaleDateString(locales, options);
  }

  addLeadingZero(number: number): string {
    return number < 10 ? `0${number}` : number.toString();
  };

  numericalFormsOfWord(num: number, words: [string, string, string]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
  };

  removeZeroFraction(number: number | string): string {
    number = number.toString().replace(',', '.');
    const splitNumber = number.split('.');
    if (!parseInt(splitNumber[1], 10)) return splitNumber[0];
    return number;
  };

  addZeroFraction(number: number | string, trimDoubleZero = false): string {
    number = number.toString().replace(',', '.');
    const splitNumber = number.split('.');
    if (!splitNumber[1]) return trimDoubleZero ? number : `${number}.00`;
    if (splitNumber[1].length === 1) return number + '0';
    if (splitNumber[1] === '00' && trimDoubleZero) return splitNumber[0];
    return number;
  };

  toFloat(number: number | string): number {
    return parseFloat(number.toString().replace(',', '.'));
  }

  isZero(number: number | string): boolean {
    return !this.toFloat(number);
  }

  additionFloat(a: string | number, b: string | number): string {
    if (this.isZero(a) && this.isZero(b)) return '0';
    return this.convertKopToRub(this.convertRubToKop(a) + this.convertRubToKop(b));
  }

  subtractionFloat(a: string | number, b: string | number): string {
    if (this.isZero(a) && this.isZero(b)) return '0';
    return this.convertKopToRub(this.convertRubToKop(a) - this.convertRubToKop(b));
  }

  convertRubToKop(number: number | string): number {
    if (this.isZero(number)) return 0;
    return parseInt(this.addZeroFraction(number).replace('.', ''), 10);
  }

  convertKopToRub(number: number | string): string {
    if (this.isZero(number)) return '0';
    number = number.toString().replace(/[.,\s]/g, '');
    const isNegative = number[0] === '-';
    if (isNegative) number = number.slice(1);
    if (number.length < 3) number = Array(4 - number.length).join('0') + number;
    const letters = number.split('');
    letters.splice(-2, 0, '.');
    return isNegative ? '-' + this.addZeroFraction(letters.join('')) : this.addZeroFraction(letters.join(''));
  }

  convertObjToGet(obj: GetParams, trimEmpty = true): string {
    const keys = Object.keys(obj);
    let output = '';
    if (!keys.length) return output;
    for (let i = 0; i < keys.length; i++) {
      if (obj[keys[i]] === undefined) continue;
      if (trimEmpty && obj[keys[i]] === '') continue;
      output += `&${keys[i]}=${obj[keys[i]]}`;
    }
    if (output) output = '?' + output.slice(1);
    return output;
  }

  declOfNum(number: number, titles: string[]): string {
    const cases: number[] = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  }

  splitIntoNumbers(str: string, separator: string): number[] {
    return str.split(separator).map((val => parseInt(val, 10))).filter(val => !isNaN(val));
  }

  firstLetterUp(text: string): string {
    if (!text) return '';
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  @action setInit() {
    this.storeMainInit = true;
    const hydrate = create({ storage: localForage });
    hydrate('userStore', this.userStore).then(() => {
      runInAction(() => {
        this.storeUserInit = true;
        this.initComplete();
      });
    });
    hydrate('cabinetStore', this.cabinetStore).then(() => {
      runInAction(() => {
        this.storeCabinetInit = true;
        this.initComplete();
      });
    });
    hydrate('routesStore', this.routesStore).then(() => {
      runInAction(() => {
        this.storeRoutesInit = true;
        this.initComplete();
      });
    });
    hydrate('ordersStore', this.ordersStore).then(() => {
      runInAction(() => {
        this.storeOrdersInit = true;
        this.initComplete();
      });
    });
    hydrate('catalogStore', this.catalogStore).then(() => {
      runInAction(() => {
        this.storeCatalogInit = true;
        this.initComplete();
      });
    });
    hydrate('promotionsStore', this.promotionsStore).then(() => {
      runInAction(() => {
        this.storePromotionsInit = true;
        this.initComplete();
      });
    });
    hydrate('checkoutStore', this.checkoutStore).then(() => {
      runInAction(() => {
        this.storeCheckoutInit = true;
        this.initComplete();
      });
    });
    hydrate('mapStore', this.mapStore).then(() => {
      runInAction(() => {
        this.storeMapInit = true;
        this.initComplete();
      });
    });
  };

  @action clearCache() {
    this.cacheMenuDate = null;
    this.menu = {
      header: {
        parts: [],
      },
      menu: {
        parts: [],
      },
      footer: {
        parts: [],
      },
      content: '',
    };
    this.dynamicParts = {};
    this.alerts = [];
    this.attentionMessage = '';
    this.dynamicPart = null;
    this.locationRegionList = [];
    this.currentRegion = null;
    this.isShowConfirmLocation = false;
    this.isShowChooseRegionPopup = false;
    this.isShowChooseAddressPopup = false;
  }

  @action initComplete() {
    if (this.storeMainInit && this.storeUserInit && this.storeCabinetInit && this.storeRoutesInit && this.storeOrdersInit && this.storeCatalogInit && this.storePromotionsInit && this.storeCheckoutInit) {
      this.storesInitComplete = true;
      this.userStore.setInit();
      this.cabinetStore.setInit();
      this.routesStore.setInit();
      this.ordersStore.setInit();
      this.catalogStore.setInit();
      this.promotionsStore.setInit();
      this.checkoutStore.setInit();
      this.mapStore.setInit();
    }
  };

  @action getMenu(force = false) {
    if (this.activeRequestsSet.has('main_getMenu')) return Promise.reject(responseOverlappingError);
    if (!force && this.cacheMenuDate && Date.now() - this.cacheMenuDate < CACHE_EXPIRED_MENU) return Promise.reject(
      responseLockedError);
    this.activeRequestsSet.add('main_getMenu');
    return MenuRequests.getMenu().then(e => {
      runInAction(() => {
        this.cacheMenuDate = Date.now();
        if (e.data.header) this.menu.header = e.data.header;
        if (e.data.menu) this.menu.menu = e.data.menu;
        if (e.data.footer) this.menu.footer = e.data.footer;
        if (e.data.content) this.menu.content = e.data.content;
        if (e.data.min_position_price) this.minBasketRowPrice = e.data.min_position_price;
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getMenu')).finally(() => {
      runInAction(() => {
        this.activeRequestsSet.delete('main_getMenu');
      });
    });
  };

  @action getPage(force = false) {
    if (!this.dynamicPart) return Promise.reject(badRequestError);
    if (this.activeRequestsSet.has('main_getPage')) return Promise.reject(responseOverlappingError);
    if (this.dynamicParts[this.dynamicPart]) {
      if (!force && this.dynamicParts[this.dynamicPart].cacheDate && Date.now() - this.dynamicParts[this.dynamicPart].cacheDate < CACHE_EXPIRED_DYNAMIC) return Promise.reject(
        responseLockedError);
    }
    this.activeRequestsSet.add('main_getPage');
    const dynamicPart = this.dynamicPart;
    return DynamicPageRequests.getPage(this.dynamicPart).then(e => {
      runInAction(() => {
        this.dynamicParts[dynamicPart] = {
          cacheDate: Date.now(),
          content: e.data.content,
        };
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPage')).finally(() => {
      runInAction(() => {
        this.activeRequestsSet.delete('main_getPage');
      });
    });
  };

  @action getRegions() {
    if (this.activeRequestsSet.has('main_getLocation')) return Promise.reject(responseOverlappingError);
    this.activeRequestsSet.add('main_getLocation');
    return CatalogRequests.getRegions().then(e => {
      runInAction(() => {
        this.locationRegionList = e.data.regions.map((item) => {
          return {
            ...item, name: `${item.name[0].toUpperCase()}${item.name.slice(
              1).toLowerCase()}`,
          };
        });
        if (!e.data.regions.length) {
          this.currentRegion = null;
          this.isShowConfirmLocation = false;
          return;
        }
        if (!this.currentRegion) {
          this.isShowConfirmLocation = true;
          for (let i = 0; i < this.locationRegionList.length; i++) {
            if (this.locationRegionList[i].name.toLowerCase().indexOf('новосибирск') !== -1) {
              this.currentRegion = toJS(this.locationRegionList[i]);
              break;
            }
          }
        }
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getLocation')).finally(() => {
      runInAction(() => {
        this.activeRequestsSet.delete('main_getLocation');
      });
    });
  }

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage = error.data?.message || 'На сервере произошла неизвестная ошибка';
    let errorButton: AlertButton | undefined = undefined;
    switch (error.status) {
      case 400:
        errorMessage = 'Отсутствуют необходимые данные';
        break;
      case 403:
        errorMessage = 'Доступ запрещён';
        break;
      case 404:
        if (errorMessage === 'User not found') {
          errorMessage = 'Пользователь не найден<br>Пожалуйста, перезайдите в свой аккаунт';
          errorButton = {
            text: 'Выйти',
            action: () => mainStore.userStore.logout(),
          };
        }
        break;
      case 12002:
        errorMessage = 'Не удалось получить данные от сервера, истекло время ожидания ответа';
        break;
      case 12029:
        errorMessage = 'Отсутствует подключение к сети Интернет';
        break;
    }
    if (errorMessage) {
      this.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    }
    return Promise.reject(error);
  };
}

export const mainStore = new MainStore();
