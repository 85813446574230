import { observer } from 'mobx-react';
import React from 'react';
import { mainStore } from '../stores/MainStore';

export default observer(() => {
  const handleDismiss = () => mainStore.isShowConfirmLocation = false;
  const handleOpenChooseLocationPopup = () => {
    mainStore.isShowConfirmLocation = false;
    mainStore.isShowChooseRegionPopup = true;
  };

  return (
    <div className="attention c-bg-white shadow-1">
      <div className="d-flex align-items-center flex-wrap my-n2">
        <div className="h6 mb-0 mr-3 my-1">Ваш регион - {mainStore.currentRegion?.name || ''}?</div>
        <div className="flex-shrink-0 d-flex align-items-center my-1">
          <div className="btn btn-primary btn-sm w-60" onClick={handleDismiss}>Да</div>
          <div className="btn btn-secondary btn-sm px-12 ml-2" onClick={handleOpenChooseLocationPopup}>
            Изменить регион
          </div>
        </div>
      </div>
      <div className="attention__close btn-close icon icon-close-round c-mtext" onClick={handleDismiss} />
    </div>
  );
});
