import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { mainStore } from '../stores/MainStore';
import Popup from './Popup';
import { UI } from '../UI';
import htmlClasses from 'html-classes';
import { Region } from '../api/Catalog';

export default observer(() => {
  const [regionList, setRegionList] = useState<Region[] | null>(null);
  const [searchVal, setSearchVal] = useState('');
  const handlePopupDismiss = () => {
    mainStore.isShowChooseRegionPopup = false;
    mainStore.isShowConfirmLocation = false;
  };
  const handleInputSearch = (e: React.FormEvent<HTMLInputElement>) => setSearchVal(e.currentTarget.value);
  const handleChooseRegion = (region: Region) => {
    handlePopupDismiss();
    mainStore.currentRegion = region;
  };

  useEffect(() => {
    if (!mainStore.isShowChooseRegionPopup) return;
    setSearchVal('');
    UI.scroll.toggleCustomScroll();
    //eslint-disable-next-line
  }, [mainStore.isShowChooseRegionPopup]);

  useEffect(() => {
    setRegionList(mainStore.locationRegionList);
    //eslint-disable-next-line
  }, [mainStore.locationRegionList]);

  useEffect(() => {
    if (!mainStore.isShowChooseRegionPopup || !mainStore.locationRegionList.length) return;
    if (!searchVal) {
      setRegionList(mainStore.locationRegionList);
      return;
    }
    setRegionList(
      mainStore.locationRegionList.filter((item) => item.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1));
  }, [searchVal]);

  return (
    <Popup className="w-max-752" isShow={mainStore.isShowChooseRegionPopup} onBackdropDismiss={handlePopupDismiss}>
      <div className="board__title h6">Ваш регион</div>
      <div className="position-relative">
        <div className="form-control__icon icon icon-magnifier pe-n fz-20 mr-2" />
        <input
          className="form-control pr-5"
          onChange={handleInputSearch}
          value={searchVal}
          placeholder="Поиск"
          title="Поиск"
          type="text"
        />
      </div>
      <div className="mt-3 h-max-500 position-relative mx-n3 js-custom-scroll">
        {regionList === null ? (
          <div className="h6 text-center">Не удалось загрузить список регионов</div>
        ) : (
          <>
            {regionList.length > 0 ? regionList.map((item, i) => (
              <React.Fragment key={item.id}>
                {i === 0 && <div className="h-1 c-bg-mgray pe-n" />}
                <div
                  className={htmlClasses(
                    'h-min-40 d-flex align-items-center p-3 fz-14 lh-16 cur-p',
                    item.id === mainStore.currentRegion?.id ? 'c-primary c-bg-lgray' : 'c-text',
                  )}
                  onClick={() => handleChooseRegion(item)}
                >
                  {item.name}
                </div>
                <div className="h-1 c-bg-mgray pe-n" />
              </React.Fragment>
            )) : (
              <div className="h6 text-center">По вашему запросу ничего не найдено</div>)
            }
          </>
        )}
      </div>
    </Popup>
  );
});
