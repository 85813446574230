import { action, computed, observable, runInAction, toJS } from 'mobx';
import { badRequestError, ErrorResponse, responseLockedError, responseOverlappingError } from '../api/Requests';
import { AlertButton, AlertType, mainStore } from './MainStore';
import { BrandPlanTotal, Client, Route, RoutesRequests } from '../api/Routes';
import { CACHE_EXPIRED_ROUTES } from '../config';
import { persist } from 'mobx-persist';
import {
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  getDate,
  getDaysInMonth,
  isSameMonth,
  isWeekend,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { Geolocation, Geoposition } from '@ionic-native/geolocation';

export default class RoutesStore {
  @persist('list') @observable routes: Route[][] = [];
  @persist('list') @observable clients: Client[] = [];
  @persist('list') @observable filterDateRange: [number, number] = [0, 0];
  @persist('list') @observable filterDateLimit: [string, string] = ['', ''];
  @persist('object') @observable excludedBrandId: { [key: string]: boolean } = {};
  @persist @observable cacheDate: number | null = null;
  @persist @observable serverTime: number = 0;
  @persist @observable brandsFilterPhrase: string = '';
  @persist @observable activeDay: number = 0;
  @observable curMonthYear: string = format(new Date(), ' MMM yyyy', { locale: ru });
  @observable curDay: number = 0;
  @observable lastDay: number = 0;
  @observable brandsListOutput: [number, string][] = [];
  @observable brandsList: [number, string][] = [];
  @observable routesOutput: Route[][] = [];
  @observable clientsOutput: Client[] = [];
  @observable excludedClients: Client[] = [];
  @observable totalCorrelation: BrandPlanTotal = {
    total_exclusive_done: 0,
    total_exclusive_plan: 0,
    total_sale_done: 0,
    total_sale_plan: 0,
  };
  isRoutesFiltered: boolean = false;

  @computed get getTotalPlanDay(): { exclusive: string, sale: string }[] {
    if (!this.routesOutput.length) return [];
    let totalPlans: { exclusive: string, sale: string }[] = [
      {
        exclusive: '0',
        sale: '0',
      },
    ];
    let startIndex: number = getDate(this.filterDateRange[0]);
    for (let i = 0; i < this.routesOutput.length; i++) {
      let plans: string[] = ['0', '0'];
      for (let j = 0; j < this.routesOutput[i].length; j++) {
        for (let k = 0; k < this.routesOutput[i][j].plans.length; k++) {
          if (this.excludedBrandId[this.routesOutput[i][j].plans[k].brand_id]) continue;
          plans[0] = mainStore.additionFloat(plans[0], this.routesOutput[i][j].plans[k].exclusive_plan);
          plans[1] = mainStore.additionFloat(plans[1], this.routesOutput[i][j].plans[k].sale_plan);
        }
      }
      totalPlans[startIndex + i] = {
        exclusive: mainStore.prettyPrice(plans[0]),
        sale: mainStore.prettyPrice(plans[1]),
      };
      totalPlans[0] = {
        exclusive: mainStore.additionFloat(totalPlans[0].exclusive, plans[0]),
        sale: mainStore.additionFloat(totalPlans[0].sale, plans[1]),
      };
    }
    return totalPlans;
  }

  @action setInit() {
    if (!this.isRoutesFiltered) this.filterRoutes();
  };

  @action clearCache() {
    this.cacheDate = null;
    this.routes = [];
    this.clients = [];
    this.filterDateRange = [0, 0];
    this.excludedBrandId = {};
    this.filterDateLimit = ['', ''];
    this.serverTime = 0;
    this.brandsFilterPhrase = '';
    this.curDay = 0;
    this.lastDay = 0;
    this.activeDay = 0;
    this.brandsListOutput = [];
    this.brandsList = [];
    this.routesOutput = [];
    this.clientsOutput = [];
    this.excludedClients = [];
    this.isRoutesFiltered = false;
    this.totalCorrelation = {
      total_exclusive_done: 0,
      total_exclusive_plan: 0,
      total_sale_done: 0,
      total_sale_plan: 0,
    };
  }

  @action calcDataRange() {
    if (!this.serverTime) {
      this.serverTime = 0;
      this.filterDateLimit = ['', ''];
      this.filterDateRange = [0, 0];
      this.routesOutput = [];
      return;
    }
    this.filterDateLimit = [
      formatISO(startOfMonth(this.serverTime), { representation: 'date' }),
      formatISO(endOfMonth(this.serverTime), { representation: 'date' }),
    ];
    if (this.filterDateRange[0] || this.filterDateRange[1]) {
      if (!isSameMonth(this.filterDateRange[0], this.serverTime)) this.filterDateRange[0] = 0;
      if (!isSameMonth(this.filterDateRange[1], this.serverTime)) this.filterDateRange[1] = 0;
    }
    this.curMonthYear = format(this.serverTime, ' MMM yyyy', { locale: ru });
    this.curDay = getDate(this.serverTime);
    this.lastDay = getDate(endOfMonth(this.serverTime));
  }

  @action calcWorkWeekRange() {
    if (!this.serverTime) {
      this.filterDateLimit = ['', ''];
      this.filterDateRange = [0, 0];
      return;
    }
    let startWeek: Date = startOfWeek(this.serverTime, { weekStartsOn: 1 });
    let endWeek: Date = endOfWeek(this.serverTime, { weekStartsOn: 1 });
    if (!isWeekend(this.serverTime)) endWeek = subDays(endWeek, 2);
    if (!isSameMonth(startWeek, this.serverTime)) startWeek = startOfMonth(this.serverTime);
    if (!isSameMonth(endWeek, this.serverTime)) endWeek = endOfMonth(this.serverTime);
    this.filterDateRange = [
      startWeek.getTime(),
      endWeek.getTime(),
    ];
    this.activeDay = this.curDay;
  }

  @action parseRoutes(data: Route[][]) {
    this.routes = [];
    if (!this.serverTime) return;
    let list: Route[][] = [];
    for (let i = 0; i < data.length; i++) list[getDate(data[i][0].date)] = data[i];
    for (let i = 0; i <= getDaysInMonth(this.serverTime); i++) this.routes[i] = list[i] || [];
  }

  @action parseBrands() {
    let brands: { [key: number]: [number, string] } = {};
    for (let i = 0; i < this.clients.length; i++) {
      this.clients[i].original_brand_plan = this.clients[i].brand_plan;
      if (!this.clients[i].brand_plan.length) continue;
      for (let j = 0; j < this.clients[i].brand_plan.length; j++) {
        if (!brands[this.clients[i].brand_plan[j].brand_id]) {
          brands[this.clients[i].brand_plan[j].brand_id] = [
            this.clients[i].brand_plan[j].brand_id,
            this.clients[i].brand_plan[j].brand_name,
          ];
        }
      }
    }
    this.brandsList = Object.values(brands).sort((a, b) => a[1].localeCompare(b[1]));
    this.checkBrandsExcluded(Object.keys(brands));
    this.filterBrandsList();
  }

  @action filterBrandsList() {
    this.brandsListOutput = toJS(this.brandsList);
    if (!this.brandsListOutput.length || !this.brandsFilterPhrase) return;
    this.brandsListOutput = this.brandsListOutput.filter(
      (item) => item[1].toLowerCase().indexOf(this.brandsFilterPhrase.toLowerCase()) !== -1);
  }

  @action checkBrandsExcluded(brandIds: string[]) {
    if (!brandIds.length) {
      mainStore.routesStore.excludedBrandId = {};
      return;
    }
    const excludedIds: string[] = Object.keys(mainStore.routesStore.excludedBrandId);
    if (!excludedIds.length) return;
    for (let i = 0; i < excludedIds.length; i++) {
      if (brandIds.indexOf(excludedIds[i]) === -1) {
        delete mainStore.routesStore.excludedBrandId[excludedIds[i]];
      }
    }
  }

  @action filterRoutes() {
    this.isRoutesFiltered = true;
    this.routesOutput = [];
    if (!this.filterDateRange[0] || !this.filterDateRange[1] || !this.routes.length || !this.clients.length) return;
    this.routesOutput = this.routes.slice(getDate(this.filterDateRange[0]), getDate(this.filterDateRange[1]) + 1);
    const dayRange: [number, number] = [getDate(this.filterDateRange[0]), getDate(this.filterDateRange[1])];
    if (!this.activeDay) this.activeDay = this.curDay;
    if (this.activeDay < dayRange[0] || this.activeDay > dayRange[1]) this.activeDay = dayRange[0];
    this.filterClients();
  }

  @action filterClients() {
    this.filterClientBrands();
    this.excludedClients = [];
    this.clientsOutput = [];
    if (!this.routesOutput.length || !this.routes.length || !this.clients.length) {
      this.clientsOutput = this.clients;
      return;
    }
    const addressIds: number[] = [];
    for (let i = 0; i < this.routesOutput.length; i++) {
      if (!this.routesOutput[i].length) continue;
      for (let j = 0; j < this.routesOutput[i].length; j++) {
        addressIds.push(this.routesOutput[i][j].address_id);
      }
    }
    this.clientsOutput = this.clients.filter((item) => {
      if (addressIds.indexOf(item.address_id) !== -1) {
        this.excludedClients.push(item);
        return false;
      }
      return true;
    });
  }

  @action filterClientBrands() {
    const excludedIds: string[] = Object.keys(mainStore.routesStore.excludedBrandId);
    this.totalCorrelation = {
      total_exclusive_done: 0,
      total_exclusive_plan: 0,
      total_sale_done: 0,
      total_sale_plan: 0,
    };
    for (let i = 0; i < this.clients.length; i++) {
      if (!this.clients[i].original_brand_plan.length) continue;
      if (!excludedIds.length) this.clients[i].brand_plan = this.clients[i].original_brand_plan;
      else this.clients[i].brand_plan = this.clients[i].original_brand_plan.filter(
        (item) => !mainStore.routesStore.excludedBrandId[item.brand_id.toString()]);
      for (let j = 0; j < this.clients[i].brand_plan.length; j++) {
        this.totalCorrelation.total_exclusive_done += parseInt(
          this.clients[i].brand_plan[j].total_exclusive_done.toString(), 10);
        this.totalCorrelation.total_exclusive_plan += parseInt(
          this.clients[i].brand_plan[j].total_exclusive_plan.toString(), 10);
        this.totalCorrelation.total_sale_done += parseInt(this.clients[i].brand_plan[j].total_sale_done.toString(), 10);
        this.totalCorrelation.total_sale_plan += parseInt(this.clients[i].brand_plan[j].total_sale_plan.toString(), 10);
      }
    }
  }

  @action getClientByAddressId(addressId: number): Client | null {
    if (!this.excludedClients.length) return null;
    for (let i = 0; i < this.excludedClients.length; i++) {
      if (this.excludedClients[i].address_id === addressId) return this.excludedClients[i];
    }
    return null;
  }

  @action getServerTime(): Promise<any> {
    if (mainStore.activeRequestsSet.has('routes_getServerTime')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_getServerTime');
    return RoutesRequests.getServerTime('Asia/Novosibirsk').then(e => {
      runInAction(() => {
        const serverTimeTemp: number = e.data.serverTime ? new Date(e.data.serverTime.slice(0, 10)).getTime() : 0;
        const isOtherDay: boolean = !(serverTimeTemp === this.serverTime);
        this.serverTime = serverTimeTemp;
        this.calcDataRange();
        if (isOtherDay || !this.filterDateRange[0] || !this.filterDateRange[1]) this.calcWorkWeekRange();
        this.filterRoutes();
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => {
      runInAction(() => {
        this.serverTime = 0;
        this.filterDateLimit = ['', ''];
        this.filterDateRange = [0, 0];
        this.routesOutput = [];
      });
      return this.errorHandler(error, 'getServerTime');
    }).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_getServerTime');
      });
    });
  };

  @action getRoutes(force: boolean = false): Promise<any> {
    if (!this.serverTime) return Promise.resolve();
    if (mainStore.activeRequestsSet.has('routes_getRoutes')) return Promise.reject(responseOverlappingError);
    if (!force && this.cacheDate && Date.now() - this.cacheDate < CACHE_EXPIRED_ROUTES) return Promise.reject(
      responseLockedError);
    mainStore.activeRequestsSet.add('routes_getRoutes');
    return RoutesRequests.getRoutes().then(e => {
      runInAction(() => {
        this.cacheDate = Date.now();
        this.parseRoutes(e.data || []);
        this.getClients();
        this.filterRoutes();
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getRoutes')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_getRoutes');
      });
    });
  };

  @action getClients() {
    if (mainStore.activeRequestsSet.has('routes_getClients')) return;
    mainStore.activeRequestsSet.add('routes_getClients');
    RoutesRequests.getClients().then(e => {
      runInAction(() => {
        this.clients = e.data.clients || [];
        this.parseBrands();
        this.filterRoutes();
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getClients')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_getClients');
      });
    });
  };

  @action addClients(customerId: number, addressId: number) {
    if (!this.activeDay) {
      mainStore.alerts.push({
        message: 'Сперва выберите день на который хотите добавить клиента',
        cssClass: AlertType.Error,
      });
      return;
    }
    if (this.activeDay < this.curDay) {
      mainStore.alerts.push({
        message: 'Невозможно добавить клиента в прошедший день',
        cssClass: AlertType.Error,
      });
      return;
    }
    if (customerId === undefined || addressId === undefined) return;
    if (mainStore.activeRequestsSet.has('routes_addClients')) return;
    mainStore.activeRequestsSet.add('routes_addClients');
    RoutesRequests.addClients({
      customer_id: customerId,
      address_id: addressId,
      date: this.filterDateLimit[0].slice(0, -2) + mainStore.addLeadingZero(this.activeDay),
    }).then((e) => {
      runInAction(() => {
        if (!e.data.length) return;
        this.routes[this.activeDay].push(e.data[0]);
        this.filterRoutes();
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'addClients').catch(() => void 0)).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_addClients');
      });
    });
  };

  @action updateRoute(routeId: number, brandId: number, exclusivePlan: number, salePlan: number): Promise<any> {
    if (mainStore.activeRequestsSet.has('routes_updateRoute')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_updateRoute');
    return RoutesRequests.updateRoute({
      route_id: routeId,
      brand_id: brandId,
      sale_plan: salePlan,
      exclusive_plan: exclusivePlan,
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'updateRoute')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_updateRoute');
      });
    });
  };

  @action
  async closeRoute(routeId: number): Promise<any> {
    if (mainStore.activeRequestsSet.has('routes_closeRoute')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_closeRoute');
    let coords: { lat: string, long: string } = {
      lat: '53.321512',
      long: '83.642199',
    };
    try {
      const data: Geoposition = await Geolocation.getCurrentPosition();
      coords.lat = data.coords.latitude.toString();
      coords.long = data.coords.longitude.toString();
    } catch (e) {}
    return RoutesRequests.closeRoute(routeId, coords).catch(
      (error: ErrorResponse) => this.errorHandler(error, 'closeRoute')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_closeRoute');
      });
    });
  };

  @action moveRoute(routeId: number, newDay: number, day: number) {
    if (mainStore.activeRequestsSet.has('routes_moveRoute')) return;
    mainStore.activeRequestsSet.add('routes_moveRoute');
    RoutesRequests.moveRoute({
      routes: [
        {
          route_id: routeId,
          date: mainStore.routesStore.filterDateLimit[0].slice(0, -2) + mainStore.addLeadingZero(newDay),
        },
      ],
    }).then((e) => {
      runInAction(() => {
        let isDouble: boolean = false;
        let movedRoute: Route | null = null;
        let failsId: number[] = [];
        for (let i = 0; i < e.data.length; i++) {
          if (e.data[i].errors.length && e.data[i].errors[0].id) {
            if (e.data[i].errors[0].type === 'Duplicate route') isDouble = true;
            failsId.push(e.data[i].errors[0].id);
          }
        }
        this.routes[day] = this.routes[day].filter((route) => {
          if (route.id === routeId) {
            movedRoute = route;
            return false;
          }
          return true;
        });
        if (movedRoute) this.routes[newDay].push(movedRoute);
        if (failsId.length) {
          mainStore.alerts.push({
            message: isDouble ? 'Дубль маршрута' : 'При переносе маршрута произошла ошибка',
            cssClass: AlertType.Error,
          });
        } else this.filterRoutes();
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'moveRoute')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_moveRoute');
      });
    });
  };

  @action copyRoute(routeId: number, newDay: number) {
    if (mainStore.activeRequestsSet.has('routes_copyRoute')) return;
    mainStore.activeRequestsSet.add('routes_copyRoute');
    RoutesRequests.copyRoute({
      routes: [
        {
          route_id: routeId,
          date: mainStore.routesStore.filterDateLimit[0].slice(0, -2) + mainStore.addLeadingZero(newDay),
        },
      ],
    }).then((e) => {
      runInAction(() => {
        if (!e.data.length) return;
        this.routes[newDay].push(e.data[0]);
        this.filterRoutes();
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'copyRoute').catch(() => void 0)).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_copyRoute');
      });
    });
  };

  @action addTask(routeId: number, taskText: string): Promise<any> {
    if (routeId === undefined || !taskText.trim().length) return this.errorHandler(badRequestError, 'addTask');
    if (mainStore.activeRequestsSet.has('routes_addTask')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_addTask');
    return RoutesRequests.addTask({
      route_id: routeId,
      text: taskText.trim().substr(0, 255),
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'addTask')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_addTask');
      });
    });
  };

  @action editTask(taskId: number, taskText: string): Promise<any> {
    if (taskId === undefined || !taskText.trim().length) return this.errorHandler(badRequestError, 'editTask');
    if (mainStore.activeRequestsSet.has('routes_editTask')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_editTask');
    return RoutesRequests.editTask({
      task_id: taskId,
      text: taskText.trim().substr(0, 255),
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'editTask')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_editTask');
      });
    });
  };

  @action toggleTask(taskId: number): Promise<any> {
    if (taskId === undefined) return this.errorHandler(badRequestError, 'taggleTask');
    if (mainStore.activeRequestsSet.has('routes_taggleTask')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_taggleTask');
    return RoutesRequests.toggleTask({
      task_id: taskId,
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'taggleTask')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_taggleTask');
      });
    });
  };

  @action removeTask(taskId: number): Promise<any> {
    if (taskId === undefined) return this.errorHandler(badRequestError, 'removeTask');
    if (mainStore.activeRequestsSet.has('routes_removeTask')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('routes_removeTask');
    return RoutesRequests.removeTask({
      task_id: taskId,
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'removeTask')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('routes_removeTask');
      });
    });
  };

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage: string = '';
    let errorButton: AlertButton | undefined = undefined;
    switch (error.status) {
      case 404:
        if (context === 'removeTask') errorMessage = 'Задача не найдена';
        break;
      case 422:
        if (context === 'copyRoute') {
          if (error.data.message?.indexOf('уже существует')) errorMessage = 'Дубль маршрута';
        }
        break;
    }
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
