import { action, runInAction, observable, computed } from 'mobx';
import { AlertButton, AlertType, mainStore } from './MainStore';
import { ErrorResponse, badRequestError, responseOverlappingError } from '../api/Requests';
import {
  CheckoutRequests,
  ConfirmOrderRequest,
  CheckoutGood,
  CheckoutPromotionPack,
  CheckoutMeta,
  DeliveryType,
  PaymentType,
  DeliverySchedule,
  PickupPoint,
  CdekSubtype,
  CheckoutRequest,
  ShippingDocumentsType,
  OrderPaymentRequest
} from '../api/Checkout';
import { persist } from 'mobx-persist';
import { ClientAddress } from '../api/Cabinet';
import { formatISO, addDays } from 'date-fns';

export default class CheckoutStore {
  @persist('object') @observable checkoutMeta: CheckoutMeta | null = null;
  @persist('object') @observable deliveryAddress: ClientAddress | null = null;
  @persist('object') @observable deliveryDate: DeliverySchedule | null = null;
  @persist('object') @observable deliveryPoint: PickupPoint | null = null;
  @persist('object') @observable cdekSubtype: CdekSubtype = CdekSubtype.toStore;
  @persist @observable clientName = '';
  @persist @observable conditionsAgree = false;
  @persist @observable deliveryType: DeliveryType | null = null;
  @persist @observable deliveryCost = 0;
  @persist @observable defPayment = false;
  @persist @observable paymentMethod: PaymentType = 'alfabank';
  @persist @observable shippingDocumentsMethod: ShippingDocumentsType = 'UPD'
  @observable deliveryScheduleList: DeliverySchedule[] = [];
  
  @computed get formatedAddress() {
    if (!this.deliveryAddress) return '';

    const address = this.deliveryAddress?.address.split(',');
    const regionName = this.deliveryAddress?.region?.name;

    if (address[0].toUpperCase() === regionName) return address.splice(1).join();
    return address.join();
  }

  @computed get checkedDeliveryDate() {
    if (this.deliveryType === 'courier_delivery' || this.deliveryType === 'cdek' || this.deliveryType === 'cdek_to_door') return this.deliveryDate;

    return null;
  }

  @computed get isCheckoutValid(): boolean {
    if (!this.conditionsAgree || !this.clientName.trim() || !this.deliveryType) return false;
    if (mainStore.userStore.isClient) {
      //if (!this.deliveryAddress.country.trim() || !this.deliveryAddress.postcode.trim() || !this.deliveryAddress.city.trim() || !this.deliveryAddress.address.trim()) return false;
    } else {
      if (!this.deliveryAddress) return false;
    }
    return true;
  }

  @action setInit() {

  }

  @action clearCache() {
    this.checkoutMeta = null;
    this.deliveryAddress = null;
    this.deliveryDate = null;
    this.deliveryScheduleList = [];
    this.clientName = '';
    this.conditionsAgree = false;
    this.deliveryType = null;
    this.deliveryCost = 0;
    this.cdekSubtype = CdekSubtype.toStore;
    this.paymentMethod = 'alfabank';
    this.shippingDocumentsMethod = 'UPD'
    this.deliveryPoint = null;
    this.defPayment = false;
  }

  @action confirmOrder() {
    if (!mainStore.catalogStore.clientId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('checkout_confirmOrder')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('checkout_confirmOrder');
    const request: ConfirmOrderRequest = {
      basket: [],
      promotions: [],
      customer_id: parseInt(mainStore.catalogStore.clientId, 10),
      manager_id: mainStore.cabinetStore.manager?.id,
      doc_type: mainStore.catalogStore.docType ? 'bsf' : ''
    };
    request.basket = mainStore.catalogStore.basket.map<CheckoutGood>((item) => {
      return {
        good_id: item.good_id,
        ref: item.ref || '',
        count: item.count,
        price: Number(item.price || '0'),
        status: 'not_confirmed',
      };
    });
    const promotionBasket = Object.values(mainStore.promotionsStore.basket).filter((promotion) => promotion.isValid);
    request.promotions = promotionBasket.map((promotion) => {
      const gift_packs: CheckoutPromotionPack[] = [];
      const promotion_packs: CheckoutPromotionPack[] = [];
      const packs = Object.values(promotion.packs).filter((pack) => !!pack.items.length);
      packs.forEach((pack) => {
        const [type, id] = pack.id.split('_');
        if (type === 'goods') {
          promotion_packs.push({
            id: parseInt(id, 10),
            ref: pack.ref,
            products: pack.items.map<CheckoutGood>((item) => {
              return {
                good_id: item.good_id,
                ref: item.ref || '',
                count: item.count,
                price: Number(item.price || '0'),
                status: 'not_confirmed',
              };
            }),
          });
        } else {
          gift_packs.push({
            id: parseInt(id, 10),
            ref: pack.ref,
            products: pack.items.map<CheckoutGood>((item) => {
              return {
                good_id: item.good_id,
                ref: item.ref || '',
                count: item.count,
                price: 0,
                status: 'not_confirmed',
              };
            }),
          });
        }
      });
      return {
        id: promotion.id,
        ref: promotion.ref,
        type_present: promotion.typePresent,
        multiplicity: promotion.multiplicity,
        gift_packs: gift_packs,
        promotion_packs: promotion_packs,
      };
    });
    this.checkoutMeta = null;
    return CheckoutRequests.confirmOrder(request).then((e) => {
      runInAction(() => {
        this.checkoutMeta = e.meta || null;
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'confirmOrder')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('checkout_confirmOrder');
      });
    });
  }

  @action getDeliveryInfo(type: DeliveryType, delivery_subtype?: CdekSubtype): Promise<any> {
    if (!this.checkoutMeta || !this.deliveryAddress) return Promise.reject(badRequestError);
    return CheckoutRequests.getDeliveryInfo({
      address_ref: this.deliveryAddress.ref,
      order_ref: this.checkoutMeta.order_ref,
      delivery_type: type,
      delivery_subtype: delivery_subtype,
      doc_type: mainStore.catalogStore.docType ? 'bsf' : undefined
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getDeliveryInfo'));
  }

  @action checkout(checkoutData?: CheckoutRequest): Promise<any> {
    if (checkoutData) {
      if (mainStore.activeRequestsSet.has('checkout_checkout')) return Promise.reject(responseOverlappingError);
      mainStore.activeRequestsSet.add('checkout_checkout');
      
      return CheckoutRequests.checkout(checkoutData).finally(() => {
        runInAction(() => {
          mainStore.activeRequestsSet.delete('checkout_checkout');
        });
      });
    }

    if (!mainStore.catalogStore.clientId || !this.checkoutMeta || !this.deliveryAddress || !this.deliveryType) {
      return Promise.reject(badRequestError);
    }
    if (mainStore.activeRequestsSet.has('checkout_checkout')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('checkout_checkout');
  
    const deliveryDate = this.checkedDeliveryDate?.date
      ? new Date(this.checkedDeliveryDate.date)
      : addDays(new Date(), 3);
    
    let deliveryType = this.deliveryType;

    if (this.deliveryType === 'cdek_to_door') deliveryType = 'cdek';
    
    return CheckoutRequests.checkout({
      address_ref: this.deliveryAddress.ref,
      order_ref: this.checkoutMeta.order_ref,
      def_payment: this.defPayment,
      delivery: {
        type: deliveryType,
        subtype: this.cdekSubtype,
        delivery_point: this.deliveryPoint?.code || '',
        date: formatISO(deliveryDate, { representation: 'date' }),
      },
      to: this.clientName,
      payment: {
        type: this.paymentMethod,
      },
      doc_type: this.shippingDocumentsMethod
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'checkout')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('checkout_checkout');
      });
    });
  }

  @action payment(paymentData: OrderPaymentRequest): Promise<any> {
    return CheckoutRequests.payment({
      order_ref: paymentData.order_ref,
      payment: {
        type: this.paymentMethod,
      },
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'checkout')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('checkout_checkout');
      });
    });
  }

  @action checkStatus(paymentId: string): Promise<any> {
    if (!paymentId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('checkout_checkStatus')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('checkout_checkStatus');
    return CheckoutRequests.checkStatus(paymentId).catch((error: ErrorResponse) => this.errorHandler(error, 'checkStatus')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('checkout_checkStatus');
      });
    });
  }

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage: string = '';
    let errorButton: AlertButton | undefined = undefined;
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
