import React from 'react';
import { mainStore } from '../stores/MainStore';
import { observer } from 'mobx-react';

export default observer(() => {
  const handleAttentionClear = () => mainStore.attentionMessage = '';

  return (
    <div className="attention c-bg-red c-white">
      <div className="h6 mb-0">{mainStore.attentionMessage}</div>
      <div className="attention__close btn-close icon icon-close-round" onClick={handleAttentionClear} />
    </div>
  );
});
