import { action, computed, observable } from "mobx"
import { persist } from "mobx-persist";

export type MapConfig = {
  center: Array<number>,
  zoom: number,
}

export type MarkerConfig = {
  uid: string,
  latitude: number,
  longitude: number,
}

export default class MapStore {
  @observable zoom: number = 10;

  @persist('object') @observable markerPoints: MarkerConfig[] = [];

  @observable activePoint = '';
  
  @computed get center() {
    if (!this.markerPoints.length) return [];

    return [this.markerPoints[0].latitude, this.markerPoints[0].longitude];
  }

  @action clearCache() {
    this.zoom = 10;
    this.activePoint = '';
    this.markerPoints = [];
  }

  @action setInit() {

  }
}