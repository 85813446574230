import { IonApp, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Alerts from './components/Alerts';
import Auth from './pages/Auth';
import Logout from './pages/Logout';
import PageLayout from './pages/PageLayout';
import { mainStore } from './stores/MainStore';
import './theme/common.scss';

const App: React.FC = () => {
  if (!mainStore.storesInitComplete) {
    return (
      <IonApp>
        <IonLoading cssClass="ion-loading" isOpen={true} spinner="crescent"/>
      </IonApp>
    );
  }

  return (
    <IonApp className={!mainStore.isCapacitor ? 'ion-app-web' : ''}>
      <IonReactRouter>
        <Switch>
          <Route path="/auth" component={Auth} exact/>
          <Route path="/logout" component={Logout} exact/>
          <Route component={PageLayout}/>
        </Switch>
      </IonReactRouter>
      <Alerts/>
      {mainStore.isShowLoader && (
        <IonLoading cssClass="ion-loading" isOpen={mainStore.isShowLoader} spinner="crescent"/>
      )}
      <div className="debug-screen-width"/>
    </IonApp>
  );
};

export default observer(App);
