import { observer } from 'mobx-react';
import React from 'react';

const NotFound = () => {
  return (
    <div className="board">
      <div className="board__title h6">Страница не найдена</div>
      <div className="text-center py-5">
        <div className="h1 pb-2">4 0 4</div>
        <div className="h5">Страница не найдена</div>
      </div>
    </div>
  );
};

export default observer(NotFound);
