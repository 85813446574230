import { action, computed, observable, runInAction, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import {
  CatalogRequests,
  CatalogTreeRequest,
  Category,
  Filter,
  FilterLineItem,
  Good,
  GoodOffer,
  GoodResponse,
  GoodsRequest,
  PropertiesRequest,
  Property,
  SearchAutocompleteRequest,
} from '../api/Catalog';
import { badRequestError, ErrorResponse, responseOverlappingError, sequentialRequests } from '../api/Requests';
import {
  CATALOG_ITEMS_PER_PAGE,
  CATALOG_RECENT_ITEMS_COUNT,
  CATALOG_SEARCH_AUTOCOMPLETE_ITEMS_PER_PAGE,
} from '../config';
import { UI } from '../UI';
import { AlertButton, AlertType, mainStore, SortingOrder } from './MainStore';
import { CabinetRequests, ClientAddress } from '../api/Cabinet';
import { CdekSubtype, ConfirmOrderResponse } from '../api/Checkout';

export interface BasketItem extends Good {
  offerId: number | null,
  count: number,
  oldCount?: number,
  actualCount?: number,
}

export type Breadcrumb = {
  id: number,
  name: string,
};

export type ActiveFilters = {
  count: number,
  brandList: { id: number, name: string }[],
  brandLineList: { id: number, name: string }[],
  propList: { id: number, name: string }[],
  brandIds: number[],
  brandLineIds: number[],
  propIds: number[],
}

type BasketTotal = {
  [key: number]: [number, string],
  totalCount: number,
  totalSum: string,
  totalSumDiscount: string,
}

type GetParams = {
  b?: string,
  bl?: string,
  c?: string,
  f?: string,
  p?: string,
  q?: string,
  s?: string,
}


export default class CatalogStore {
  @persist('list') @observable basket: BasketItem[] = [];
  @persist('list') @observable searchPicks: GoodOffer[] = [];
  @persist('list') @observable clientAddressList: ClientAddress[] = [];
  @persist('object') @observable filterPartExpanded: { [key: string]: boolean } = {
    brand: false,
    category: false,
    properties: false,
  };
  @persist('object') @observable recentList: { [key: string]: Good[] } = {};
  @persist @observable clientId: string | null = null;
  @persist @observable regionId: string = '';
  @persist @observable docType: boolean = false;
  @persist @observable catalogViewType: 'list' | 'block' = 'list';
  @observable filters: Filter[] = [];
  @observable categories: Category[] = [];
  @observable properties: Property[] = [];
  @observable offers: GoodOffer[] = [];
  @observable activeFilters: ActiveFilters = {
    count: 0,
    brandList: [],
    brandLineList: [],
    propList: [],
    brandIds: [],
    brandLineIds: [],
    propIds: [],
  };
  @observable sortingOrder: SortingOrder = SortingOrder.Asc;
  @observable breadcrumbs: Breadcrumb[] = [];
  @observable curPage = 1;
  @observable totalPages = 0;
  @observable searchField = '';
  @observable expandedOfferId: [number | null, number | null] = [null, null];
  scrollPosition = 0;
  pathnames: string[] = [];
  prevGetParam: GetParams = {};
  catalogPath: string[] = [
    '/catalog',
    '/catalog/:clientId',
    '/offer/:clientId/:offerId',
    '/good/:clientId/:goodId',
  ];

  @computed get getTotals(): BasketTotal {
    const totals: BasketTotal = {
      totalCount: 0,
      totalSum: '0',
      totalSumDiscount: '0',
    };
    for (let i = 0; i < this.basket.length; i++) {
      if (!this.basket[i].count) continue;
      totals.totalCount = totals.totalCount + this.basket[i].count;
      let itemSum = this.basket[i].price || '0';
      if (this.basket[i].count > 1) {
        itemSum = mainStore.convertKopToRub(
          mainStore.convertRubToKop(this.basket[i].price || '0') * this.basket[i].count);
      }
      let itemSumDiscount = itemSum;
      if (this.basket[i].discount) {
        itemSumDiscount = mainStore.convertKopToRub(
          mainStore.convertRubToKop(this.basket[i].price_with_discount || '0') * this.basket[i].count);
      }
      totals.totalSum = mainStore.additionFloat(totals.totalSum, itemSum);
      totals.totalSumDiscount = mainStore.additionFloat(totals.totalSumDiscount, itemSumDiscount);
      totals[`g${this.basket[i].good_id}` as unknown as number] = [0, itemSumDiscount.toString()];
      if (this.basket[i].offerId) {
        if (totals[this.basket[i].offerId!] === undefined) totals[this.basket[i].offerId!] = [0, '0'];
        totals[this.basket[i].offerId!][0] = totals[this.basket[i].offerId!][0] + this.basket[i].count;
        totals[this.basket[i].offerId!][1] = mainStore.additionFloat(totals[this.basket[i].offerId!][1], itemSum);
      }
    }
    return totals;
  }

  getActiveCategories(activeCategoryId: number, categories: Category[], level: number): Breadcrumb[] {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].id === activeCategoryId) {
        return [
          {
            id: categories[i].id,
            name: mainStore.firstLetterUp(categories[i].name),
          },
        ];
      } else {
        if (categories[i].children.length) {
          const breadcrumb: Breadcrumb[] = this.getActiveCategories(
            activeCategoryId, categories[i].children, level + 1);
          if (breadcrumb.length) {
            breadcrumb.unshift({
              id: categories[i].id,
              name: mainStore.firstLetterUp(categories[i].name),
            });
            return breadcrumb;
          }
        }
      }
    }
    return [];
  }

  getOfferById(offerId: number): { offer: GoodOffer | null, isExist: boolean } {
    for (let i = 0; i < this.offers.length; i++) {
      if (this.offers[i].offer_id === offerId) return { offer: this.offers[i], isExist: true };
    }
    for (let i = 0; i < this.searchPicks.length; i++) {
      if (this.searchPicks[i].offer_id === offerId) return { offer: this.searchPicks[i], isExist: false };
    }
    return { offer: null, isExist: false };
  }

  getCheckedBrandLines(lines: FilterLineItem[]): number {
    let checkedCount: number = 0;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].checked) checkedCount++;
    }
    return checkedCount;
  }

  getBasketItem(goodId: number): BasketItem | null {
    for (let i = 0; i < this.basket.length; i++) {
      if (this.basket[i].good_id === goodId) return this.basket[i];
    }
    return null;
  }

  updateBasketByResponse(confirmResponse: ConfirmOrderResponse) {
    let isValid = true;

    confirmResponse.basket.forEach(async (el) => {
      const good = this.basket.find((g) => g.good_id === el.good_id);
      console.log('checkoutValid', this.basket[0], 'el:', el)
      
      if(!good) return;

      if (el.status === 'not_confirmed' || el.count > (good.qty || 0)) {
        isValid = false;
        
        good.oldCount = el.count;
        good.count = el.valid_max_count;
        good.price = el.valid_price.toString();
        
        if (good.price === el.price.toString()) {
          good.qty = el.valid_max_count;
        }
      }
    })

    return isValid;
  }

  @action setInit() {};

  @action clearCache() {
    this.clientId = null;
    this.categories = [];
    this.sortingOrder = SortingOrder.Asc;
    this.filters = [];
    this.properties = [];
    this.offers = [];
    this.breadcrumbs = [];
    this.searchField = '';
    this.curPage = 1;
    this.totalPages = 0;
    this.scrollPosition = 0;
    this.pathnames = [];
    this.prevGetParam = {};
    this.activeFilters = {
      count: 0,
      brandList: [],
      brandLineList: [],
      propList: [],
      brandIds: [],
      brandLineIds: [],
      propIds: [],
    };
    this.searchPicks = [];
    this.expandedOfferId = [null, null];
    this.clientAddressList = [];
  }

  @action setClientId(clientId: string | null) {
    this.scrollPosition = 0;
    this.pathnames = [];
    this.filters = [];
    this.properties = [];
    this.categories = [];
    this.offers = [];
    this.prevGetParam = {};
    this.activeFilters = {
      count: 0,
      brandList: [],
      brandLineList: [],
      propList: [],
      brandIds: [],
      brandLineIds: [],
      propIds: [],
    };
    this.breadcrumbs = [];
    this.searchField = '';
    this.curPage = 1;
    this.totalPages = 0;
    this.expandedOfferId = [null, null];
    this.searchPicks = [];
    if (clientId && clientId === this.clientId) {
      if (this.basket.length && !this.recentList[clientId]?.length) {
        this.recentList[clientId] = toJS(this.basket).slice(0, CATALOG_RECENT_ITEMS_COUNT);
      }
      if (!this.regionId && clientId) this.getAddressListByClientId(parseInt(clientId, 10));
      return;
    }
    this.clientId = clientId;
    this.clientAddressList = [];
    this.regionId = '';
    if (clientId) this.getAddressListByClientId(parseInt(clientId, 10));
    if (mainStore.userStore.isClient) return;
    this.basket = [];
    mainStore.checkoutStore.clientName = '';
    mainStore.checkoutStore.deliveryAddress = null;
    mainStore.checkoutStore.deliveryType = null;
    mainStore.checkoutStore.deliveryCost = 0;
    mainStore.checkoutStore.cdekSubtype = CdekSubtype.toStore;
    mainStore.checkoutStore.paymentMethod = 'alfabank';
    mainStore.checkoutStore.deliveryPoint = null;
    mainStore.checkoutStore.checkoutMeta = null;
    mainStore.checkoutStore.deliveryScheduleList = [];
    mainStore.checkoutStore.deliveryDate = null;
    mainStore.checkoutStore.defPayment = false;
    mainStore.mapStore.clearCache();
  }

  @action removeBasketItem(goodId: number) {
    this.basket = this.basket.filter(item => item.good_id !== goodId);
  }

  @action optimizeBasket() {
    if (!this.basket.length) return;
    this.basket = this.basket.filter(item => item.count);
  }

  @action
  async getGoodById(goodId: number): Promise<{ good: Good | null, isExist: boolean }> {
    for (let i = 0; i < this.offers.length; i++) {
      for (let j = 0; j < this.offers[i].goods.length; j++) {
        if (this.offers[i].goods[j].good_id === goodId) {
          if (this.offers[i].goods[j].offer_id === undefined) this.offers[i].goods[j].offer_id = this.offers[i].offer_id;
          if (!this.offers[i].goods[j].offer_name) this.offers[i].goods[j].offer_name = this.offers[i].name;
          return Promise.resolve({ good: this.offers[i].goods[j], isExist: true });
        }
      }
    }
    for (let i = 0; i < this.searchPicks.length; i++) {
      for (let j = 0; j < this.searchPicks[i].goods.length; j++) {
        if (this.searchPicks[i].goods[j].good_id === goodId) {
          if (this.searchPicks[i].goods[j].offer_id === undefined) this.searchPicks[i].goods[j].offer_id = this.searchPicks[i].offer_id;
          if (!this.searchPicks[i].goods[j].offer_name) this.searchPicks[i].goods[j].offer_name = this.searchPicks[i].name;
          return Promise.resolve({ good: this.searchPicks[i].goods[j], isExist: false });
        }
      }
    }
    let offer: GoodResponse | null = null;
    try {
      offer = await this.getGood(goodId);
    } catch (e) {}
    if (offer) {
      if (this.searchPicks.length > 10) this.searchPicks = [];
      this.searchPicks.push(offer.data.good);
      for (let j = 0; j < offer.data.good.goods.length; j++) {
        if (offer.data.good.goods[j].good_id === goodId) {
          if (offer.data.good.goods[j].offer_id === undefined) offer.data.good.goods[j].offer_id = offer.data.good.offer_id;
          if (!offer.data.good.goods[j].offer_name) offer.data.good.goods[j].offer_name = offer.data.good.name;
          return Promise.resolve({ good: offer.data.good.goods[j], isExist: false });
        }
      }
    }
    return Promise.resolve({ good: null, isExist: false });
  }

  @action increaseGoodNotifyCount(goodId: number) {
    this.getGoodById(goodId).then((e) => {
      if (!e.good) return;
      e.good.notification_count = (e.good.notification_count || 0) + 1;
    });
  }

  @action getBreadcrumbs(activeCategoryId: number | null) {
    this.breadcrumbs = [];
    if (activeCategoryId === null || !this.categories.length) return;
    this.breadcrumbs = this.getActiveCategories(activeCategoryId, this.categories, 0);
  }

  @action setProperties(activePropIds: number[]) {
    this.activeFilters.count = this.activeFilters.brandIds.length + this.activeFilters.brandLineIds.length;
    this.activeFilters.propList = [];
    this.activeFilters.propIds = [];
    if (!this.properties.length) return;
    for (let i = 0; i < this.properties.length; i++) {
      if (!this.properties[i].items.length) continue;
      for (let j = 0; j < this.properties[i].items.length; j++) {
        if (activePropIds.length && activePropIds.indexOf(this.properties[i].items[j].item_id) !== -1) {
          this.activeFilters.propList.push({
            id: this.properties[i].items[j].item_id,
            name: `${this.properties[i].name || 'Без названия'}: ${this.properties[i].items[j].name || 'Без названия'}`,
          });
          this.activeFilters.propIds.push(this.properties[i].items[j].item_id);
          this.properties[i].items[j].checked = true;
        } else this.properties[i].items[j].checked = false;
      }
    }
    this.activeFilters.count += this.activeFilters.propIds.length;
  }

  @action parseGetParams(params: string) {
    const sp: URLSearchParams = new URLSearchParams(params);
    const changedList: { [key: string]: boolean } = {
      b: false,
      bl: false,
      c: false,
      f: false,
      p: false,
      q: false,
      s: false,
    };
    const b: string = sp.get('b') || '';
    const bl: string = sp.get('bl') || '';
    const c: string = sp.get('c') || '';
    const f: string = sp.get('f') || '';
    const p: string = sp.get('p') || '';
    const s: string = sp.get('s') || '';
    let q: string = sp.get('q') || '';
    if (b || bl || c || f) q = '';
    if (!c) {
      this.properties = [];
      this.setProperties([]);
    }
    let activeBrandIds: number[] = mainStore.splitIntoNumbers(b, ',');
    let activeBrandLineIds: number[] = mainStore.splitIntoNumbers(bl, ',');
    let activeCategory: number | null = null;
    let activePropIds: number[] = [];
    if (this.prevGetParam.b !== b) {
      this.prevGetParam.b = b;
      changedList.b = true;
    }
    if (this.prevGetParam.bl !== bl) {
      this.prevGetParam.bl = bl;
      changedList.bl = true;
    }
    if (changedList.b || changedList.bl) {
      this.activeFilters = {
        count: 0,
        brandList: [],
        brandLineList: [],
        propList: [],
        brandIds: [],
        brandLineIds: [],
        propIds: [],
      };
      if (this.filters.length) {
        for (let i = 0; i < this.filters[0].data.length; i++) {
          if (activeBrandIds.length && activeBrandIds.indexOf(this.filters[0].data[i].id) !== -1) {
            this.activeFilters.brandList.push({
              id: this.filters[0].data[i].id,
              name: `Бренд: ${this.filters[0].data[i].name || 'Без названия'}`,
            });
            this.activeFilters.brandIds.push(this.filters[0].data[i].id);
            this.filters[0].data[i].checked = true;
            for (let j = 0; j < this.filters[0].data[i].lines.length; j++) {
              this.filters[0].data[i].lines[j].checked = true;
            }
          } else {
            this.filters[0].data[i].checked = false;
            for (let j = 0; j < this.filters[0].data[i].lines.length; j++) {
              if (activeBrandLineIds.length && activeBrandLineIds.indexOf(this.filters[0].data[i].lines[j].id) !== -1) {
                this.activeFilters.brandLineList.push({
                  id: this.filters[0].data[i].lines[j].id,
                  name: `Бренд: ${this.filters[0].data[i].name || 'Без названия'} > ${this.filters[0].data[i].lines[j].name || 'Без названия'}`,
                });
                this.activeFilters.brandLineIds.push(this.filters[0].data[i].lines[j].id);
                this.filters[0].data[i].lines[j].checked = true;
              } else this.filters[0].data[i].lines[j].checked = false;
            }
          }
        }
        this.activeFilters.count = this.activeFilters.brandIds.length + this.activeFilters.brandLineIds.length;
      }
    }
    if (this.prevGetParam.c !== c) {
      this.prevGetParam.c = c;
      changedList.c = true;
      activeCategory = parseInt(c, 10) || null;
      this.breadcrumbs = [];
    }
    if (this.prevGetParam.f !== f) {
      this.prevGetParam.f = f;
      changedList.f = true;
      if (c) activePropIds = mainStore.splitIntoNumbers(f, ',');
    }
    if (this.prevGetParam.p !== p) {
      this.prevGetParam.p = p;
      changedList.p = true;
      this.curPage = parseInt(p, 10) || 1;
    }
    if (this.prevGetParam.q !== q) {
      this.prevGetParam.q = q;
      changedList.q = true;
      this.searchField = q;
    }
    if (this.prevGetParam.s !== s) {
      this.prevGetParam.s = s;
      changedList.s = true;
      this.sortingOrder = parseInt(s, 10) === 2 ? 2 : 1;
    }
    if (Object.values(changedList).indexOf(true) !== -1) {
      this.expandedOfferId = [null, null];
    }
    if (changedList.b || changedList.bl) {
      sequentialRequests([
        () => mainStore.catalogStore.getCatalogTree().then(() => {
          this.getBreadcrumbs(activeCategory);
          return Promise.resolve();
        }),
        () => c ? mainStore.catalogStore.getProperties().then(() => {
          this.setProperties(activePropIds);
          return Promise.resolve();
        }) : Promise.resolve(),
        () => mainStore.catalogStore.getGoods(),
      ]).catch(() => void 0);
      return;
    }
    if (changedList.c) {
      if (!this.categories.length) return;
      this.getBreadcrumbs(activeCategory);
      sequentialRequests([
        () => c ? mainStore.catalogStore.getProperties().then(() => {
          this.setProperties(activePropIds);
          return Promise.resolve();
        }) : Promise.resolve(),
        () => mainStore.catalogStore.getGoods(),
      ]).catch(() => void 0);
      return;
    }
    if (changedList.f) {
      if (!this.properties.length) return;
      this.setProperties(activePropIds);
      mainStore.catalogStore.getGoods().catch(() => void 0);
      return;
    }
    if (changedList.p || changedList.q || changedList.s) {
      mainStore.catalogStore.getGoods().catch(() => void 0);
    }
  }

  @action addToRecentList(good: Good) {
    if (!this.clientId) return;
    if (!this.recentList[this.clientId]?.length) {
      this.recentList[this.clientId] = [good];
      return;
    }
    for (let i = 0; i < this.recentList[this.clientId].length; i++) {
      if (this.recentList[this.clientId][i].good_id === good.good_id) return;
    }
    this.recentList[this.clientId].unshift(good);
    this.recentList[this.clientId] = this.recentList[this.clientId].slice(0, CATALOG_RECENT_ITEMS_COUNT);
  }

  @action getCatalogTree(): Promise<any> {
    if (!this.clientId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('catalog_getCatalogTree')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('catalog_getCatalogTree');
    const requestData: CatalogTreeRequest = {
      customer_id: this.clientId,
      filters: {},
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    if (this.activeFilters.brandIds.length) {
      requestData.filters.brand_id = this.activeFilters.brandIds;
    }
    if (this.activeFilters.brandLineIds.length) {
      requestData.filters.brand_line_id = this.activeFilters.brandLineIds;
    }
    return CatalogRequests.getCatalogTree(requestData).then(e => {
      runInAction(() => {
        this.categories = e.data.catalogs || [];
        UI.accordion.reset('.filter__part._category .accordion__body');
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getCatalogTree')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('catalog_getCatalogTree');
      });
    });
  };

  @action getFilters(getPrams: string): Promise<any> {
    if (!this.clientId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('catalog_getFilters')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('catalog_getFilters');
    return CatalogRequests.getFilters({
      customer_id: this.clientId,
      manager_id: mainStore.cabinetStore.manager?.id,
    }).then(e => {
      runInAction(() => {
        this.filters = e.data.filter || [];
        UI.accordion.reset('.filter__part._brand .accordion__body');
        this.parseGetParams(getPrams);
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getFilters')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('catalog_getFilters');
      });
    });
  };

  @action getProperties(): Promise<any> {
    if (!this.clientId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('catalog_getProperties')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('catalog_getProperties');
    const requestData: PropertiesRequest = {
      customer_id: this.clientId,
      filters: {},
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    } else {
      requestData.customer_id = mainStore.cabinetStore.cabinet!.id;
    }
    if (this.activeFilters.brandIds.length) {
      requestData.filters.brand_id = this.activeFilters.brandIds;
    }
    if (this.activeFilters.brandLineIds.length) {
      requestData.filters.brand_line_id = this.activeFilters.brandLineIds;
    }
    if (this.breadcrumbs.length) {
      requestData.catalog_id = this.breadcrumbs.slice(-1)[0].id;
    }
    return CatalogRequests.getProperties(requestData).then(e => {
      runInAction(() => {
        this.properties = e.data.property || [];
        UI.accordion.reset('.filter__part._properties .accordion__body');
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getProperties')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('catalog_getProperties');
      });
    });
  };

  @action getGoods(): Promise<any> {
    if (!this.clientId || !this.regionId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('catalog_getGoods')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('catalog_getGoods');
    const requestData: GoodsRequest = {
      customer_id: this.clientId,
      filters: {},
      properties: this.activeFilters.propIds,
      q: this.searchField,
      page: this.curPage,
      perPage: CATALOG_ITEMS_PER_PAGE,
      sort: {
        name: 'name',
        type: this.sortingOrder === SortingOrder.Desc ? 'desc' : 'asc',
      },
      region_id: this.regionId,
      doc_type: this.docType ? 'bsf' : ''
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    if (this.activeFilters.brandIds.length) {
      requestData.filters.brand_id = this.activeFilters.brandIds;
    }
    if (this.activeFilters.brandLineIds.length) {
      requestData.filters.brand_line_id = this.activeFilters.brandLineIds;
    }
    if (this.breadcrumbs.length) {
      requestData.catalog_id = this.breadcrumbs.slice(-1)[0].id;
    }
    return CatalogRequests.getGoods(requestData).then(e => {
      runInAction(() => {
        this.offers = e.data.goods || [];
        this.totalPages = e.data.pages || 0;
        this.curPage = e.data.page || 1;
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getGoods')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('catalog_getGoods');
      });
    });
  };

  @action getGood(goodId: number): Promise<any> {
    if (goodId === undefined || !this.clientId) return Promise.reject(badRequestError);
    // if (mainStore.activeRequestsSet.has('catalog_getGood')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('catalog_getGood');
    const requestData: { doc_type?: string, customer_id: number | string, manager_id?: number } = {
      customer_id: this.clientId,
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    if (mainStore.catalogStore.docType) {
      requestData.doc_type = 'bsf'
    }
    return CatalogRequests.getGood(goodId, requestData).catch(
      (error: ErrorResponse) => this.errorHandler(error, 'getGood')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('catalog_getGood');
      });
    });
  };

  @action getSearchAutocomplete(q: string): Promise<any> {
    if (!this.clientId) return Promise.reject(badRequestError);
    const requestData: SearchAutocompleteRequest = {
      q: q,
      perPage: CATALOG_SEARCH_AUTOCOMPLETE_ITEMS_PER_PAGE,
      page: 1,
      customer_id: this.clientId,
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    return CatalogRequests.getSearchAutocomplete(requestData).catch((error: ErrorResponse) => {
      this.errorHandler(error, 'getSearchAutocomplete').catch(() => void 0);
    });
  };

  @action sendNotifyArrival(goodId: number): Promise<any> {
    if (goodId === undefined || !this.clientId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('catalog_notifyArrival')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('catalog_notifyArrival');
    const requestData: { customer_id: number | string, manager_id?: number } = {
      customer_id: this.clientId,
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    return CatalogRequests.notifyArrival(goodId, requestData).then(() => {
      runInAction(() => {
        this.increaseGoodNotifyCount(goodId);
      });
      return Promise.resolve();
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'notifyArrival')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('catalog_notifyArrival');
      });
    });
  };

  @action getAddressListByClientId(clientId: number) {
    if (!clientId) return;
    CabinetRequests.getAddressListByClientId({ customer_id: clientId }).then((e) => {
      runInAction(() => {
        this.clientAddressList = (e.data.addresses.data || []).filter((address) => address.active);
        if (!this.clientAddressList.length) {
          mainStore.alerts.push({
            message: 'У клиента отсутсвуют адреса',
            cssClass: AlertType.Error,
          });
          return;
        }
        // if (this.clientAddressList.length === 1) {
        //   mainStore.checkoutStore.deliveryAddress = toJS(this.clientAddressList[0]);
        //   this.regionId = this.clientAddressList[0].region_id;
        // } else 
        mainStore.isShowChooseAddressPopup = true;
      });
    }).catch((error) => {
      this.errorHandler(error, 'getAddressListByClientId').catch(() => void 0);
    });
  };

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage: string = '';
    let errorButton: AlertButton | undefined = undefined;
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
