import { IonRouterLink } from '@ionic/react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { mainStore } from '../stores/MainStore';

const Header: React.FC = () => {
    const catalogMatch = useRouteMatch({
        path: mainStore.catalogStore.catalogPath,
        exact: true
    })
    const promotionMatch = useRouteMatch({
        path: mainStore.promotionsStore.promotionPath,
        exact: true
    })

    return (
        <header className="header">
            <div className="header__top">
                <div className="header__menu-trigger js-open-menu">
                    <div/>
                </div>
                <IonRouterLink routerLink="/">
                    <img className="header__logo" src="/assets/img/logo_beegudi_white.svg" alt=""/>
                </IonRouterLink>
            </div>
            <div className={`header__bottom${catalogMatch !== null || promotionMatch !== null ? ' _catalog' : ''}`}>
                <div className="header__trigger-logo">
                    <div className="header__menu-trigger js-open-menu">
                        <div/>
                    </div>
                    <IonRouterLink routerLink="/">
                        <img className="header__logo" src="/assets/img/logo_beegudi_color.svg" alt=""/>
                    </IonRouterLink>
                </div>
            </div>
        </header>
    );
};

export default inject('mainStore')(observer(Header));
