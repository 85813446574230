import { action, observable, runInAction, computed } from 'mobx';
import { persist } from 'mobx-persist';
import {
  Category,
  Filter,
  FilterLineItem,
  GoodOffer,
  Property,
  CatalogRequests,
  Good,
  GoodResponse,
} from '../api/Catalog';
import {
  Promotion,
  PromotionBrandsRequest,
  PromotionGoodsRequest,
  PromotionPropertiesRequest,
  PromotionShort,
  PromotionsRequest,
  PromotionsRequests,
  PromotionTreeRequest, PromotionRequest,
} from '../api/Promotions';
import {
  badRequestError,
  ErrorResponse,
  responseOverlappingError,
  sequentialRequests,
  responseNetworkError,
} from '../api/Requests';
import { CATALOG_ITEMS_PER_PAGE, PROMOTION_ITEMS_PER_PAGE } from '../config';
import { UI } from '../UI';
import { ActiveFilters, BasketItem, Breadcrumb } from './CatalogStore';
import { AlertButton, AlertType, mainStore, SortingOrder } from './MainStore';

type GetParams = {
  pt?: string,
  pid?: string,
  b?: string,
  bl?: string,
  c?: string,
  f?: string,
  p?: string,
  q?: string,
  s?: string,
}

type ExpandedPackId = ['goods' | 'gifts', string] | null;

export type BasketPack = {
  items: BasketItem[],
  name: string,
  id: string,
  ref: string,
}

export type BasketPromotion = {
  packs: Record<string, BasketPack>,
  name: string,
  typePresent: 'gift' | 'discount',
  discount: number,
  id: number,
  multiplicity: number,
  isValid: boolean,
  ref: string,
}

type Basket = Record<number, BasketPromotion>;

type BasketTotal = {
  totalCount: number,
  totalSum: string,
  totalSumDiscount: string,
  totalSumDisplayed: string,
  discountAmount: string,
  offers: {
    [key: string]: [number, string],
  },
  promotions: {
    [key: string]: [number, string],
  },
  packs: {
    [key: string]: [number, string],
  }
}


export default class PromotionsStore {
  @persist('list') @observable promotionList: PromotionShort[] = [];
  @persist('list') @observable searchPicks: GoodOffer[] = [];
  @persist('object') @observable basket: Basket = {};
  @persist('object') @observable filterPartExpaned: { [key: string]: boolean } = {
    brand: false,
    category: false,
    properties: false,
  };
  @persist @observable clientId: string | null = null;
  @persist @observable catalogViewType: 'list' | 'block' = 'list';
  @observable promotion: Promotion | null = null;
  @observable filters: Filter[] = [];
  @observable categories: Category[] = [];
  @observable properties: Property[] = [];
  @observable offers: GoodOffer[] = [];
  @observable gifts: GoodOffer[] = [];
  @observable activeFilters: ActiveFilters = {
    count: 0,
    brandList: [],
    brandLineList: [],
    propList: [],
    brandIds: [],
    brandLineIds: [],
    propIds: [],
  };
  @observable sortingOrder: SortingOrder = SortingOrder.Asc;
  @observable breadcrumbs: Breadcrumb[] = [];
  @observable curPage = 1;
  @observable listCurPage = 1;
  @observable totalPages = 0;
  @observable listTotalPages = 0;
  @observable searchField = '';
  @observable expandedOfferId: [number | null, number | null] = [null, null];
  @observable expandedPackId: ExpandedPackId = null;
  promotionPath: string[] = [
    '/promotion/:clientId/:promotionId',
    '/promotion-offer/:clientId/:offerId',
    '/promotion-good/:clientId/:goodId',
  ];
  prevGetParam: GetParams = {};
  promotionId: number | null = null;

  @computed get getTotals(): BasketTotal {
    const totals: BasketTotal = {
      totalCount: 0,
      totalSum: '0',
      totalSumDiscount: '0',
      totalSumDisplayed: '0',
      discountAmount: '0',
      offers: {},
      promotions: {},
      packs: {},
    };
    totals.totalCount = mainStore.catalogStore.getTotals.totalCount;
    totals.totalSum = totals.totalSumDisplayed = mainStore.catalogStore.getTotals.totalSum;
    totals.totalSumDiscount = mainStore.catalogStore.getTotals.totalSumDiscount;
    totals.discountAmount = mainStore.subtractionFloat(totals.totalSum, totals.totalSumDiscount);
    if (!Object.keys(this.basket).length) return totals;
    Object.values(this.basket).forEach(promotion => {
      let promotionCount = 0;
      let promotionSum = '0';
      let promotionSumDiscount = '0';
      let promotionSumDisplayed = '0';
      Object.values(promotion.packs).forEach(pack => {
        let packCount = 0;
        let packSum = '0';
        let packSumDiscount = '0';
        let packSumDisplayed = '0';
        for (let i = 0; i < pack.items.length; i++) {
          if (!pack.items[i].count) continue;
          const count = pack.items[i].count;
          packCount = packCount + count;
          let itemSum = pack.items[i].price || '0';
          if (count > 1) {
            itemSum = mainStore.convertKopToRub(mainStore.convertRubToKop(itemSum) * count);
          }
          let itemSumDiscount = itemSum;
          let itemSumDisplayed = itemSum;
          if (pack.items[i].discount && pack.items[i].discount?.catalog_visibility) {
            itemSumDiscount = mainStore.convertKopToRub(
              mainStore.convertRubToKop(pack.items[i].price_with_discount || '0') * count);
            itemSumDisplayed = itemSumDiscount;
          }
          packSum = mainStore.additionFloat(packSum, itemSum);
          packSumDiscount = mainStore.additionFloat(packSumDiscount, itemSumDiscount);
          packSumDisplayed = mainStore.additionFloat(packSumDisplayed, itemSumDisplayed);
          if (pack.items[i].offerId === null) continue;
          const totalOfferId = `${promotion.id}_${pack.id}_${pack.items[i].offerId}`;
          if (totals.offers[totalOfferId] === undefined) totals.offers[totalOfferId] = [0, '0'];
          totals.offers[totalOfferId][0] = totals.offers[totalOfferId][0] + count;
          totals.offers[totalOfferId][1] = mainStore.additionFloat(totals.offers[totalOfferId][1], itemSum);
        }
        if (!packCount && packSum === '0') return;
        totals.packs[`${promotion.id}_${pack.id}`] = [packCount, packSumDisplayed];
        if (pack.id.indexOf('gifts') !== -1) return;
        promotionCount = promotionCount + packCount;
        promotionSum = mainStore.additionFloat(promotionSum, packSum);
        promotionSumDiscount = mainStore.additionFloat(promotionSumDiscount, packSumDiscount);
        promotionSumDisplayed = mainStore.additionFloat(promotionSumDisplayed, packSumDisplayed);
      });
      if (promotion.typePresent === 'discount' && promotion.discount) {
        promotionSumDiscount = mainStore.subtractionFloat(promotionSumDiscount, mainStore.convertKopToRub(
          Math.ceil(mainStore.convertRubToKop(promotionSum) / 100 * promotion.discount)));
        promotionSumDisplayed = mainStore.subtractionFloat(promotionSumDisplayed, mainStore.convertKopToRub(
          Math.ceil(mainStore.convertRubToKop(promotionSum) / 100 * promotion.discount)));
      }
      totals.promotions[promotion.id] = [promotionCount, promotionSum];
      totals.totalCount = totals.totalCount + promotionCount;
      totals.totalSum = mainStore.additionFloat(totals.totalSum, promotionSum);
      totals.totalSumDiscount = mainStore.additionFloat(totals.totalSumDiscount, promotionSumDiscount);
      totals.totalSumDisplayed = mainStore.additionFloat(totals.totalSumDisplayed, promotionSumDisplayed);
      totals.discountAmount = mainStore.subtractionFloat(totals.totalSum, totals.totalSumDiscount);
    });
    return totals;
  }

  @computed get getIsPromotionPackCompleted(): Record<string, boolean> {
    const packsCompleted: Record<string, boolean> = {};
    if (!this.promotion || !this.promotionId) return packsCompleted;
    if (!this.basket[this.promotionId]) return packsCompleted;
    if (!this.promotion.promotionPackIds.length) return packsCompleted;
    for (let i = 0; i < this.promotion.promotionPackIds.length; i++) {
      const packId = this.promotion.promotionPackIds[i];
      packsCompleted[`goods_${packId}`] = true;
      if (!this.getTotals.packs[`${this.promotionId}_goods_${packId}`]) {
        packsCompleted[`goods_${packId}`] = false;
        continue;
      }
      if (this.promotion.type === 'free') continue;
      const pack = this.promotion.promotion_packs[packId];
      if (pack.type === 'amount_to_buy' && pack.settings?.amount_to_buy) {
        const amountDiff = mainStore.subtractionFloat(
          pack.settings.amount_to_buy * this.promotion.multiplicity,
          this.getTotals.packs[`${this.promotionId}_goods_${packId}`][1],
        );
        if (amountDiff !== '0' && amountDiff.indexOf('-') === -1) {
          packsCompleted[`goods_${packId}`] = false;
        }
      } else {
        if (this.getTotals.packs[`${this.promotionId}_goods_${packId}`][0] !== (pack.totalQty * this.promotion.multiplicity)) {
          packsCompleted[`goods_${packId}`] = false;
        }
      }
    }
    if (this.promotion.giftPackIds.length) {
      for (let i = 0; i < this.promotion.giftPackIds.length; i++) {
        const packId = this.promotion.giftPackIds[i];
        packsCompleted[`gifts_${packId}`] = true;
        if (this.promotion.discount && this.basket[this.promotionId].typePresent === 'discount') continue;
        if (!this.getTotals.packs[`${this.promotionId}_gifts_${packId}`]) {
          packsCompleted[`gifts_${packId}`] = false;
          continue;
        }
        if (this.getTotals.packs[`${this.promotionId}_gifts_${packId}`][0] !== (this.promotion.gift_packs[packId].totalQty * this.promotion.multiplicity)) {
          packsCompleted[`gifts_${this.promotion.giftPackIds[i]}`] = false;
        }
      }
    }
    return packsCompleted;
  }

  @computed get getIsPromotionCompleted(): boolean {
    if (!this.promotion || !this.promotionId) return false;
    if (!this.promotion.promotionPackIds.length) return false;
    if (!Object.keys(this.getIsPromotionPackCompleted).length) return false;
    return !Object.values(this.getIsPromotionPackCompleted).some((flag) => !flag);
  }

  @computed get getIsPromotionChanged(): boolean {
    if (!Object.keys(this.basket).length || !this.promotionId || !this.promotion) return false;
    if (this.basket[this.promotionId].multiplicity !== 1) return true;
    if (!this.promotion.packNoFixedCountIds.length && this.promotion.packFixedCountIds.length) return false;
    if (!Object.keys(this.basket[this.promotionId].packs).length) return false;
    return Object.values(this.basket[this.promotionId].packs).some(pack => !!pack.items.length);
  }

  @computed get getIsPromotionManualChanged(): boolean {
    if (!Object.keys(this.basket).length || !this.promotionId || !this.promotion) return false;
    if (this.basket[this.promotionId].multiplicity !== 1) return true;
    if (!this.promotion.packNoFixedCountIds.length && this.promotion.packFixedCountIds.length) return false;
    if (!Object.keys(this.basket[this.promotionId].packs).length) return false;
    return Object.values(this.basket[this.promotionId].packs).some(pack => {
      return this.promotion?.packNoFixedCountIds.indexOf(pack.id) !== -1 && pack.items.length;
    });
  }

  getActiveCategories(activeCategoryId: number, categories: Category[], level: number): Breadcrumb[] {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].id === activeCategoryId) {
        return [
          {
            id: categories[i].id,
            name: mainStore.firstLetterUp(categories[i].name),
          },
        ];
      } else {
        if (categories[i].children.length) {
          const breadcrumb: Breadcrumb[] = this.getActiveCategories(
            activeCategoryId, categories[i].children, level + 1);
          if (breadcrumb.length) {
            breadcrumb.unshift({
              id: categories[i].id,
              name: mainStore.firstLetterUp(categories[i].name),
            });
            return breadcrumb;
          }
        }
      }
    }
    return [];
  }

  getCheckedBrandLines(lines: FilterLineItem[]): number {
    let checkedCount: number = 0;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].checked) checkedCount++;
    }
    return checkedCount;
  }

  getBasketItem(goodId: number, promotionId: number | null, packId: string): BasketItem | null {
    if (!Object.keys(this.basket).length || !promotionId) return null;
    if (!this.basket[promotionId]) return null;
    if (!this.basket[promotionId].packs[packId]) return null;
    if (!this.basket[promotionId].packs[packId].items) return null;
    for (let i = 0; i < this.basket[promotionId].packs[packId].items.length; i++) {
      if (this.basket[promotionId].packs[packId].items[i].good_id === goodId) return this.basket[promotionId].packs[packId].items[i];
    }
    return null;
  }

  getItemsByType(packs: BasketPack[], acceptType: string): BasketItem[] {
    let items: BasketItem[] = [];
    for (let i = 0; i < packs.length; i++) {
      if (packs[i].id.indexOf(acceptType) === -1) continue;
      items = items.concat(packs[i].items);
    }
    return items;
  }

  isDisabledGiftPack(packId: number): boolean {
    if (!this.promotion || this.promotion.type !== 'n_gifts' || !this.promotion.settings?.n) return false;
    const giftPacksData = Object.keys(this.getTotals.packs).filter(
      (id) => id.indexOf(`${this.promotionId}_gifts`) !== -1);
    if (giftPacksData.some((id) => id.indexOf(`_${packId}`) !== -1)) return false;
    return giftPacksData.length >= this.promotion.settings.n;
  }

  isBasketPromotionExist(): boolean {
    if (!Object.keys(this.basket).length) return false;
    return Object.values(this.basket).some(promotion => promotion.isValid);
  }

  @action setInit() {}

  @action clearCache() {
    this.promotionList = [];
    this.clientId = null;
    this.categories = [];
    this.sortingOrder = SortingOrder.Asc;
    this.filters = [];
    this.properties = [];
    this.offers = [];
    this.gifts = [];
    this.breadcrumbs = [];
    this.searchField = '';
    this.curPage = 1;
    this.listCurPage = 1;
    this.totalPages = 0;
    this.listTotalPages = 0;
    this.activeFilters = {
      count: 0,
      brandList: [],
      brandLineList: [],
      propList: [],
      brandIds: [],
      brandLineIds: [],
      propIds: [],
    };
    this.expandedOfferId = [null, null];
    this.expandedPackId = null;
    this.promotion = null;
    this.prevGetParam = {};
    this.promotionId = null;
    this.searchPicks = [];
  }

  @action setClientId(clientId: string | null) {
    this.filters = [];
    this.properties = [];
    this.categories = [];
    this.offers = [];
    this.gifts = [];
    this.prevGetParam = {};
    this.activeFilters = {
      count: 0,
      brandList: [],
      brandLineList: [],
      propList: [],
      brandIds: [],
      brandLineIds: [],
      propIds: [],
    };
    this.breadcrumbs = [];
    this.searchField = '';
    this.curPage = 1;
    this.totalPages = 0;
    this.expandedOfferId = [null, null];
    this.expandedPackId = null;
    this.searchPicks = [];
    this.promotionId = null;
    if (clientId && clientId === this.clientId) return;
    this.clientId = clientId;
    if (mainStore.userStore.isClient) return;
    this.basket = {};
  }

  @action addBasketItem(
    item: BasketItem, promotionId: number | null, packId: string, packName: string, packRef: string) {
    if (!promotionId) return;
    if (!Object.keys(this.basket).length || !this.basket[promotionId]) {
      this.basket[promotionId] = {
        packs: {
          [packId]: {
            items: [item],
            name: packName,
            id: packId,
            ref: packRef,
          },
        },
        name: this.promotion?.name || '',
        typePresent: 'gift',
        discount: this.promotion?.discount || 0,
        id: promotionId,
        multiplicity: this.promotion?.multiplicity || 1,
        isValid: this.getIsPromotionCompleted,
        ref: this.promotion?.ref || '',
      };
      return;
    }
    if (this.basket[promotionId].packs[packId]) {
      if (this.basket[promotionId].packs[packId].items) {
        this.basket[promotionId].packs[packId].items.push(item);
      } else {
        this.basket[promotionId].packs[packId].items = [item];
      }
    } else {
      this.basket[promotionId].packs[packId] = {
        items: [item],
        name: packName,
        id: packId,
        ref: packRef,
      };
    }
  }

  @action setBasketMultiplicity(multiplicity: number) {
    if (!this.promotionId) return;
    if (!Object.keys(this.basket).length || !this.basket[this.promotionId]) {
      this.basket[this.promotionId] = {
        packs: {},
        name: this.promotion?.name || '',
        typePresent: 'gift',
        discount: this.promotion?.discount || 0,
        id: this.promotionId,
        multiplicity: multiplicity,
        isValid: this.getIsPromotionCompleted,
        ref: this.promotion?.ref || '',
      };
    } else this.basket[this.promotionId].multiplicity = multiplicity;
  }

  @action updateBasketValid() {
    if (!this.promotionId) return;
    if (!Object.keys(this.basket).length || !this.basket[this.promotionId]) {
      this.basket[this.promotionId] = {
        packs: {},
        name: this.promotion?.name || '',
        typePresent: 'gift',
        discount: this.promotion?.discount || 0,
        id: this.promotionId,
        multiplicity: this.promotion?.multiplicity || 1,
        isValid: this.getIsPromotionCompleted,
        ref: this.promotion?.ref || '',
      };
    } else this.basket[this.promotionId].isValid = this.getIsPromotionCompleted;
  }

  @action changeBasketPromotionTypePresent(type: 'gift' | 'discount') {
    if (!this.promotionId) return;
    if (!Object.keys(this.basket).length || !this.basket[this.promotionId]) {
      this.basket[this.promotionId] = {
        packs: {},
        name: this.promotion?.name || '',
        typePresent: type,
        discount: this.promotion?.discount || 0,
        id: this.promotionId,
        multiplicity: this.promotion?.multiplicity || 1,
        isValid: this.getIsPromotionCompleted,
        ref: this.promotion?.ref || '',
      };
      return;
    }
    this.basket[this.promotionId].typePresent = type;
  }

  @action removeBasketItem(goodId: number, promotionId: number | null, packId: string) {
    if (!Object.keys(this.basket).length || !promotionId) return;
    const items = this.basket[promotionId].packs[packId].items.filter(item => item.good_id !== goodId);
    if (items.length) this.basket[promotionId].packs[packId].items = items;
    else delete this.basket[promotionId].packs[packId];
  }

  @action clearBasketNoFixedPack() {
    if (!Object.keys(this.basket).length || !this.promotionId || !this.promotion) return;
    for (let i = 0; i < this.promotion.packNoFixedCountIds.length; i++) {
      delete this.basket[this.promotionId].packs[this.promotion.packNoFixedCountIds[i]];
    }
  }

  @action syncBasketFixedPack() {
    if (!Object.keys(this.basket).length || !this.promotionId || !this.promotion) return;
    Object.values(this.basket[this.promotionId].packs).forEach(pack => {
      if (!this.promotionId || !this.promotion) return;
      if (this.promotion.packFixedCountIds.indexOf(pack.id) === -1) return;
      pack.items.forEach(item => {
        if (!this.promotion) return;
        const [type, id] = pack.id.split('_');
        if (type === 'goods') {
          item.count = (this.promotion.promotion_packs[id].products[item.good_id].settings?.quantity_to_buy || 0) * this.promotion.multiplicity;
        } else {
          item.count = (this.promotion.gift_packs[id].products[item.good_id].settings?.quantity_to_gift || 0) * this.promotion.multiplicity;
        }
      });
    });
  }

  @action resetBasketPromotion() {
    if (!Object.keys(this.basket).length || !this.promotionId || !this.promotion) return false;
    this.promotion.multiplicity = 1;
    this.basket[this.promotionId].multiplicity = 1;
    this.basket[this.promotionId].typePresent = 'gift';
    this.clearBasketNoFixedPack();
    this.syncBasketFixedPack();
  }

  @action removeBasketPromotion(promotionId: number | null) {
    if (!Object.keys(this.basket).length || !promotionId) return;
    delete this.basket[promotionId];
  }

  @action mergeBasketToCatalog(promotionId: number | null) {
    if (!Object.keys(this.basket).length || !promotionId) return;
    this.optimizeBasket();
    const newBasketItems: BasketItem[] = [];
    Object.values(this.basket[promotionId].packs).map(pack => {
      for (let i = 0; i < pack.items.length; i++) {
        const catalogBasketItem = mainStore.catalogStore.getBasketItem(pack.items[i].good_id);
        if (!catalogBasketItem) newBasketItems.push(pack.items[i]);
        else {
          let newCount = catalogBasketItem.count + pack.items[i].count;
          if (newCount > (catalogBasketItem.qty || 0)) newCount = catalogBasketItem.qty || 0;
          catalogBasketItem.count = newCount;
        }
      }
      return null;
    });
    if (newBasketItems.length) {
      mainStore.catalogStore.basket = mainStore.catalogStore.basket.concat(newBasketItems);
    }
    this.removeBasketPromotion(promotionId);
  }

  @action optimizeBasket() {
    if (!Object.keys(this.basket).length) return;
    Object.values(this.basket).forEach(promotion => {
      Object.values(promotion.packs).forEach(pack => {
        this.basket[promotion.id].packs[pack.id].items = pack.items.filter(item => item.count);
      });
      return null;
    });
  }

  @action setMultiplicity(multiplicity: number, isDecrease = false) {
    if (!this.promotion) return;
    this.promotion.multiplicity = multiplicity;
    this.setBasketMultiplicity(multiplicity);
    if (isDecrease) this.clearBasketNoFixedPack();
    this.syncBasketFixedPack();
  }

  @action
  async getGoodById(goodId: number): Promise<{ good: Good | null, isExist: boolean }> {
    for (let i = 0; i < this.offers.length; i++) {
      for (let j = 0; j < this.offers[i].goods.length; j++) {
        if (this.offers[i].goods[j].good_id === goodId) {
          if (this.offers[i].goods[j].offer_id === undefined) this.offers[i].goods[j].offer_id = this.offers[i].offer_id;
          if (!this.offers[i].goods[j].offer_name) this.offers[i].goods[j].offer_name = this.offers[i].name;
          return Promise.resolve({ good: this.offers[i].goods[j], isExist: true });
        }
      }
    }
    for (let i = 0; i < this.searchPicks.length; i++) {
      for (let j = 0; j < this.searchPicks[i].goods.length; j++) {
        if (this.searchPicks[i].goods[j].good_id === goodId) {
          if (this.searchPicks[i].goods[j].offer_id === undefined) this.searchPicks[i].goods[j].offer_id = this.searchPicks[i].offer_id;
          if (!this.searchPicks[i].goods[j].offer_name) this.searchPicks[i].goods[j].offer_name = this.searchPicks[i].name;
          return Promise.resolve({ good: this.searchPicks[i].goods[j], isExist: false });
        }
      }
    }
    let offer: GoodResponse | null = null;
    try {
      offer = await mainStore.catalogStore.getGood(goodId);
    } catch (e) {}
    if (offer) {
      if (this.searchPicks.length > 10) this.searchPicks = [];
      this.searchPicks.push(offer.data.good);
      for (let j = 0; j < offer.data.good.goods.length; j++) {
        if (offer.data.good.goods[j].good_id === goodId) {
          if (offer.data.good.goods[j].offer_id === undefined) offer.data.good.goods[j].offer_id = offer.data.good.offer_id;
          if (!offer.data.good.goods[j].offer_name) offer.data.good.goods[j].offer_name = offer.data.good.name;
          return Promise.resolve({ good: offer.data.good.goods[j], isExist: false });
        }
      }
    }
    return Promise.resolve({ good: null, isExist: false });
  }

  @action increaseGoodNotifyCount(goodId: number) {
    this.getGoodById(goodId).then((e) => {
      if (!e.good) return;
      e.good.notification_count = (e.good.notification_count || 0) + 1;
    });
  }

  @action getBreadcrumbs(activeCategoryId: number | null) {
    this.breadcrumbs = [];
    if (activeCategoryId === null || !this.categories.length) return;
    this.breadcrumbs = this.getActiveCategories(activeCategoryId, this.categories, 0);
  }

  @action setProperties(activePropIds: number[]) {
    this.activeFilters.count = this.activeFilters.brandIds.length + this.activeFilters.brandLineIds.length;
    this.activeFilters.propList = [];
    this.activeFilters.propIds = [];
    if (!this.properties.length) return;
    for (let i = 0; i < this.properties.length; i++) {
      if (!this.properties[i].items.length) continue;
      for (let j = 0; j < this.properties[i].items.length; j++) {
        if (activePropIds.length && activePropIds.indexOf(this.properties[i].items[j].item_id) !== -1) {
          this.activeFilters.propList.push({
            id: this.properties[i].items[j].item_id,
            name: `${this.properties[i].name || 'Без названия'}: ${this.properties[i].items[j].name || 'Без названия'}`,
          });
          this.activeFilters.propIds.push(this.properties[i].items[j].item_id);
          this.properties[i].items[j].checked = true;
        } else this.properties[i].items[j].checked = false;
      }
    }
    this.activeFilters.count += this.activeFilters.propIds.length;
  }

  @action setExpandedPackId(id: ExpandedPackId) {
    if (!this.promotion) {
      this.expandedPackId = null;
      return;
    }
    let expandedPackId: ExpandedPackId = null;
    if (id) {
      if (id[0] === 'goods' && this.promotion.promotionPackIds.indexOf(id[1]) !== -1) {
        expandedPackId = [id[0], id[1]];
      }
      if (id[0] === 'gifts' && this.promotion.giftPackIds.indexOf(id[1]) !== -1) {
        expandedPackId = [id[0], id[1]];
      }
    } else {
      if (this.promotion.promotionPackIds.length) {
        expandedPackId = ['goods', this.promotion.promotionPackIds[0]];
      } else if (this.promotion.giftPackIds.length) {
        expandedPackId = ['gifts', this.promotion.giftPackIds[0]];
      }
    }
    if (!this.expandedPackId && !expandedPackId) return;
    if (this.expandedPackId && expandedPackId && this.expandedPackId[0] === expandedPackId[0] && this.expandedPackId[1] === expandedPackId[1]) return;
    this.offers = [];
    this.totalPages = 0;
    this.curPage = 1;
    this.expandedOfferId = [null, null];
    this.expandedPackId = expandedPackId;
  }

  @action parseGetParams(params: string) {
    const sp: URLSearchParams = new URLSearchParams(params);
    const changedList: { [key: string]: boolean } = {
      pt: false,
      pid: false,
      b: false,
      bl: false,
      c: false,
      f: false,
      p: false,
      q: false,
      s: false,
    };
    const pt: string = sp.get('pt') || '';
    const pid: string = sp.get('pid') || '';
    const b: string = sp.get('b') || '';
    const bl: string = sp.get('bl') || '';
    const c: string = sp.get('c') || '';
    const f: string = sp.get('f') || '';
    const p: string = sp.get('p') || '';
    const s: string = sp.get('s') || '';
    let q: string = sp.get('q') || '';
    if (b || bl || c || f) q = '';
    if (!c) {
      this.properties = [];
      this.setProperties([]);
    }
    let activeBrandIds: number[] = mainStore.splitIntoNumbers(b, ',');
    let activeBrandLineIds: number[] = mainStore.splitIntoNumbers(bl, ',');
    let activeCategory: number | null = null;
    let activePropIds: number[] = [];
    if (this.prevGetParam.pt !== pt) {
      this.prevGetParam.pt = pt;
      changedList.pt = true;
    }
    if (this.prevGetParam.pid !== pid) {
      this.prevGetParam.pid = pid;
      changedList.pid = true;
    }
    if (changedList.pt || changedList.pid) {
      this.setExpandedPackId((pt === 'goods' || pt === 'gifts') && pid ? [pt, pid] : null);
    }
    if (this.prevGetParam.b !== b) {
      this.prevGetParam.b = b;
      changedList.b = true;
    }
    if (this.prevGetParam.bl !== bl) {
      this.prevGetParam.bl = bl;
      changedList.bl = true;
    }
    if (changedList.b || changedList.bl) {
      this.activeFilters = {
        count: 0,
        brandList: [],
        brandLineList: [],
        propList: [],
        brandIds: [],
        brandLineIds: [],
        propIds: [],
      };
      if (this.filters.length) {
        for (let i = 0; i < this.filters[0].data.length; i++) {
          if (activeBrandIds.length && activeBrandIds.indexOf(this.filters[0].data[i].id) !== -1) {
            this.activeFilters.brandList.push({
              id: this.filters[0].data[i].id,
              name: `Бренд: ${this.filters[0].data[i].name || 'Без названия'}`,
            });
            this.activeFilters.brandIds.push(this.filters[0].data[i].id);
            this.filters[0].data[i].checked = true;
            for (let j = 0; j < this.filters[0].data[i].lines.length; j++) {
              this.filters[0].data[i].lines[j].checked = true;
            }
          } else {
            this.filters[0].data[i].checked = false;
            for (let j = 0; j < this.filters[0].data[i].lines.length; j++) {
              if (activeBrandLineIds.length && activeBrandLineIds.indexOf(this.filters[0].data[i].lines[j].id) !== -1) {
                this.activeFilters.brandLineList.push({
                  id: this.filters[0].data[i].lines[j].id,
                  name: `Бренд: ${this.filters[0].data[i].name || 'Без названия'} > ${this.filters[0].data[i].lines[j].name || 'Без названия'}`,
                });
                this.activeFilters.brandLineIds.push(this.filters[0].data[i].lines[j].id);
                this.filters[0].data[i].lines[j].checked = true;
              } else this.filters[0].data[i].lines[j].checked = false;
            }
          }
        }
        this.activeFilters.count = this.activeFilters.brandIds.length + this.activeFilters.brandLineIds.length;
      }
    }
    if (this.prevGetParam.c !== c) {
      this.prevGetParam.c = c;
      changedList.c = true;
      activeCategory = parseInt(c, 10) || null;
      this.breadcrumbs = [];
    }
    if (this.prevGetParam.f !== f) {
      this.prevGetParam.f = f;
      changedList.f = true;
      if (c) activePropIds = mainStore.splitIntoNumbers(f, ',');
    }
    if (this.prevGetParam.p !== p) {
      this.prevGetParam.p = p;
      changedList.p = true;
      this.curPage = parseInt(p, 10) || 1;
    }
    if (this.prevGetParam.q !== q) {
      this.prevGetParam.q = q;
      changedList.q = true;
      this.searchField = q;
    }
    if (this.prevGetParam.s !== s) {
      this.prevGetParam.s = s;
      changedList.s = true;
      this.sortingOrder = parseInt(s, 10) === 2 ? 2 : 1;
    }
    if (Object.values(changedList).indexOf(true) !== -1) {
      this.expandedOfferId = [null, null];
    }
    if (changedList.b || changedList.bl) {
      sequentialRequests([
        () => this.getPromotionTree(this.expandedPackId).then(() => {
          this.getBreadcrumbs(activeCategory);
          return Promise.resolve();
        }),
        () => c ? this.getPromotionProperties(this.expandedPackId).then(() => {
          this.setProperties(activePropIds);
          return Promise.resolve();
        }) : Promise.resolve(),
        () => {
          this.getPromotionGoods(this.expandedPackId);
          return Promise.resolve();
        },
      ]).catch(() => void 0);
      return;
    }
    if (changedList.c) {
      if (!this.categories.length) return;
      this.getBreadcrumbs(activeCategory);
      sequentialRequests([
        () => c ? this.getPromotionProperties(this.expandedPackId).then(() => {
          this.setProperties(activePropIds);
          return Promise.resolve();
        }) : Promise.resolve(),
        () => {
          this.getPromotionGoods(this.expandedPackId);
          return Promise.resolve();
        },
      ]).catch(() => void 0);
      return;
    }
    if (changedList.f) {
      if (!this.properties.length) return;
      this.setProperties(activePropIds);
      this.getPromotionGoods(this.expandedPackId);
      return;
    }
    if (changedList.p || changedList.q || changedList.s) {
      this.getPromotionGoods(this.expandedPackId);
    }
  }

  @action getPromotions(getParams: string) {
    if (mainStore.activeRequestsSet.has('promotions_getPromotions')) return;
    mainStore.activeRequestsSet.add('promotions_getPromotions');
    const curPage = parseInt(new URLSearchParams(getParams).get('p') || '1', 10);
    const requestData: PromotionsRequest = {
      page: curPage,
      per_page: PROMOTION_ITEMS_PER_PAGE,
    };
    if (this.clientId) {
      requestData.customer_id = this.clientId;
    }
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    PromotionsRequests.getPromotions(requestData).then(e => {
      runInAction(() => {
        this.promotionList = e.data.promotions.data;
        this.listTotalPages = e.data.promotions.pages || 0;
        this.listCurPage = parseInt(e.data.promotions.page || curPage.toString(), 10);
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPromotions')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotions_getPromotions');
      });
    });
  }

  @action getPromotion(promotionID: string, getParams: string) {
    if (!promotionID) return;
    if (mainStore.activeRequestsSet.has('promotions_getPromotion')) return;
    mainStore.activeRequestsSet.add('promotions_getPromotion');
    this.promotion = null;
    const requestData: PromotionRequest = {
      region_id: mainStore.catalogStore.regionId,
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    if (this.clientId) {
      requestData.customer_id = this.clientId;
    }
    PromotionsRequests.getPromotion(promotionID, requestData).then(({ data }) => {
      runInAction(() => {
        if (!data || !data.promotion) {
          this.promotion = null;
          return;
        }
        data.promotion.packFixedCountIds = [];
        data.promotion.packNoFixedCountIds = [];
        data.promotion.promotionPackIds = Object.keys(data.promotion.promotion_packs).filter((id) => {
          let isFixedCount = false;
          let totalQty = data.promotion.promotion_packs[id].settings?.quantity_to_buy || 0;
          if (!totalQty && data.promotion.promotion_packs[id].products.length === undefined) {
            totalQty = Object.values(
              data.promotion.promotion_packs[id].products).reduce(
              (sum, item) => {
                return sum + (item.settings?.quantity_to_buy || 0);
              }, 0);
            isFixedCount = !!totalQty;
          }
          data.promotion.promotion_packs[id].totalQty = totalQty;
          data.promotion.promotion_packs[id].isFixedCount = isFixedCount;
          if (isFixedCount) data.promotion.packFixedCountIds.push('goods_' + id);
          else data.promotion.packNoFixedCountIds.push('goods_' + id);
          return data.promotion.promotion_packs[id].products.length === undefined;
        });
        data.promotion.giftPackIds = Object.keys(data.promotion.gift_packs).filter((id) => {
          if (data.promotion.gift_packs[id].type === 'discount') return false;
          let isFixedCount = false;
          let totalQty = data.promotion.gift_packs[id].settings?.quantity_to_gift || 0;
          if (!totalQty && data.promotion.gift_packs[id].products.length === undefined) {
            totalQty = Object.values(data.promotion.gift_packs[id].products).reduce(
              (sum, item) => {
                return sum + (item.settings?.quantity_to_gift || 0);
              }, 0);
            isFixedCount = !!totalQty;
          }
          data.promotion.gift_packs[id].totalQty = totalQty;
          data.promotion.gift_packs[id].isFixedCount = isFixedCount;
          if (isFixedCount) data.promotion.packFixedCountIds.push('gifts_' + id);
          else data.promotion.packNoFixedCountIds.push('gifts_' + id);
          return data.promotion.gift_packs[id].products.length === undefined;
        });
        if (data.promotion.type === 'n_gifts' && data.promotion.settings?.n === 1) {
          Object.values(data.promotion.gift_packs).forEach((item) => {
            if (item.type === 'discount') {
              data.promotion.discount = item.settings?.discount;
            }
          });
        }
        if (data.promotion.type === 'base' && data.promotion.settings?.multiplicity === 1) {
          if (!Object.values(data.promotion.gift_packs).some((item) => item.type !== 'discount')) {
            data.promotion.type = 'free';
          }
        }
        this.promotion = data.promotion;
        this.promotionId = parseInt(promotionID, 10);
        this.setMultiplicity(this.basket[data.promotion.id]?.multiplicity || 1);
        const sp: URLSearchParams = new URLSearchParams(getParams);
        const pt: string = sp.get('pt') || '';
        const pid: string = sp.get('pid') || '';
        this.setExpandedPackId((pt === 'goods' || pt === 'gifts') && pid ? [pt, pid] : null);
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPromotion')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotions_getPromotion');
      });
    });
  }

  @action getPromotionBrands(packType: 'goods' | 'gifts', packID: number | null, getParams: string): Promise<any> {
    if (!this.clientId || this.promotionId === null) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('promotion_getPromotionBrands')) return Promise.reject(
      responseOverlappingError);
    mainStore.activeRequestsSet.add('promotion_getPromotionBrands');
    const requestData: PromotionBrandsRequest = {
      customer_id: this.clientId,
      manager_id: mainStore.cabinetStore.manager?.id,
      promotion_id: this.promotionId,
      region_id: mainStore.catalogStore.regionId,
    };
    if (packID !== null) {
      if (packType === 'goods') requestData.promotion_pack_id = packID;
      else requestData.promotion_gift_pack_id = packID;
    }
    return PromotionsRequests.getPromotionBrands(packType, requestData).then(e => {
      runInAction(() => {
        this.filters = e.data.filter || [];
        UI.accordion.reset('.filter__part._brand .accordion__body');
        this.prevGetParam.b = undefined;
        this.prevGetParam.bl = undefined;
        this.parseGetParams(getParams);
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPromotionBrands')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotion_getPromotionBrands');
      });
    });
  };

  @action getPromotionTree(packData: ExpandedPackId): Promise<any> {
    if (!this.clientId || this.promotionId === null || !packData) return Promise.reject(responseNetworkError);
    if (mainStore.activeRequestsSet.has('promotion_getPromotionTree')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('promotion_getPromotionTree');
    const requestData: PromotionTreeRequest = {
      customer_id: this.clientId,
      promotion_id: this.promotionId,
      filters: {},
      region_id: mainStore.catalogStore.regionId,
    };
    if (packData[0] === 'goods') requestData.promotion_pack_id = parseInt(packData[1], 10);
    else requestData.promotion_gift_pack_id = parseInt(packData[1], 10);
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    if (this.activeFilters.brandIds.length) {
      requestData.filters.brand_id = this.activeFilters.brandIds;
    }
    if (this.activeFilters.brandLineIds.length) {
      requestData.filters.brand_line_id = this.activeFilters.brandLineIds;
    }
    return PromotionsRequests.getPromotionTree(packData[0], requestData).then(e => {
      runInAction(() => {
        this.categories = e.data.catalogs || [];
        UI.accordion.reset('.filter__part._category .accordion__body');
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPromotionTree')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotion_getPromotionTree');
      });
    });
  };

  @action getPromotionProperties(packData: ExpandedPackId): Promise<any> {
    if (!this.clientId || this.promotionId === null || !packData) return Promise.reject(responseNetworkError);
    if (mainStore.activeRequestsSet.has('promotion_getPromotionProperties')) return Promise.reject(
      responseOverlappingError);
    mainStore.activeRequestsSet.add('promotion_getPromotionProperties');
    const requestData: PromotionPropertiesRequest = {
      customer_id: this.clientId,
      promotion_id: this.promotionId,
      filters: {},
      region_id: mainStore.catalogStore.regionId,
    };
    if (packData[0] === 'goods') requestData.promotion_pack_id = parseInt(packData[1], 10);
    else requestData.promotion_gift_pack_id = parseInt(packData[1], 10);
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    } else {
      requestData.customer_id = mainStore.cabinetStore.cabinet!.id;
    }
    if (this.activeFilters.brandIds.length) {
      requestData.filters.brand_id = this.activeFilters.brandIds;
    }
    if (this.activeFilters.brandLineIds.length) {
      requestData.filters.brand_line_id = this.activeFilters.brandLineIds;
    }
    if (this.breadcrumbs.length) {
      requestData.catalog_id = this.breadcrumbs.slice(-1)[0].id;
    }
    return PromotionsRequests.getPromotionProperties(packData[0], requestData).then(e => {
      runInAction(() => {
        this.properties = e.data.property || [];
        UI.accordion.reset('.filter__part._properties .accordion__body');
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPromotionProperties')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotion_getPromotionProperties');
      });
    });
  };

  @action getPromotionGoods(packData: ExpandedPackId) {
    if (!this.clientId || this.promotionId === null || !packData) return;
    if (mainStore.activeRequestsSet.has('promotions_getPromotionGoods')) return;
    mainStore.activeRequestsSet.add('promotions_getPromotionGoods');
    this.offers = [];
    const requestData: PromotionGoodsRequest = {
      customer_id: this.clientId,
      promotion_id: this.promotionId,
      filters: {},
      properties: this.activeFilters.propIds,
      q: this.searchField,
      page: this.curPage,
      perPage: CATALOG_ITEMS_PER_PAGE,
      sort: {
        name: 'name',
        type: this.sortingOrder === SortingOrder.Desc ? 'desc' : 'asc',
      },
      region_id: mainStore.catalogStore.regionId,
      doc_type: mainStore.catalogStore.docType ? 'bsf' : ''
    };
    if (packData[0] === 'goods') requestData.promotion_pack_id = parseInt(packData[1], 10);
    else requestData.promotion_gift_pack_id = parseInt(packData[1], 10);
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    if (this.activeFilters.brandIds.length) {
      requestData.filters.brand_id = this.activeFilters.brandIds;
    }
    if (this.activeFilters.brandLineIds.length) {
      requestData.filters.brand_line_id = this.activeFilters.brandLineIds;
    }
    if (this.breadcrumbs.length) {
      requestData.catalog_id = this.breadcrumbs.slice(-1)[0].id;
    }
    PromotionsRequests.getPromotionGoods(packData[0], requestData).then(({ data }) => {
      runInAction(() => {
        this.offers = data.goods || [];
        this.totalPages = data.pages || 0;
        this.curPage = data.page || 1;
        this.expandedOfferId = [null, null];
        const packId = `${packData[0]}_${packData[1]}`;
        if (!this.promotionId || !this.promotion || this.promotion.packFixedCountIds.indexOf(
          packId) === -1 || (this.basket[this.promotionId].packs[packId] && this.basket[this.promotionId].packs[packId].items?.length)) return;
        const items: BasketItem[] = [];
        let name;
        let ref;
        (data.goods || []).forEach(offer => {
          offer.goods.forEach(good => {
            if (!this.promotion) return;
            let count;
            if (packData[0] === 'goods') {
              count = (this.promotion.promotion_packs[packData[1]].products[good.good_id].settings?.quantity_to_buy || 0) * this.promotion.multiplicity;
            } else {
              count = (this.promotion.gift_packs[packData[1]].products[good.good_id].settings?.quantity_to_gift || 0) * this.promotion.multiplicity;
            }
            items.push({ ...good, count: count, offerId: offer.offer_id });
          });
        });
        if (packData[0] === 'goods') {
          name = this.promotion.promotion_packs[packData[1]].comment || this.promotion.promotion_packs[packData[1]].name || '';
          ref = this.promotion.promotion_packs[packData[1]].ref;
        } else {
          name = this.promotion.gift_packs[packData[1]].comment || this.promotion.promotion_packs[packData[1]].name || '';
          ref = this.promotion.gift_packs[packData[1]].ref;
        }
        this.basket[this.promotionId].packs[packId] = {
          items: items,
          name: name,
          id: packId,
          ref: ref,
        };
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPromotionGoods')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotions_getPromotionGoods');
      });
    });
  }

  @action sendNotifyArrival(goodId: number): Promise<any> {
    if (goodId === undefined || !this.clientId) return Promise.reject(badRequestError);
    if (mainStore.activeRequestsSet.has('promotions_notifyArrival')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('promotions_notifyArrival');
    const requestData: { customer_id: number | string, manager_id?: number } = {
      customer_id: this.clientId,
    };
    if (mainStore.userStore.isManager) {
      requestData.manager_id = mainStore.cabinetStore.manager!.id;
    }
    return CatalogRequests.notifyArrival(goodId, requestData).then(() => {
      runInAction(() => {
        this.increaseGoodNotifyCount(goodId);
      });
      return Promise.resolve();
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'notifyArrival')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('promotions_notifyArrival');
      });
    });
  };

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage: string = '';
    let errorButton: AlertButton | undefined = undefined;
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
