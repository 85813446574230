import { action, computed, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { AuthRequests, LoginResponse } from '../api/Auth';
import { ErrorResponse, responseOverlappingError, sequentialRequests } from '../api/Requests';
import { AlertButton, AlertType, mainStore } from './MainStore';

type LoginType = 'email' | 'phone' | null;


export default class UserStore {
  @persist @observable tokenAccess = '';
  @persist @observable tokenRefresh = '';
  @persist @observable userName = '';
  @persist @observable userType: 'client' | 'manager' | null = null;
  @observable login = '';
  @observable password = '';
  @observable loginType: LoginType = null;
  @observable authStatus: 'request' | 'code' | 'email' = 'request';
  emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  @computed get isAuth(): boolean {
    return !!this.userType;
  };

  @computed get isManager(): boolean {
    return this.userType === 'manager';
  };

  @computed get isClient(): boolean {
    return this.userType === 'client';
  };

  @computed get isValidForm(): boolean {
    if (this.authStatus === 'request') {
      return !!(this.login.length && this.loginType !== null);
    }
    return !!this.password.length;
  };

  checkLoginType(login: string): LoginType {
    if (!login.length) return null;
    if (this.cleanLoginPhone(login).length === 10) return 'phone';
    if (this.emailPattern.test(login)) return 'email';
    return null;
  };

  cleanLoginPhone(login: string): string {
    return login.replace(/\D/g, '').slice(1);
  };

  runLoginRequests() {
    sequentialRequests([
      () => mainStore.getMenu(true),
      () => mainStore.cabinetStore.getPartsData(true),
    ]).catch(() => void 0);
  };

  @action setInit() {
    if (this.isAuth) this.runLoginRequests();
  };

  @action setLogin(login: string) {
    this.login = login;
    this.loginType = this.checkLoginType(login);
  };

  @action setAuthorized(e: LoginResponse) {
    if (!e.data) {
      mainStore.alerts.push({
        message: 'Произошла ошибка при авторизации<br>Пожалуйста, попробуйте позже',
        cssClass: AlertType.Error,
      });
      return;
    }
    if (e.data.user_type !== 'manager' && e.data.user_type !== 'client') {
      mainStore.alerts.push({
        message: 'Произошла ошибка в определении типа пользователя<br>Пожалуйста, попробуйте позже',
        cssClass: AlertType.Error,
      });
      return;
    }
    if (!e.data.token || !e.data.refresh_token) {
      mainStore.alerts.push({
        message: 'Не удалось получить токен безопасности<br>Пожалуйста, попробуйте позже',
        cssClass: AlertType.Error,
      });
      return;
    }
    this.login = '';
    this.password = '';
    this.authStatus = 'request';
    this.loginType = null;
    this.userName = e.data.user_name || '';
    this.userType = e.data.user_type;
    this.tokenAccess = e.data.token;
    this.tokenRefresh = e.data.refresh_token;
    this.runLoginRequests();
  };

  @action logout() {
    this.login = '';
    this.password = '';
    this.authStatus = 'request';
    this.loginType = null;
    this.userName = '';
    this.userType = null;
    this.tokenAccess = '';
    this.tokenRefresh = '';
    mainStore.clearCache();
    mainStore.cabinetStore.clearCache();
    mainStore.routesStore.clearCache();
    mainStore.ordersStore.clearCache();
    mainStore.catalogStore.clearCache();
  };

  @action sendLogin() {
    if (mainStore.activeRequestsSet.has('user_sendLogin')) return;
    mainStore.activeRequestsSet.add('user_sendLogin');
    let request: any = {};
    if (this.loginType === 'phone') {
      request.phone = `+7${this.cleanLoginPhone(this.login)}`;
      request.password = '';
    }
    if (this.loginType === 'email') {
      request.email = this.login;
      request.password = this.password;
    }
    AuthRequests.sendLogin(request).then(e => {
      runInAction(() => {
        if (e.status === 'code') {
          this.authStatus = 'code';
          this.password = '';
        }
        if (e.status === 'email') {
          this.authStatus = 'email';
          mainStore.alerts.push({
            message: 'Мы выслали вам письмо с паролем, введите его в поле Пароль',
            cssClass: AlertType.Info,
          });
        }
        if (e.status === 'complete') this.setAuthorized(e);
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendLogin').catch(() => void 0)).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('user_sendLogin');
      });
    });
  };

  @action sendCode() {
    if (mainStore.activeRequestsSet.has('user_sendCode')) return;
    mainStore.activeRequestsSet.add('user_sendCode');
    AuthRequests.sendCode({
      phone: `+7${this.cleanLoginPhone(this.login)}`,
      code: this.password,
    }).then(e => {
      runInAction(() => {
        if (e.status === 'complete') this.setAuthorized(e);
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendCode').catch(() => void 0)).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('user_sendCode');
      });
    });
  };

  @action refreshToken(): Promise<any> {
    if (mainStore.activeRequestsSet.has('user_refreshToken')) return Promise.reject(responseOverlappingError);
    mainStore.activeRequestsSet.add('user_refreshToken');
    return AuthRequests.refreshToken({
      refresh_token: this.tokenRefresh,
    }).then(e => {
      runInAction(() => {
        this.tokenAccess = e.data?.token || '';
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'refreshToken')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('user_refreshToken');
      });
    });
  };

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage = '';
    let errorButton: AlertButton | undefined = undefined;
    switch (error.status) {
      case 403:
        if (context === 'sendCode') errorMessage = 'Неверный код';
        break;
      case 404:
        errorMessage = 'Пользователь не найден';
        break;
      case 421:
        errorMessage = 'SMS-сервис временно не доступен, попробуйте позже';
        break;
    }
    if (context === 'refreshToken') {
      errorMessage = 'Не удалось обновить токен безопасности<br>Пожалуйста, перезайдите в свой аккаунт';
      errorButton = {
        text: 'Выйти',
        action: () => mainStore.userStore.logout(),
      };
    }
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
