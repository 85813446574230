import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from '@sentry/browser';
import localForage from "localforage";
import { create } from 'mobx-persist';
import { Provider } from "mobx-react";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { API_ROOT, VERSION } from './config';
import * as serviceWorker from './serviceWorker';
import { mainStore } from './stores/MainStore';

Sentry.init({
  enabled: false,
  dsn: "https://1199480dc873406db75ba4a63ffd5000@sentry.hpdev.ru/13",
  environment: (API_ROOT.indexOf('parfum2') !== -1) ? 'Dev' : 'Prod',
  maxBreadcrumbs: 20,
  beforeSend(event) {
    if (event.request?.url) {
      event.request.url = event.request.url.replace(/^https?:\/\/localhost:?(\d?)+/, '');
    }
    //return event;
    return null;
  },
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'fetch') return null;
    if (breadcrumb.category === 'xhr') return null;
    if (breadcrumb.category === 'ui.input') return null;
    if (breadcrumb.category === 'ui.click') return null;
    return breadcrumb;
  }
});
Sentry.setTag('Version', VERSION);

const hydrate = create({storage: localForage});
hydrate('mainStore', mainStore).then(() => mainStore.setInit());

ReactDOM.render(<Provider mainStore={mainStore}><App/></Provider>, document.getElementById('root'));

defineCustomElements(window).catch(e => console.error(e));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
