import { action, computed, observable, runInAction, toJS } from 'mobx';
import { AlertButton, AlertType, mainStore, SortingOrder } from './MainStore';
import { Address, Cabinet, CabinetRequests, Client, Dashboard, Manager, NewClient } from '../api/Cabinet';
import { ErrorResponse, responseLockedError, responseOverlappingError } from '../api/Requests';
import { persist } from 'mobx-persist';
import { CACHE_EXPIRED_CABINET, CLIENTS_PER_PAGE } from '../config';
import { ClientInFilter } from './OrdersStore';

export enum ClientsSortingName {
  None,
  Client,
}

export default class CabinetStore {
  @persist('object') @observable manager: Manager | null = null;
  @persist('object') @observable cabinet: Cabinet | null = null;
  @persist('object') @observable dashboard: Dashboard | null = null;
  @persist('object') @observable excludedClientId: { [key: string]: boolean } = {};
  @persist('list') @observable sortingOrder: SortingOrder[] = [0];
  @persist('list') @observable clients: Client[] = [];
  @persist('list') @observable newClients: NewClient[] = [];
  @persist @observable sortingName: ClientsSortingName = ClientsSortingName.None;
  @persist @observable reconciliationActUrl: string | null = null;
  @persist @observable cacheDate: number | null = null;
  @persist @observable clientsFilterPhrase: string = '';
  @observable delivery: Address[] = [];
  @observable feedbackSubject = '';
  @observable feedbackMessage = '';
  @observable newClientName = '';
  @observable newClientPhone = '';
  @observable newClientAddress = '';
  @observable newClientEmail = '';
  @observable managerName = this.manager?.name || '';
  @observable managerPhone = this.manager?.phone || '';
  @observable clientName = this.cabinet?.name || '';
  @observable clientKpp = this.cabinet?.kpp || '';
  @observable clientInn = this.cabinet?.inn || '';
  @observable clientLegalAddress = this.cabinet?.legal_address || '';
  @observable clientContactName = this.cabinet?.contact_name || '';
  @observable clientContactPhone = this.cabinet?.contact_phone || '';
  @observable clientsList: ClientInFilter[] = [];
  @observable curPage = 1;
  isDataParsed: boolean = false;
  emptyDeliveryAddress: Address = {
    address: '',
    desc: '',
    delivery: [],
  };

  @computed get isValidFeedbackForm(): boolean {
    return !!(this.feedbackSubject.trim().length && this.feedbackMessage.trim().length);
  };

  @computed get isValidNewClientForm(): boolean {
    return (this.newClientName.trim().length > 1 && this.newClientAddress.trim().length > 1);
  };

  @computed get isValidContactsForm(): boolean {
    return !!((this.managerName.trim().length && this.managerPhone.trim().length) && (this.managerName !== this.manager?.name || this.managerPhone !== this.manager?.phone));
  };

  @computed get isValidCabinetForm(): boolean {
    const dataExist: boolean = !!(
      this.clientName.trim().length &&
      this.clientKpp.trim().length &&
      this.clientInn.trim().length &&
      this.clientLegalAddress.trim().length &&
      this.clientContactName.trim().length &&
      this.clientContactPhone.trim().length
    );
    const dataDiff: boolean = (
      this.clientName !== this.cabinet?.name ||
      this.clientKpp !== this.cabinet?.kpp ||
      this.clientInn !== this.cabinet?.inn ||
      this.clientLegalAddress !== this.cabinet?.legal_address ||
      this.clientContactName !== this.cabinet?.contact_name ||
      this.clientContactPhone !== this.cabinet?.contact_phone
    );
    return (dataExist && dataDiff) || (dataExist && JSON.stringify(this.findValidAddresses()) !== JSON.stringify(
      this.cabinet?.addresses));
  };

  @computed get filteredClients(): Client[] {
    let clients = toJS(this.clients);
    if (!clients.length) return clients;
    if (Object.keys(this.excludedClientId).length === this.clientsList.length) return [];
    clients = clients.filter(({ customer: { id } }) => {
      if (Object.keys(this.excludedClientId).length) {
        if (this.excludedClientId[id.toString()]) return false;
      }
      return true;
    });
    return clients;
  }

  @computed get sortedClients(): Client[] {
    let clients = this.filteredClients;
    if (!clients.length) return clients;
    if (!this.sortingName || !this.sortingOrder[this.sortingName]) return clients;
    switch (this.sortingName) {
      case ClientsSortingName.Client:
        clients = clients.slice().sort(({ customer: { name: a } }, { customer: { name: b } }) => {
          if (!a || !b) return 0;
          return a.localeCompare(b) * (this.sortingOrder[this.sortingName] === SortingOrder.Asc ? 1 : -1);
        });
        break;
    }
    return clients;
  }

  @computed get paginatedClients(): Client[] {
    return this.sortedClients.slice(0, this.curPage * CLIENTS_PER_PAGE);
  }

  @computed get filteredClientsList(): ClientInFilter[] {
    let clients = toJS(this.clientsList);
    if (!clients.length || !this.clientsFilterPhrase) return clients;
    return clients.filter(([, name]) => name.toLowerCase().indexOf(this.clientsFilterPhrase.toLowerCase()) !== -1);
  }

  @computed get isLastPage(): boolean {
    return this.sortedClients.length === this.paginatedClients.length;
  }

  findValidAddresses(): Address[] {
    let newList: Address[] = [];
    for (let i = 0; i < this.delivery.length; i++) {
      if (!this.delivery[i].address.trim().length) continue;
      newList.push(this.delivery[i]);
    }
    return newList;
  };

  getClientById(clientId: number): Client | null {
    for (let i = 0; i < this.clients.length; i++) {
      if (this.clients[i].customer.id === clientId) return this.clients[i];
    }
    return null;
  }

  @action setInit() {
    if (!this.isDataParsed) this.parsePartsData();
  };

  @action clearCache() {
    this.sortingOrder = [0];
    this.sortingName = ClientsSortingName.None;
    this.cacheDate = null;
    this.manager = null;
    this.cabinet = null;
    this.dashboard = null;
    this.clients = [];
    this.newClients = [];
    this.reconciliationActUrl = null;
    this.delivery = [];
    this.feedbackSubject = '';
    this.feedbackMessage = '';
    this.newClientName = '';
    this.newClientPhone = '';
    this.newClientAddress = '';
    this.newClientEmail = '';
    this.managerName = '';
    this.managerPhone = '';
    this.clientName = '';
    this.clientKpp = '';
    this.clientInn = '';
    this.clientLegalAddress = '';
    this.clientContactName = '';
    this.clientContactPhone = '';
    this.clientsFilterPhrase = '';
    this.excludedClientId = {};
    this.isDataParsed = false;
    this.clientsList = [];
    this.curPage = 1;
  }

  @action parsePartsData() {
    this.isDataParsed = true;
    this.managerName = this.manager?.name || '';
    this.managerPhone = this.manager?.phone || '';
    this.clientName = this.cabinet?.name || '';
    this.clientKpp = this.cabinet?.kpp || '';
    this.clientInn = this.cabinet?.inn || '';
    this.clientLegalAddress = this.cabinet?.legal_address || '';
    this.clientContactName = this.cabinet?.contact_name || '';
    this.clientContactPhone = this.cabinet?.contact_phone || '';
    let clients: { [key: number]: ClientInFilter } = {};
    for (let i = 0; i < this.clients.length; i++) {
        if (!clients[this.clients[i].customer.id]) {
            clients[this.clients[i].customer.id] = [
                this.clients[i].customer.id,
                this.clients[i].customer.name,
                this.clients[i].customer.pdz,
            ];
        }
    }
    this.clientsList = Object.values(clients).sort(([, a], [, b]) => a.localeCompare(b));
    if (this.cabinet?.addresses.length) this.delivery = toJS(this.cabinet.addresses);
    else this.delivery.push(this.emptyDeliveryAddress);
  };

  @action getPartsData(force: boolean = false): Promise<any> {
    if (mainStore.activeRequestsSet.has('cabinet_getPartsData')) return Promise.reject(responseOverlappingError);
    if (!force && this.cacheDate && Date.now() - this.cacheDate < CACHE_EXPIRED_CABINET) return Promise.reject(
      responseLockedError);
    mainStore.activeRequestsSet.add('cabinet_getPartsData');
    return CabinetRequests.getPartsData().then(e => {
      runInAction(() => {
        this.cacheDate = Date.now();
        this.manager = e.data.manager || null;
        this.cabinet = e.data.cabinet || null;
        this.dashboard = e.data.dashboard || null;
        this.clients = e.data.clients || [];
        this.newClients = e.data.new_clients || [];
        this.reconciliationActUrl = e.data.reconciliation_act_url || null;
        this.parsePartsData();
      });
      return Promise.resolve(e);
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'getPartsData')).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('cabinet_getPartsData');
      });
    });
  };

  @action sendFeedback() {
    if (mainStore.activeRequestsSet.has('cabinet_sendFeedback')) return;
    mainStore.activeRequestsSet.add('cabinet_sendFeedback');
    CabinetRequests.sendFeedback({
      subject: this.feedbackSubject,
      message: this.feedbackMessage,
    }).then(() => {
      runInAction(() => {
        this.feedbackSubject = '';
        this.feedbackMessage = '';
        mainStore.alerts.push({
          message: 'Ваше сообщение успешно отправленно',
          cssClass: AlertType.Success,
        });
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendFeedback').catch(() => void 0)).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('cabinet_sendFeedback');
      });
    });
  };

  @action addNewClient() {
    if (mainStore.activeRequestsSet.has('cabinet_addNewClient')) return;
    mainStore.activeRequestsSet.add('cabinet_addNewClient');
    CabinetRequests.addNewClient({
      name: this.newClientName,
      address: this.newClientAddress,
      phone: this.newClientPhone,
      email: this.newClientEmail,
    }).then((e) => {
      runInAction(() => {
        this.newClients.push({
          name: this.newClientName,
          address: this.newClientAddress,
          phone: this.newClientPhone,
          email: this.newClientEmail,
          id: e.data.id,
        });
        this.newClientName = '';
        this.newClientAddress = '';
        this.newClientPhone = '';
        this.newClientEmail = '';
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'addNewClient').catch(() => void 0)).finally(() => {
      runInAction(() => {
        mainStore.activeRequestsSet.delete('cabinet_addNewClient');
      });
    });
  };

  @action sendManagerContacts() {
    if (mainStore.activeRequestsSet.has('cabinet_sendManagerContacts')) return;
    mainStore.activeRequestsSet.add('cabinet_sendManagerContacts');
    CabinetRequests.sendManagerContacts({
      name: this.managerName,
      phone: this.managerPhone,
    }).then(() => {
      runInAction(() => {
        this.manager!.name = this.managerName;
        this.manager!.phone = this.managerPhone;
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendManagerContacts').catch(() => void 0)).finally(
      () => {
        runInAction(() => {
          mainStore.activeRequestsSet.delete('cabinet_sendManagerContacts');
        });
      });
  };

  @action sendClientContacts() {
    if (mainStore.activeRequestsSet.has('cabinet_sendClientContacts')) return;
    mainStore.activeRequestsSet.add('cabinet_sendClientContacts');
    const addresses: Address[] = this.findValidAddresses();
    CabinetRequests.sendClientContacts({
      name: this.clientName,
      kpp: this.clientKpp,
      inn: this.clientInn,
      legal_address: this.clientLegalAddress,
      contact_name: this.clientContactName,
      contact_phone: this.clientContactPhone,
      addresses: addresses,
    }).then(() => {
      runInAction(() => {
        this.cabinet!.contact_name = this.clientContactName;
        this.cabinet!.contact_phone = this.clientContactPhone;
        this.cabinet!.addresses = addresses;
        this.delivery = addresses;
      });
    }).catch((error: ErrorResponse) => this.errorHandler(error, 'sendClientContacts').catch(() => void 0)).finally(
      () => {
        runInAction(() => {
          mainStore.activeRequestsSet.delete('cabinet_sendClientContacts');
        });
      });
  };

  @action.bound errorHandler(error: ErrorResponse, context: string): Promise<ErrorResponse> {
    let errorMessage = '';
    let errorButton: AlertButton | undefined = undefined;
    switch (error.status) {
      case 422:
        if (context === 'addNewClient') {
          if (error.data.data.email) errorMessage = 'Вы указали некорректный Email';
        }
        break;
    }
    if (errorMessage) {
      mainStore.alerts.push({
        message: errorMessage,
        cssClass: AlertType.Error,
        button: errorButton,
      });
    } else mainStore.errorHandler(error, context).catch(() => void 0);
    return Promise.reject(error);
  };
}
