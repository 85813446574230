import { IonContent, IonPage } from '@ionic/react';
import { inject, observer } from 'mobx-react';
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Attention from '../components/Attention';
import CatalogCategoriesFilters from '../components/CatalogCategoriesFilters';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Menu from '../components/Menu';
import PromotionCategoriesFilters from '../components/PromotionCategoriesFilters';
import { mainStore, MainStore } from '../stores/MainStore';
import { UI } from '../UI';
import Home from './Home';
import NotFound from './NotFound';
import ConfirmLocation from '../components/ConfirmLocation';
import PopupChooseRegion from '../components/PopupChooseRegion';
import PopupChooseAddress from '../components/PopupChooseAddress';
import PopupChooseDeliverySchedule from '../components/PopupChooseDeliverySchedule';
import PopupMap from '../components/PopupMap';
import PopupDebt from '../components/PopupDebt';

const Cart = lazy(() => import('./Cart'));
const Checkout = lazy(() => import('./Checkout'));
const Cabinet = lazy(() => import('./Cabinet'));
const Catalog = lazy(() => import('./Catalog'));
const Orders = lazy(() => import('./Orders'));
const Order = lazy(() => import('./Order'));
const Manager = lazy(() => import('./Manager'));
const Clients = lazy(() => import('./Clients'));
const Routes = lazy(() => import('./Routes'));
const News = lazy(() => import('./News'));
const Promotions = lazy(() => import('./Promotions'));
const Promotion = lazy(() => import('./Promotion'));
const PromotionStatistic = lazy(() => import('./PromotionStatistic'));
const DynamicPage = lazy(() => import('./DynamicPage'));
const Status = lazy(() => import('./Status'));

const PageLayout: React.FC<{ mainStore: MainStore }> = ({ mainStore }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [isInit, setIsInit] = useState(true);
  const catalogMatch = useRouteMatch({
    path: mainStore.catalogStore.catalogPath,
    exact: true,
  });
  const promotionMatch = useRouteMatch({
    path: mainStore.promotionsStore.promotionPath,
    exact: true,
  });

  useEffect(() => {
    if (!mainStore.userStore.isAuth) history.replace('/auth');
  }, [mainStore.userStore.isAuth, history]);

  useLayoutEffect(() => {
    if (!mainStore.userStore.isAuth) return;
    UI.init();
  }, [mainStore.userStore.isAuth]);

  useLayoutEffect(() => {
    if (!mainStore.userStore.isAuth) return;
    UI.scroll.toggleCustomScroll();
  });

  useLayoutEffect(() => {
    if (!mainStore.userStore.isAuth) return;
    UI.scroll.to(0);
  }, [pathname, mainStore.userStore.isAuth]);

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
      return;
    }
    mainStore.forceStartupRequests = false;
    //eslint-disable-next-line
  }, [pathname]);

  if (!mainStore.userStore.isAuth) return <></>;

  return (
    <IonPage>
      <IonContent className="ion-content">
        <div className="container-fluid">
          <Menu />
          {catalogMatch !== null && <CatalogCategoriesFilters />}
          {promotionMatch !== null && <PromotionCategoriesFilters />}
          <div className="attention-wrap">
            {mainStore.attentionMessage.length > 0 && <Attention />}
            {mainStore.isShowConfirmLocation && <ConfirmLocation />}
          </div>
          <div className="column-main js-custom-scroll">
            <div className="attention-wrap">
              {mainStore.attentionMessage.length > 0 && <Attention />}
              {mainStore.isShowConfirmLocation && <ConfirmLocation />}
            </div>
            <Header />
            <Suspense fallback={<></>}>
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/cart" component={Cart} exact />
                <Route path="/checkout" component={Checkout} exact />
                <Route path="/status" component={Status} exact />
                <Route path="/cabinet" component={Cabinet} exact />
                <Route path={mainStore.catalogStore.catalogPath} component={Catalog} exact />
                <Route path={['/orders', '/orders/:clientId']} component={Orders} exact />
                <Route path="/order/:orderId" component={Order} exact />
                <Route path="/manager" component={Manager} exact />
                <Route path="/clients" component={Clients} exact />
                <Route path="/routes" component={Routes} exact />
                <Route path="/news" component={News} exact />
                <Route path={['/actions', '/actions/:clientId']} component={Promotions} exact />
                <Route path={mainStore.promotionsStore.promotionPath} component={Promotion} exact />
                <Route path="/promotion/statistic/:promotionId" component={PromotionStatistic} exact />
                <Route path="/page/:part" component={DynamicPage} exact />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
            <Footer />
          </div>
          <PopupChooseRegion />
          <PopupChooseAddress />
          <PopupChooseDeliverySchedule />
          <PopupMap />
          <PopupDebt />
        </div>
      </IonContent>
    </IonPage>
  );
};

/*const RefresherWrap: React.FC = observer(() => {
    return (
        <IonRefresher className="ion-refresher"
                      slot="fixed"
                      onIonRefresh={(e) => {setTimeout(() => {e.detail.complete()}, 2000)}}
                      disabled={!mainStore.isScrollTop}
        >
            <IonRefresherContent/>
        </IonRefresher>
    );
});*/

export default inject('mainStore')(observer(PageLayout));
