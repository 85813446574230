import {IonPage} from '@ionic/react';
import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {MainStore} from '../stores/MainStore';
import {useHistory} from 'react-router';

const Auth: React.FC<{ mainStore: MainStore }> = ({mainStore}) => {
    const history = useHistory();

    useEffect(() => {
        if (mainStore.userStore.isAuth) history.replace('/');
    }, [mainStore.userStore.isAuth, history]);

    const handleInputLogin = (e: React.FormEvent<HTMLInputElement>) =>
        mainStore.userStore.setLogin(e.currentTarget.value);
    const handleInputPassword = (e: React.FormEvent<HTMLInputElement>) => {
        mainStore.userStore.password = e.currentTarget.value;
    }
    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (mainStore.userStore.authStatus === 'code') mainStore.userStore.sendCode();
        else mainStore.userStore.sendLogin();
    }

    return (
        <IonPage>
            <div className="container-fluid">
                <div className="column-main">
                    <section className="auth">
                        <img className="auth__logo pe-n" src="/assets/img/logo_beegudi_color.svg" alt=""/>
                        <div className="auth-form board board_p-lg">
                            <div className="h6">Добро пожаловать!</div>
                            <div className="h3">Вход в кабинет</div>
                            <form onSubmit={handleFormSubmit}>
                                <div className="form-group">
                                    <label className="h6" htmlFor="auth_login">Логин</label>
                                    <input className="form-control"
                                           onChange={handleInputLogin}
                                           value={mainStore.userStore.login}
                                           id="auth_login"
                                           type="text"
                                           placeholder="Номер телефона или Email"
                                           title="Номер телефона или Email"
                                           disabled={mainStore.userStore.authStatus !== 'request'}
                                    />
                                </div>
                                {!(mainStore.userStore.authStatus === 'request' && mainStore.userStore.loginType === 'phone') && (
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label className="h6" htmlFor="auth_password">
                                                {mainStore.userStore.loginType === 'phone' && mainStore.userStore.authStatus === 'code' ? 'Код из SMS' : 'Пароль'}
                                            </label>
                                            {mainStore.userStore.loginType !== 'phone' && (
                                                <a className="auth-form__link-forgot pe-n" href="/">Забыли пароль?</a>
                                            )}
                                        </div>
                                        <input className="form-control"
                                               onChange={handleInputPassword}
                                               value={mainStore.userStore.password}
                                               id="auth_password"
                                               type="password"
                                               placeholder="*********"
                                               autoComplete="new-password"
                                               title={mainStore.userStore.loginType === 'phone' && mainStore.userStore.authStatus === 'code' ? 'Код из SMS' : 'Пароль'}
                                               maxLength={mainStore.userStore.loginType === 'phone' && mainStore.userStore.authStatus === 'code' ? 4 : undefined}
                                        />
                                    </div>
                                )}
                                <button className="btn btn-primary btn-lg btn-block"
                                        type="submit"
                                        disabled={!mainStore.userStore.isValidForm}>
                                    {mainStore.userStore.loginType === 'phone' && mainStore.userStore.authStatus === 'request' ? 'Получить код' : 'Войти в кабинет'}
                                </button>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </IonPage>
    );
};

export default inject('mainStore')(observer(Auth));
